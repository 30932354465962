export enum JOB_TYPE {
    DOCKING = 'dock',
    BINDING_STABILITY_SIMULATION = 'MD',
    LIGAND_PREPARATION = 'ligprep',
    PROTEIN_REFINEMENT = 'protein_refine',
    FEP_CALCULATION = 'fep2',
    FEP_PREPARATION = 'fep1',
    ADMET = 'admet',
    BINDING_SITE = 'lbs',
    SIMILAR_SEARCH = 'simsearch',
    SUBSTRUCTURE_SEARCH = 'subcheck',
    LOOP_OPT = 'loopopt',
    BIOISOSTERIC_REPLACEMENT = 'leadopt',
    PROTEIN_PREP = 'proprep',
    RID_BUILDER = 'rid_builder',
    FOLD_PREP = 'fold_prep',
    FOLD_INFER = 'fold_infer',
    EM = 'struct_fit',
}

export const JOB_FUNCTION_NAME = {
    [JOB_TYPE.DOCKING]: 'Docking',
    [JOB_TYPE.BINDING_STABILITY_SIMULATION]: 'Binding Stability Simulation',
    [JOB_TYPE.LIGAND_PREPARATION]: 'Ligand Preparation',
    [JOB_TYPE.PROTEIN_REFINEMENT]: 'Protein Refinement',
    [JOB_TYPE.FEP_CALCULATION]: 'Uni-FEP Calculation',
    [JOB_TYPE.FEP_PREPARATION]: 'Uni-FEP Preparation',
    [JOB_TYPE.ADMET]: 'ADME/T',
    [JOB_TYPE.BINDING_SITE]: 'Binding Site Detection',
    [JOB_TYPE.SIMILAR_SEARCH]: 'Similar Molecules Search',
    [JOB_TYPE.SUBSTRUCTURE_SEARCH]: 'Substructure Search',
    [JOB_TYPE.LOOP_OPT]: 'Loop Optimization',
    [JOB_TYPE.BIOISOSTERIC_REPLACEMENT]: 'Bioisosteric Replacement',
    [JOB_TYPE.PROTEIN_PREP]: 'Protein Preparation',
    [JOB_TYPE.RID_BUILDER]: 'RiD Builder',
    [JOB_TYPE.FOLD_PREP]: 'Fold',
    [JOB_TYPE.FOLD_INFER]: 'Fold',
    [JOB_TYPE.EM]: 'EM',
};

export const JOB_TYPE_OPTIONS = [
    {
        label: 'Uni-FEP Preparation',
        value: 'fep1',
    },
    {
        label: 'Uni-FEP Calculation',
        value: 'fep2',
    },
    {
        label: 'Protein Refinement',
        value: 'protein_refine',
    },
    {
        label: 'Ligand Preparation',
        value: 'ligprep',
    },
    {
        label: 'Docking',
        value: 'dock',
    },
    {
        label: 'Binding Stability Simulation',
        value: 'MD',
    },
    {
        label: 'Bioisosteric Replacement',
        value: 'leadopt',
    },
    {
        label: 'Loop Optimization',
        value: 'loopopt',
    },
    {
        label: 'Substructure Search',
        value: 'subcheck',
    },
    {
        label: 'Similar Molecules Search',
        value: 'simsearch',
    },
    {
        label: 'Binding Site Detection',
        value: 'lbs',
    },
    {
        label: 'ADME/T',
        value: 'admet',
    },
    {
        label: 'Protein Preparation',
        value: 'proprep',
    },
    {
        label: 'RiD Builder',
        value: 'rid_builder',
    },
    {
        label: 'Fold',
        value: 'fold_prep,fold_infer',
    },
    {
        label: 'EM',
        value: 'struct_fit',
    },
];
