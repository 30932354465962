import { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { ApiBaseEndPointOptions } from '@shared/model/type/api.type';

declare module 'axios' {
    interface AxiosRequestConfig {
        endpointSource?: string | undefined;
        bizType?: string | undefined;
    }
}

export interface ApiBaseEndPointOptions {
    endPoints?: {
        [name: string]: string;
    };
    defaultEndpoint?: string;
    bizPrefix?: {
        [name: string]: string;
    };
}

export default function apiBaseEndpointAxios({ defaultEndpoint, endPoints, bizPrefix }: ApiBaseEndPointOptions) {
    return (axios: AxiosInstance) => {
        if (defaultEndpoint) {
            // eslint-disable-next-line no-param-reassign
            axios.defaults.baseURL = defaultEndpoint;
        }

        const handle = (requestConfig: AxiosRequestConfig) => {
            if (requestConfig.endpointSource) {
                if (endPoints) {
                    // eslint-disable-next-line no-param-reassign
                    requestConfig.baseURL = endPoints[requestConfig.endpointSource];
                }
            }
            if (requestConfig.bizType) {
                if (bizPrefix) {
                    requestConfig.url = bizPrefix[requestConfig.bizType] + requestConfig.url;
                }
            }

            return requestConfig;
        };

        axios.interceptors.request.use(handle);
    };
}
