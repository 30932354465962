import { AxiosInstance, AxiosResponse } from 'axios';
import { HTTP_CODE } from '@shared/model/enum/http-code.enum';
// import { USER_RES_CODE } from '../../model/enum/login-status.enum';

type ToastAxiosOptions = {
    toastFail?: (message: string, title?: string) => void;
    toastSuccess?: (message: string, title?: string) => void;
    useGlobalToastFail?: boolean;
};

declare module 'axios' {
    interface AxiosRequestConfig {
        toastFail?: string | boolean;
        toastSuccess?: string | boolean;
        isFile?: boolean;
    }
}

export default function toastAxios({ toastFail, toastSuccess }: ToastAxiosOptions) {
    return (axios: AxiosInstance) => {
        const handle = (res: AxiosResponse) => {
            if (!res || !res.config || res.config.isFile) {
                return res;
            }

            const getErrorMessage = (fallbackMessage: string) => {
                if (res.config.toastFail && typeof res.config.toastFail === 'string') {
                    return res.config.toastFail;
                }
                return fallbackMessage;
            };

            const getSuccessMessage = (fallbackMessage: string) => {
                if (res.config.toastSuccess && typeof res.config.toastSuccess === 'string') {
                    return res.config.toastSuccess;
                }
                return fallbackMessage;
            };

            if (res.config.toastFail !== false) {
                if (res instanceof Error) {
                    const { response } = res as any;
                    if (toastFail && response.data.msg) {
                        toastFail(getErrorMessage(response.data.message));
                    } else if (toastFail) {
                        toastFail(getErrorMessage(response.statusText || ''));
                    }
                    // 兼容充值接口
                } else if (res.status !== HTTP_CODE.SUCCESS && toastFail) {
                    const message = (res?.data?.msg ?? res?.data?.error?.msg) as string;
                    if (message) {
                        toastFail(message);
                    }
                }
            }

            if (res.config.toastSuccess !== false) {
                if (toastSuccess && res.status === HTTP_CODE.SUCCESS && !res.data.code) {
                    if (getSuccessMessage('')) {
                        toastSuccess(getSuccessMessage(''));
                    }
                }
            }

            return res;
        };

        axios.interceptors.response.use(handle, handle);
    };
}
