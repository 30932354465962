import { Button, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchOrgTags } from '@/services/api/org';
import { OrgListParams, OrgType } from '@/shared/model/type/org/org.type';

const { RangePicker } = DatePicker;
interface OrgListFormProps {
    orgTypeList: OrgType[];
    form: FormInstance;
    onSubmit: (values: Partial<OrgListParams>) => void;
}

const OrgListForm: React.FC<OrgListFormProps> = React.memo(function OrgListForm({ form, orgTypeList, onSubmit }) {
    const [tagsOptions, setTagsOptions] = useState<{ label: string; value: number }[]>();
    const orgTypeOptions = useMemo(
        () =>
            orgTypeList.map(item => ({
                label: item.name,
                value: item.id,
            })),
        [orgTypeList]
    );
    const colProps = useMemo(
        () => ({
            span: 8,
        }),
        []
    );

    const getTagsOptions = () => {
        fetchOrgTags().then(({ data }) => {
            setTagsOptions(() =>
                data.map(item => ({
                    label: item.name,
                    value: item.id,
                }))
            );
        });
    };

    useEffect(() => {
        getTagsOptions();
    }, []);

    return (
        <Form
            form={form}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{ span: 17 }}
            onFinish={values => {
                const { time, ...params } = values;
                const [startTime, endTime] = (time || []).map((m: Moment) => m.format('YYYY-MM-DD'));
                onSubmit({
                    ...params,
                    startTime,
                    endTime,
                });
            }}
        >
            <Row>
                <Col {...colProps}>
                    <Form.Item label="组织ID" name={'orgId'}>
                        <Input
                            type={'number'}
                            allowClear
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="请输入组织id"
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="组织名称" name={'name'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入组织名称" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="注册时间" name={'time'}>
                        <RangePicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="组织类型" name={'orgType'}>
                        <Select options={orgTypeOptions} allowClear placeholder="请选择组织类型" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="管理员邮箱" name={'email'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入管理员邮箱" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="管理员手机号" name={'phone'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入管理员手机号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="标签" name={'tagIds'}>
                        <Select options={tagsOptions} allowClear placeholder="请选择标签" mode="multiple" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item
                        wrapperCol={{
                            offset: 7,
                            span: 17,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>{' '}
                        <Button
                            htmlType="button"
                            onClick={() => {
                                form.resetFields();
                                form.submit();
                            }}
                        >
                            重置
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default OrgListForm;
