import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Space, Button, message, Tag, InputNumber, Form, Modal, Select, Radio, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { fetchUsers, openFEPPermission } from '@/services/api/users';
import useListScrollHeight from '@shared/utils/hooks/useListScrollHeight';
import { UsersListParams, UserData } from '@/shared/model/type/users/user.type';
import { getColumnSearchProps } from '@/shared/utils/table-filter/table-filter-selector';
// import { HTTP_CODE } from '@shared/model/enum';
import { charge } from '@services/api/finance';
// import { fetchOrgTags, fetchOrgTypes } from '@/services/api/org';
// import { OrgTag, OrgType, ProductLine } from '@/shared/model/type/org/org.type';
import { VIP_OPTIONS, CHARGE_TYPE_OPTIONS, BALANCE_TYPE } from './model';
// import styles from './index.module.css';

const { Item } = Form;

const { TextArea } = Input;

const UserList: React.FC<{}> = function UserList() {
    const [usersState, setUsersState] = useState<Array<UserData>>([]);
    const [totalState, setTotalState] = useState<number>(0);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [chargeVisibleState, setChargeVisibleState] = useState<boolean>(false);
    const [scrollHeightState] = useListScrollHeight(550, 35);
    // const [newUserVisibleState, setNewUserVisibleState] = useState<boolean>(false);
    // const [creatingUserState, setCreatingUserState] = useState<boolean>(false);
    const [chargeSubmitingState, setChargeSubmitingState] = useState<boolean>(false);
    // const [typesState, setTypesState] = useState<OrgType[]>([]);
    // const [tagsState, setTagsState] = useState<OrgTag[]>([]);
    const [form] = Form.useForm();
    // const [newUserForm] = Form.useForm();
    // const [modal, contextHolder] = Modal.useModal();

    const paramsRef = useRef<UsersListParams>({
        offset: 1,
        pageSize: 30,
        id: 0,
        email: '',
    });

    const emailsRef = useRef({
        emails: '',
    });

    const attachmentRef = useRef<any>([]);

    const resetPage = () => {
        paramsRef.current.offset = 1;
    };

    const handle = useCallback((callback: (...values: any) => void) => {
        resetPage();
        return function search(...params: any) {
            callback.apply(null, [...params]);
            loadUsers();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const chargeSubmit = () => {
        form.validateFields().then(() => {
            setChargeSubmitingState(true);
            const values = form.getFieldsValue();
            const { emails } = emailsRef.current;
            const data = new FormData();
            data.append('emails', emails);
            Object.keys(values).forEach(key => {
                if (values[key]) {
                    data.append(key, values[key]);
                }
            });
            const filesArr = attachmentRef.current.slice(0, 3);
            filesArr?.forEach((file: any) => {
                data.append('files', file, file.name);
            });

            charge(data)
                .then(() => {
                    loadUsers();
                })
                .finally(() => {
                    setChargeVisibleState(false);
                    setChargeSubmitingState(false);
                });
        });
    };

    const loadUsers = React.useCallback(() => {
        setLoadingState(true);
        fetchUsers({ ...paramsRef.current })
            .then(res => {
                setUsersState(res.list);
                setTotalState(res.total);
            })
            .catch(err => {
                message.error(err.msg || '服务器错误');
            })
            .finally(() => {
                setLoadingState(false);
            });
    }, []);

    const closeChargeModal = () => {
        setChargeVisibleState(false);
        attachmentRef.current = [];
    };

    // const closeNewUserModal = () => {
    //     setNewUserVisibleState(false);
    //     // setNewUserErrorListState([]);
    // };

    const USER_COLUMS = React.useMemo(
        () => [
            {
                title: '用户 ID',
                key: 'userId',
                dataIndex: 'userId',
                ...getColumnSearchProps(
                    'userId',
                    handle((_, value) => {
                        paramsRef.current.id = value;
                    })
                ),
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                ...getColumnSearchProps(
                    'email',
                    handle((_, value) => {
                        paramsRef.current.email = value;
                    })
                ),
            },
            {
                title: '余额',
                key: 'balance',
                dataIndex: 'balance',
            },
            {
                title: 'FEP',
                key: 'hasFep',
                dataIndex: 'hasFep',
                render: (auth: number) =>
                    auth === 0 ? <Tag color="grey">未开通</Tag> : <Tag color="success">已开通</Tag>,
            },
            {
                title: 'Pairs 余额',
                key: 'pairs',
                dataIndex: 'pairs',
            },
            {
                title: '创建时间',
                key: 'createTime',
                dataIndex: 'createTime',
                render: (text: string) => {
                    if (!text) {
                        return '-';
                    }
                    return text;
                },
            },
            {
                title: '操作',
                key: 'operation',
                dataIndex: 'operation',
                render: (_: string, item: UserData) => {
                    return (
                        <Space>
                            <Button
                                size="small"
                                onClick={() =>
                                    Modal.confirm({
                                        title: item.hasFep ? '确认禁用FEP？' : '确认开通FEP？',
                                        onOk: () => {
                                            openFEPPermission(item.email, item.hasFep ? 0 : 1).finally(() => {
                                                loadUsers();
                                            });
                                        },
                                    })
                                }
                                danger={!!item.hasFep}
                                type="primary"
                            >
                                {item.hasFep ? '禁用FEP' : '开通FEP'}
                            </Button>
                        </Space>
                    );
                },
            },
        ],
        [handle, loadUsers]
    );

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section>
            {/* <Button type="primary" className={styles['margin-b-10']}>
                sss
            </Button> */}
            <Table
                columns={USER_COLUMS}
                rowKey={item => item.userId}
                dataSource={usersState}
                scroll={{ y: scrollHeightState }}
                loading={loadingState}
                pagination={{
                    pageSize: paramsRef.current.pageSize,
                    total: totalState,
                    showSizeChanger: true,
                    onShowSizeChange: (current: number, size: number) => {
                        paramsRef.current.pageSize = size;
                        loadUsers();
                    },
                    onChange: (page: number) => {
                        if (paramsRef.current.offset !== page) {
                            paramsRef.current.offset = page;
                            loadUsers();
                        }
                    },
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                }}
            />
            <Modal
                visible={chargeVisibleState}
                onCancel={closeChargeModal}
                maskClosable={false}
                onOk={chargeSubmit}
                title="充值"
                confirmLoading={chargeSubmitingState}
                destroyOnClose
                centered
            >
                <Form
                    initialValues={{
                        balanceType: BALANCE_TYPE.DISCOUNT_BALANCE,
                        vip: VIP_OPTIONS[1].value,
                        chargeType: CHARGE_TYPE_OPTIONS[0].value,
                        remark: '',
                        contractNum: '',
                        contractName: '',
                        amount: 0,
                    }}
                    form={form}
                    preserve={false}
                    labelCol={{ span: 5 }}
                >
                    <Item label="Email">{emailsRef.current.emails}</Item>
                    <Item name="vip" label="是否是VIP" rules={[{ required: true }]}>
                        <Select options={VIP_OPTIONS} />
                    </Item>
                    <Item name="balanceType" label="余额类型">
                        <Radio.Group>
                            <Radio value={BALANCE_TYPE.DISCOUNT_BALANCE}>优惠充值余额</Radio>
                            <Radio value={BALANCE_TYPE.NORMAL_BALANCE}>充值余额</Radio>
                        </Radio.Group>
                    </Item>
                    <Item name="chargeType" label="充值类型">
                        <Select options={CHARGE_TYPE_OPTIONS} />
                    </Item>
                    <Item
                        name="amount"
                        label="充值金额"
                        rules={[
                            { required: true, message: '请输入充值金额' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value > 0 || getFieldValue('pairs')) {
                                        return Promise.resolve();
                                    }
                                    if (value === 0 && getFieldValue('pairs') === 0) {
                                        return Promise.reject(new Error('pair数和充值金额不能同时为0'));
                                    }
                                    return Promise.reject(new Error('充值金额请输入大于等于0的数值'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber style={{ width: '200px' }} />
                    </Item>
                    <Item
                        name="pairs"
                        label="pairs数"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value > 0 || getFieldValue('amount')) {
                                        return Promise.resolve();
                                    }
                                    if (value === 0 && getFieldValue('amount') === 0) {
                                        return Promise.reject(new Error('pair数和充值金额不能同时为0'));
                                    }
                                    return Promise.reject(new Error('充值pair数请输入大于等于0的数值'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber min={0} precision={0} style={{ width: '200px' }} />
                    </Item>
                    <Item name="remark" label="备注">
                        <TextArea maxLength={200} />
                    </Item>
                    <Item label="附件">
                        <Upload
                            beforeUpload={file => {
                                attachmentRef.current.push(file);
                                return false;
                            }}
                            onRemove={file => {
                                attachmentRef.current = attachmentRef.current.filter((f: any) => f.uid !== file.uid);
                            }}
                            multiple
                            maxCount={3}
                        >
                            <Button icon={<UploadOutlined />}>上传附件</Button>
                        </Upload>
                    </Item>
                    <Item name="contractNum" label="合同编号">
                        <TextArea maxLength={100} />
                    </Item>
                    <Item name="contractName" label="合同名称">
                        <TextArea maxLength={100} />
                    </Item>
                </Form>
            </Modal>
            {/* {contextHolder} */}
        </section>
    );
};

export default UserList;
