import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { ACCOUNT_REFILLING_OPTIONS } from '@/shared/model/constant/finance/income';
import { IncomeListParams } from '@/shared/model/type/finance/income-list.type';

const { RangePicker } = DatePicker;
interface IncomeFormProps {
    onSubmit: (values: Partial<IncomeListParams>) => void;
    onDelete: (data: number[]) => void;
    onImport: () => void;
    currSelected: number[];
}

const IncomeListForm: React.FC<IncomeFormProps> = React.memo(function IncomeListForm({
    onSubmit,
    onDelete,
    currSelected,
    onImport,
}) {
    const [form] = Form.useForm();
    const colProps = useMemo(
        () => ({
            span: 6,
        }),
        []
    );
    return (
        <Form
            form={form}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{ span: 17 }}
            onFinish={values => {
                const { time, ...params } = values;
                const [startTime, endTime] = (time || []).map((m: Moment) => m.format('YYYY-MM-DD'));
                onSubmit({
                    ...params,
                    id: +values?.id || undefined,
                    startTime: startTime ? `${startTime} 00:00:00` : undefined,
                    endTime: endTime ? `${endTime} 23:59:59` : undefined,
                });
            }}
        >
            <Row>
                <Col {...colProps}>
                    <Form.Item label="ID" name={'id'}>
                        <Input
                            type={'number'}
                            allowClear
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="请输入来款id"
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="交易流水号" name={'bankNo'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="交易流水号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="来款日期" name={'time'}>
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="认领状态" name={'claimStatus'}>
                        <Select options={ACCOUNT_REFILLING_OPTIONS} allowClear placeholder="已认领/未认领/部分认领" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="用户户名" name={'paymentAccountName'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入对方户名" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="对方账号" name={'paymentAccountNo'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="对方账户号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="来款金额" style={{ marginBottom: 0 }}>
                        <Row>
                            <Form.Item style={{ width: '40%' }} name="minAmount">
                                <InputNumber style={{ width: '100%' }} placeholder="金额" precision={2} />
                            </Form.Item>
                            <span style={{ padding: 5 }}>-</span>
                            <Form.Item style={{ width: '40%' }} name="maxAmount">
                                <InputNumber style={{ width: '100%' }} placeholder="金额" precision={2} />
                            </Form.Item>
                        </Row>
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="备注" name={'remark'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="备注" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={20}>
                    <Form.Item
                        wrapperCol={{
                            span: 17,
                        }}
                    >
                        <Button style={{ marginRight: '8px' }} type="primary" htmlType="submit">
                            搜索
                        </Button>{' '}
                        <Button
                            style={{ marginRight: '8px' }}
                            onClick={() => {
                                form.resetFields();
                                form.submit();
                            }}
                        >
                            重置
                        </Button>{' '}
                        <Button onClick={onImport} style={{ marginRight: '8px' }}>
                            导入
                        </Button>
                        <Button
                            onClick={() => {
                                onDelete(currSelected);
                            }}
                        >
                            批量删除
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default IncomeListForm;
