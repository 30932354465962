import { Button, DatePicker, Form, Input, Row, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { PayModal } from '@shared/components/biz/order-modal/pay';
import { putOrder, queryOrder } from '@services/api/order';
import { OrderModal } from '@shared/components/biz/order-modal';
import { OrderStatus, OrderStatusMsg } from '@shared/model/type/order/order.type';
import { SkuType } from '@services/api/combo';

const { Item } = Form;
const { RangePicker } = DatePicker;

export const TradeOrder = () => {
    const [payVisible, setPayVisible] = useState(false);
    const [orderVisible, setOrderVisible] = useState(false);
    const [data, setData] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [extraData, setExtraData] = useState<any>();
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        if (params?.createTime?.length) {
            params.createStartTime = params.createTime[0].format('YYYY-MM-DD HH:mm:ss');
            params.createEndTime = params.createTime[1].format('YYYY-MM-DD HH:mm:ss');
            delete params.createTime;
        }
        return queryOrder({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items || []);
            setTotal(rep.data.total);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
            return rep.data.items;
        });
    };
    useEffect(() => {
        const orderId = localStorage.getItem('_orderId');
        if (orderId) {
            localStorage.removeItem('_orderId');
            form.setFieldsValue({ orderId });
            refresh(page, pageSize).then(items => {
                const [row] = items;
                setExtraData({ orderId: row.orderId, amount: row.amount });
                setTimeout(() => {
                    setOrderVisible(true);
                    setEditable(true);
                }, 100);
            });
        } else {
            refresh(page, pageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Form form={form} layout="inline">
                <Row style={{ marginBottom: 8, width: '100%' }}>
                    <Item label="组织ID" name="orgId">
                        <Input placeholder="请输入用户ID" />
                    </Item>
                    <Item label="组织名称" name="orgName">
                        <Input placeholder="请输入" />
                    </Item>
                    <Item label="下单时间" name="createTime">
                        <RangePicker showTime />
                    </Item>
                </Row>
                <Row style={{ marginBottom: 8, width: '100%' }}>
                    <Item label="订单ID" name="orderId">
                        <Input placeholder="请输入订单ID" />
                    </Item>
                    <Item label="SKU Name" name="skuName">
                        <Input placeholder="请输入" />
                    </Item>
                    <Item>
                        <Button onClick={() => form.resetFields()}>重置</Button>
                        <Button
                            style={{ marginLeft: 12 }}
                            type="primary"
                            onClick={() => {
                                refresh(1, pageSize);
                            }}
                        >
                            搜索
                        </Button>
                    </Item>
                </Row>
            </Form>
            <Table
                pagination={{
                    defaultPageSize: pageSize,
                    current: page,
                    showSizeChanger: true,
                    onChange: (newPage: number, newPageSize?: number) => {
                        refresh(newPage, newPageSize);
                    },
                    total,
                }}
                dataSource={data}
                columns={[
                    {
                        title: '序号',
                        width: 60,
                        fixed: 'left',
                        render: (value, row, index) => index + 1,
                    },
                    {
                        title: '订单ID',
                        width: 90,
                        dataIndex: 'orderId',
                    },
                    {
                        title: '组织名称',
                        width: 90,
                        dataIndex: 'orgName',
                    },
                    {
                        title: '商品名称',
                        dataIndex: 'goodsName',
                    },
                    {
                        title: '订单金额',
                        dataIndex: 'amount',
                        width: 90,
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'status',
                        width: 90,
                        render: (value: any) => OrderStatusMsg[value],
                    },
                    {
                        title: '订单来源',
                        width: 90,
                        dataIndex: 'orderSource',
                    },
                    {
                        title: '下单时间',
                        dataIndex: 'createTime',
                        width: 180,
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作',
                        width: 240,
                        fixed: 'right',
                        render: (_, row) => (
                            <>
                                <Button
                                    style={{ marginRight: 4 }}
                                    type="primary"
                                    onClick={() => {
                                        setOrderVisible(true);
                                        setEditable(false);
                                        setExtraData({ orderId: row.orderId });
                                    }}
                                >
                                    详情
                                </Button>
                                {row.status === OrderStatus.Unpaid ? (
                                    <>
                                        <Button
                                            style={{ marginRight: 4 }}
                                            type="primary"
                                            onClick={() => {
                                                setPayVisible(true);
                                                setExtraData({ orderId: row.orderId });
                                            }}
                                        >
                                            支付
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setOrderVisible(true);
                                                setEditable(true);
                                                setExtraData({ orderId: row.orderId, amount: row.amount });
                                            }}
                                        >
                                            修改
                                        </Button>
                                    </>
                                ) : null}
                            </>
                        ),
                    },
                ]}
            />
            <OrderModal
                title={'详情/修改'}
                extraData={extraData}
                editable={editable}
                visible={orderVisible}
                onCancel={() => setOrderVisible(false)}
                marketingEditable={false}
                onSubmit={form => {
                    if (editable) {
                        // 修改订单
                        putOrder(
                            extraData.orderId,
                            form.items.map((item: any) => ({
                                orderItemId: item.orderItemId,
                                [item.goodsType === SkuType.License ? 'buyUsedAmount' : 'skuNum']:
                                    item.goodsType === SkuType.License ? +item.buyUsedAmount : +item.num,
                                agreementPrice: +item.agreementPrice,
                            }))
                        ).then(() => {
                            setOrderVisible(false);
                            refresh(page, pageSize);
                        });
                    } else {
                        setOrderVisible(false);
                    }
                }}
            />
            <PayModal
                visible={payVisible}
                onCancel={() => setPayVisible(false)}
                // amount={extraData?.amount}
                orderId={extraData?.orderId}
                onOk={() => {
                    setPayVisible(false);
                    refresh(page, pageSize);
                }}
            />
        </div>
    );
};
