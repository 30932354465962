import { Button, Form, Input, Select, Table, DatePicker, message } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';

// 假设导入了相应的API和Modal，您可能需要根据实际情况调整
import {
    queryInvoice,
    InvoiceIssueTypeText,
    InvoiceIssueType,
    InvoiceStatus,
    InvoiceStatusText,
    InvoiceTypeText,
    InvoiceType,
    InvoiceMediumText,
    InvoiceStatusOptions,
    updateInvoice,
} from '@services/api/invoice';
// import { InvoiceNewModal } from '@biz/invoice/new';
import InvoiceDetailModal from '@shared/components/biz/finance/invoice/invoice-detail';

const { Item } = Form;
const { RangePicker } = DatePicker;

const InvoiceIssueTypeOptions = [
    {
        label: '个人',
        value: InvoiceIssueType.Personal,
    },
    {
        label: '企业',
        value: InvoiceIssueType.Enterprise,
    },
];

const InvoiceTypeOptions = [
    {
        label: '增值税普通发票',
        value: InvoiceType.Normal,
    },
    {
        label: '增值税专用发票',
        value: InvoiceType.Professional,
    },
];

// // InvoiceStatus 0 处理中、1 完成 2 驳回，3 存疑 4 用户取消  -1 作废
// 已完成/驳回/存疑待确认/处理中/用户取消/作废

export const InvoiceList = () => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    // const [newVisible, setNewVisible] = useState<boolean>(false);
    const [detailVisible, setDetailVisible] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<any>();
    // invoiceBalance
    const [invoiceBalance, setInvoiceBalance] = useState<number>(0);

    const query = (newPage: number, newPageSize?: number) => {
        setPage(newPage);
        setPageSize(newPageSize || pageSize);

        const params = form.getFieldsValue();

        let applyTimeStart;
        let applyTimeEnd;

        if (params.applyTime) {
            [applyTimeStart, applyTimeEnd] = params.applyTime;
        }

        if (params.orgId === '') {
            delete params.orgId;
        }

        if (applyTimeStart && applyTimeEnd) {
            params.startDate = applyTimeStart.format('YYYY-MM-DD');
            params.endDate = applyTimeEnd.format('YYYY-MM-DD');
        }

        delete params.applyTime; // 由于你不再需要"applyTime"字段，所以从参数中删除它

        params.page = newPage;
        params.pageSize = newPageSize || pageSize;

        queryInvoice(params).then(rep => {
            setData(rep.data.invoices ?? []);
            setTotal(rep.data.total);
            setInvoiceBalance(rep.data.invoiceBalance);
        });
    };

    useEffect(() => {
        query(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form form={form} layout="inline">
                <Item label="申请账号orgId" name="orgId">
                    <Input placeholder="请填写申请账号ID" />
                </Item>
                <Item label="申请时间" name="applyTime">
                    <RangePicker />
                </Item>
                <Item label="开具类型" name="invoiceToType">
                    <Select placeholder="请选择开具类型" options={InvoiceIssueTypeOptions} />
                </Item>
                <Item label="发票类型" name="invoiceType">
                    <Select placeholder="请选择发票类型" options={InvoiceTypeOptions} />
                </Item>
                <Item label="开票状态" name="status">
                    <Select placeholder="请选择开票状态" options={InvoiceStatusOptions} />
                </Item>
            </Form>
            <div style={{ marginTop: 12 }}>
                <Button type="primary" onClick={() => query(1, pageSize)}>
                    搜索
                </Button>
                <Button
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        form.resetFields();
                        query(1, pageSize);
                    }}
                >
                    重置
                </Button>
            </div>
            <Table
                style={{ marginTop: 12 }}
                dataSource={data}
                columns={[
                    {
                        title: '申请时间',
                        dataIndex: 'createTime',
                        // parse time format like this : 2023-10-19T17:18:42+08:00
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '申请人ID',
                        dataIndex: 'userId',
                    },
                    {
                        title: '申请账号orgId',
                        dataIndex: 'orgId',
                    },
                    {
                        title: '申请开票金额',
                        dataIndex: 'amount',
                        render: value => `${value / 100}元`,
                    },
                    {
                        title: '发票开具类型',
                        dataIndex: 'invoiceToType',
                        render: value => InvoiceIssueTypeText[value as InvoiceIssueType],
                    },
                    {
                        title: '发票类型',
                        dataIndex: 'invoiceType',
                        render: value => InvoiceTypeText[value as InvoiceType],
                    },
                    {
                        title: '发票介质',
                        dataIndex: 'invoiceType',
                        render: value => InvoiceMediumText[value as InvoiceType],
                    },
                    {
                        title: '开票状态',
                        dataIndex: 'status',
                        render: value => InvoiceStatusText[value as InvoiceStatus],
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        render: (id: number, row: any) => (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setInvoiceData(row);
                                        setDetailVisible(true);
                                    }}
                                >
                                    查看详情及结果录入
                                </Button>
                            </>
                        ),
                    },
                ]}
                pagination={{
                    total,
                    current: page,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: (newPage, newPageSize) => {
                        query(newPage, newPageSize);
                    },
                }}
            />
            {/* <InvoiceNewModal
                onSubmit={() => {
                    setNewVisible(false);
                    query(1, pageSize);
                }}
                visible={newVisible}
                onCancel={() => setNewVisible(false)}
            /> */}
            <InvoiceDetailModal
                data={invoiceData}
                invoiceBalance={invoiceBalance}
                visible={detailVisible}
                onSubmit={(data: any) => {
                    updateInvoice(data.id, data.params).then(rep => {
                        if (rep.code !== 0) {
                            message.error(rep.err.msg);
                        } else {
                            message.success('操作成功');
                            window.location.reload();
                        }
                    });
                    setDetailVisible(false);
                }}
                onCancel={() => setDetailVisible(false)}
            />
        </>
    );
};
