import { Button, Form, Input, message, Select, Space, Spin, Tag, Row, Col } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';
import { OrgTag, OrgType } from '@/shared/model/type/org/org.type';
import { fetchOrgDetail, fetchOrgTags, fetchOrgTypes, modifyOrgDetail } from '@/services/api/org';
import { OrderModal } from '@/shared/components/biz/order-modal';
import { FinanceAdjustModal } from '@/shared/components/biz/finance/adjust/finance-adj';

import { fetchStock, fetchWalletInfo, financeAdjust } from '@services/api/finance';
import { createOrder } from '@services/api/order';
import { PayModal } from '@/shared/components/biz/order-modal/pay';
import { WithdrawModal } from '@/shared/components/withdraw-modal';
import { createLicenseOrder, getOrgLicenses, getOrgProductLine } from '@services/api/license';
// import { createBizEntity, hasBizEntity } from '@services/api/biz-entity';
import { AuthModal } from './components/auth-modal';
import OrgDetailEditText from './components/edit-text';
import styles from './index.module.css';

const { Item } = Form;

const Source = {
    1: 'Lebesgue',
    2: 'Hermite',
    3: 'Hermite私有化',
    4: 'Bohrium',
    6: 'Piloteye',
    7: 'Unifinder',
    0: '其他',
} as { [key: number]: string };

const OrgDetail: React.FC = React.memo(function OrgDetail() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const formValuesRef = useRef<any>();
    const [editState, setEditState] = useState<boolean>(!!(location.state as any)?.edit);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [orgTypeState, setOrgTypeState] = useState<OrgType[]>([]);
    const [orgTagState, setOrgTagState] = useState<OrgTag[]>([]);
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [orderModalVisible, setOrderModalVisible] = useState(false);
    const [financeAdjustModalVisible, setFinanceAdjustModalVisible] = useState(false);
    const [withdrawModalVisibleState, setWithdrawModalVisibleState] = useState<boolean>(false);
    const [wallet, setWallet] = useState<any>({});
    const [resources, setResources] = useState<any>([]);
    const [orderId, setOrderId] = useState<any>();
    const [payVisible, setPayVisible] = useState<any>(false);
    const [bizLines, setBizLines] = useState<any[]>([]);
    const [licenses, setLicenses] = useState<any[]>([]);
    // const [bizEntityVisible, setBizEntityVisible] = useState<boolean>(false);
    // const [hasEntity, setHasEntity] = useState<boolean>(false);
    const from = useMemo(() => (location.state as any)?.from || '/orgList', [location]);
    const orgTypeMap = useMemo(() => {
        const map = new Map<string, OrgType>();
        orgTypeState.forEach(type => {
            map.set(String(type.id), type);
        });
        return map;
    }, [orgTypeState]);
    const orgTagMap = useMemo(() => {
        const map = new Map<string, OrgTag>();
        orgTagState.forEach(tag => {
            map.set(String(tag.id), tag);
        });
        return map;
    }, [orgTagState]);

    const loadOrgDetail = async () => {
        setLoadingState(true);
        try {
            const { data } = await fetchOrgDetail(Number(id));
            const rep = await getOrgProductLine(+id!);
            getOrgLicenses(+id!).then(rep => {
                setLicenses(rep.data);
            });
            if (!data) {
                return setLoadingState(false);
            }
            setBizLines(rep.data);
            const values = {
                ...data,
                email: data?.user?.email || '',
                tags: data.tag.map((t: OrgTag) => String(t.id)),
                biz: rep.data.map((t: any) => t.businessId),
            };
            formValuesRef.current = values;
            form.setFieldsValue(values);
            setLoadingState(false);
        } catch (e) {
            setLoadingState(false);
        }
    };

    useEffect(() => {
        loadOrgDetail();
        fetchOrgTags().then(({ data }) => {
            setOrgTagState(data);
        });
        fetchOrgTypes().then(({ data }) => {
            setOrgTypeState(data);
        });
        fetchWalletInfo(+id!, '').then(rep => {
            setWallet(rep.data);
        });
        fetchStock(+id!).then(rep => {
            setResources(rep.data.items);
        });
        // hasBizEntity(+id!).then(rep => {
        //     setHasEntity(rep.data.is_certificate);
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.detail}>
            <Button
                className={styles.back}
                icon={<RollbackOutlined />}
                onClick={() => {
                    navigate(from);
                }}
            >
                返回组织列表
            </Button>
            <Row>
                <Col span={12}>
                    <Form
                        className={styles.form}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            offset: 1,
                            span: 16,
                        }}
                    >
                        <div className={styles.content}>
                            {[
                                {
                                    label: '组织ID',
                                    name: 'id',
                                    edit: false,
                                },

                                {
                                    label: '组织名称',
                                    name: 'name',
                                    // edit: editState,
                                },
                                {
                                    label: '单位全称/姓名',
                                    name: 'fullName',
                                    edit: editState,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                                {
                                    label: '组织分类',
                                    name: 'orgType',
                                    edit: editState,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    form: (
                                        <Select
                                            options={orgTypeState.map(({ id, name }) => ({
                                                label: name,
                                                value: id,
                                            }))}
                                        />
                                    ),
                                    render: (type: string) => orgTypeMap.get(String(type))?.name,
                                },
                                {
                                    label: '管理员',
                                    name: 'email',
                                    edit: false,
                                },
                                // {
                                //     label: '联系人',
                                //     name: 'contactName',
                                //     edit: editState,
                                // },
                                // {
                                //     label: '联系手机',
                                //     name: 'contactPhone',
                                //     edit: editState,
                                //     rules: [
                                //         () => ({
                                //             validator(_: any, value: string) {
                                //                 if (formValuesRef.current.contactPhone && !value) {
                                //                     return Promise.reject(new Error('该字段不允许删除'));
                                //                 }
                                //                 if (!value) {
                                //                     return Promise.resolve();
                                //                 }
                                //                 if (value.length < 11 || value.length > 16) {
                                //                     return Promise.reject(new Error('该字段不允许删除'));
                                //                 }
                                //
                                //                 return Promise.resolve();
                                //             },
                                //         }),
                                //     ],
                                // },
                                // {
                                //     label: '联系座机',
                                //     name: 'telNo',
                                //     edit: editState,
                                //     rules: [
                                //         () => ({
                                //             validator(_: any, value: string) {
                                //                 if (formValuesRef.current.telNo && !value) {
                                //                     return Promise.reject(new Error('不允许删除联系座机'));
                                //                 }
                                //                 if (!value) {
                                //                     return Promise.resolve();
                                //                 }
                                //                 if (!/\d{3,4}-\d{7,8}/.test(value)) {
                                //                     return Promise.reject(
                                //                         new Error('请输入正确的座机号码，示例：1234-12345678')
                                //                     );
                                //                 }
                                //
                                //                 return Promise.resolve();
                                //             },
                                //         }),
                                //     ],
                                // },
                                // {
                                //     label: '项目数',
                                //     name: 'todo',
                                //     edit: false,
                                // },
                                // {
                                //     label: '任务数',
                                //     name: 'todo',
                                //     edit: false,
                                // },
                                // {
                                //     label: '余额',
                                //     name: 'todo',
                                //     edit: false,
                                // },
                                {
                                    label: 'Pairs余额',
                                    name: 'pairs',
                                    edit: false,
                                },
                                {
                                    label: '注册来源',
                                    name: 'source',
                                    render: (value: number) => Source[value],
                                    edit: editState,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    form: (
                                        <Select
                                            options={Object.keys(Source).map((key: string) => ({
                                                label: Source[+key],
                                                value: +key,
                                            }))}
                                        />
                                    ),
                                },
                                {
                                    label: '开通的产品',
                                    name: 'biz',
                                    render: () => (
                                        <>
                                            {bizLines.map(line => (
                                                <Tag key={line.businessName}>{line.businessName}</Tag>
                                            ))}
                                        </>
                                    ),
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    form: (
                                        <Select
                                            mode="multiple"
                                            options={[
                                                { label: 'Hermite', value: 1 },
                                                { label: 'Bohrium', value: 2 },
                                                { label: 'Piloteye', value: 3 },
                                                { label: 'Unifinder', value: 4 },
                                            ]}
                                        />
                                    ),
                                    edit: editState,
                                },
                                {
                                    label: 'License/开通模块',
                                    name: 'license',
                                    render: () => (
                                        <>
                                            {licenses.map(l => (
                                                <Tag key={l.licenseName}>{l.licenseName}</Tag>
                                            ))}
                                        </>
                                    ),
                                    edit: false,
                                },
                                {
                                    label: '创建时间',
                                    name: 'createTime',
                                    edit: false,
                                    render: (time: string) => (time ? moment(time).format('YYYY-MM-DD hh:mm:ss') : '-'),
                                },
                                {
                                    label: '最后使用时间',
                                    name: 'updateTime',
                                    edit: false,
                                    render: (time: string) => (time ? moment(time).format('YYYY-MM-DD hh:mm:ss') : '-'),
                                },
                                // {
                                //     label: '销售',
                                //     name: 'saleName',
                                //     edit: editState,
                                // },
                                {
                                    label: '用户标签',
                                    name: 'tags',
                                    edit: editState,
                                    form: (
                                        <Select
                                            mode="tags"
                                            options={orgTagState.map(({ id, name }) => ({
                                                label: name,
                                                value: String(id),
                                            }))}
                                        />
                                    ),
                                    render: (tags: string[]) => (
                                        <>
                                            {tags?.map(t => (
                                                <Tag key={t} color="geekblue">
                                                    {orgTagMap.get(String(t))?.name}
                                                </Tag>
                                            ))}
                                        </>
                                    ),
                                },
                                {
                                    label: '备注',
                                    name: 'remark',
                                    edit: editState,
                                },
                                // {
                                //     label: '社会统一信用代码',
                                //     name: 'todo',
                                //     edit: editState,
                                // },
                                // {
                                //     label: '营业执照号',
                                //     name: 'todo',
                                //     edit: editState,
                                // },
                                // {
                                //     label: '身份证号',
                                //     name: 'todo',
                                //     edit: editState,
                                // },
                            ].map(({ label, name, edit, rules, form, render }) => (
                                <Item key={name} className={styles.item} label={label} name={name} rules={rules}>
                                    <OrgDetailEditText edit={edit} render={render}>
                                        {form ?? <Input allowClear spellCheck={false} autoComplete="off" />}
                                    </OrgDetailEditText>
                                </Item>
                            ))}
                        </div>
                        <Item
                            className={styles.buttons}
                            wrapperCol={{
                                offset: 6,
                            }}
                        >
                            <Space>
                                {editState ? (
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                const values = form.getFieldsValue();
                                                const { tags, ...params } = values;
                                                Object.keys(params).forEach(key => {
                                                    if (params[key] === '') {
                                                        delete params[key];
                                                    }
                                                });
                                                setLoadingState(true);
                                                form.validateFields()
                                                    .then(() => {
                                                        modifyOrgDetail({
                                                            ...params,
                                                            registerSource: params.source,
                                                            productLine: params.biz,
                                                            tagIds: tags.map((s: string) => Number(s)),
                                                        })
                                                            .finally(() => {
                                                                setLoadingState(false);
                                                                setEditState(false);
                                                            })
                                                            .then(() => {
                                                                loadOrgDetail();
                                                                message.success('修改信息成功！');
                                                            });
                                                    })
                                                    .catch(() => {
                                                        setLoadingState(false);
                                                    });
                                            }}
                                        >
                                            提交
                                        </Button>
                                        <Button onClick={() => form.setFieldsValue(formValuesRef.current)}>重置</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button type="primary" onClick={() => setEditState(true)}>
                                            编辑
                                        </Button>
                                        {/* {hasEntity ? ( */}
                                        {/*    <Button */}
                                        {/*        type="primary" */}
                                        {/*        onClick={() => { */}
                                        {/*            deleteBizEntity(1, +id!).then(() => { */}
                                        {/*                hasBizEntity(+id!).then(rep => { */}
                                        {/*                    setHasEntity(rep.data.is_certificate); */}
                                        {/*                }); */}
                                        {/*            }); */}
                                        {/*        }} */}
                                        {/*    > */}
                                        {/*        取消实名 */}
                                        {/*    </Button> */}
                                        {/* ) : ( */}
                                        {/*    <Button type="primary" onClick={() => setBizEntityVisible(true)}> */}
                                        {/*        实名认证 */}
                                        {/*    </Button> */}
                                        {/* )} */}

                                        {/* 后端还没开发完 先注释 */}
                                        {false && (
                                            <Button type="primary" onClick={() => setWithdrawModalVisibleState(true)}>
                                                提现
                                            </Button>
                                        )}
                                        <Button type="primary" onClick={() => setOrderModalVisible(true)}>
                                            代下单
                                        </Button>

                                        <Button type="primary" onClick={() => setFinanceAdjustModalVisible(true)}>
                                            调账
                                        </Button>
                                        {/* <Button type="primary" onClick={() => setAuthModalVisible(true)}> */}
                                        {/*    授权模块 */}
                                        {/* </Button> */}
                                    </>
                                )}
                            </Space>
                        </Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <Form
                        style={{ border: '1px solid #999', marginLeft: 12, minWidth: 600 }}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            offset: 1,
                            span: 16,
                        }}
                    >
                        <Item className={styles.item} label="账户余额">
                            {wallet.balance}
                        </Item>
                        <Item className={styles.item} label="优惠余额">
                            {wallet.couponBalance}
                        </Item>
                        {resources.map((resource: any) => (
                            <>
                                <Item className={styles.item} label={`${resource.resourceName}余额`}>
                                    {resource.buyStockNum}
                                </Item>
                                <Item className={styles.item} label={`赠送${resource.resourceName}余额`}>
                                    {resource.giveStockNum}
                                </Item>
                            </>
                        ))}
                    </Form>
                </Col>
            </Row>

            {loadingState && (
                <div className={styles.mask}>
                    <Spin className={styles.spin} size="large" />
                </div>
            )}
            <AuthModal
                org={{
                    id: +id!,
                    name: form.getFieldValue('name'),
                }}
                visible={authModalVisible}
                onSuccess={data => {
                    const { license, isFree, buyUsedAmount } = data;
                    createLicenseOrder({ orgId: +id!, licenseId: license.id, isFree, buyUsedAmount }).then(rep => {
                        if (!isFree) {
                            localStorage.setItem('_orderId', rep.data.orderId);
                            window.location.href = '/trade/order';
                        } else {
                            message.success('创建成功');
                        }
                        setAuthModalVisible(false);
                    });
                }}
                onCancel={() => setAuthModalVisible(false)}
            />
            <WithdrawModal
                visible={withdrawModalVisibleState}
                onCancel={() => setWithdrawModalVisibleState(false)}
                onOk={() => {
                    setWithdrawModalVisibleState(false);
                }}
                orgId={id ? +id : 0}
                balance={wallet.balance}
            />
            {orderModalVisible ? (
                <OrderModal
                    title={'代下单'}
                    onSubmit={(data: any) => {
                        if (!data.productLine) return message.error('请选择订单产品线');
                        if (!data.items.length) return message.error('请添加商品');
                        createOrder({
                            productLine: data.productLine,
                            orgId: +id!,
                            items: data.items.map((item: any) => ({
                                skuId: item.skuId,
                                marketingType: item.marketingType,
                                agreementPrice: +item.agreementPrice,
                                skuNum: +item.num,
                                buyUsedAmount: +item.buyUsedAmount,
                                goodsType: item.goodsType,
                                licenseId: item.licenseId,
                                priceCycle: item.priceCycle,
                            })),
                        }).then(res => {
                            if (!res.data?.orderId) {
                                // message.success(res.msg || '下单失败');
                                message.error(res?.error?.msg || res?.msg || '下单失败');
                                return;
                            }
                            message.success('下单成功');
                            setPayVisible(true);
                            setOrderId(res.data.orderId);
                        });
                    }}
                    editable
                    marketingEditable
                    extraData={{
                        orgId: id,
                    }}
                    visible={orderModalVisible}
                    onCancel={() => setOrderModalVisible(false)}
                />
            ) : null}
            {financeAdjustModalVisible ? (
                <FinanceAdjustModal
                    title={'调账'}
                    visible={financeAdjustModalVisible}
                    extraData={{
                        orgId: id,
                    }}
                    onSubmit={(data: FormData) => {
                        console.log('-=------', data);
                        financeAdjust(data).then(res => {
                            if (res.code === 0) {
                                message.success('发起成功');
                                setFinanceAdjustModalVisible(false);
                            } else {
                                message.error(res.error?.msg || res.msg || '服务器内部错误');
                            }
                        });
                    }}
                    onCancel={() => setFinanceAdjustModalVisible(false)}
                />
            ) : null}
            {payVisible ? (
                <PayModal
                    visible={payVisible && orderId !== undefined}
                    onCancel={() => {
                        setPayVisible(false);
                        setOrderModalVisible(false);
                    }}
                    // amount={extraData?.amount}
                    orderId={orderId}
                    onOk={() => {
                        setPayVisible(false);
                        setOrderModalVisible(false);
                        window.location.href = '/trade/order';
                    }}
                />
            ) : null}
            {/* <BizEntityModal */}
            {/*    visible={bizEntityVisible} */}
            {/*    editable */}
            {/*    org={{ id: +id!, email: form.getFieldValue('email'), name: form.getFieldValue('name') }} */}
            {/*    onCancel={() => { */}
            {/*        setBizEntityVisible(false); */}
            {/*    }} */}
            {/*    onSubmit={data => { */}
            {/*        const paymentAccount = data.paymentAccount.map((item: any) => { */}
            {/*            delete item.id; */}
            {/*            return item; */}
            {/*        }); */}
            {/*        createBizEntity({ */}
            {/*            orgId: +id!, */}
            {/*            ...data, */}
            {/*            paymentAccount, */}
            {/*        }).then(rep => { */}
            {/*            if (!rep.code) { */}
            {/*                message.success('操作成功'); */}
            {/*                setBizEntityVisible(false); */}
            {/*                hasBizEntity(+id!).then(rep => { */}
            {/*                    setHasEntity(rep.data.is_certificate); */}
            {/*                }); */}
            {/*            } */}
            {/*        }); */}
            {/*    }} */}
            {/* /> */}
        </div>
    );
});

export default OrgDetail;
