import { ACCOUNT_REFILLING } from '../../enum/finance/income.enum';

export const ACCOUNT_REFILLING_OPTIONS = [
    {
        label: '已认领',
        value: ACCOUNT_REFILLING.FINISH,
    },
    {
        label: '未认领',
        value: ACCOUNT_REFILLING.UNFINISH,
    },
    {
        label: '部分认领',
        value: ACCOUNT_REFILLING.PARTIAL,
    },
];
