import React from 'react';

interface OrgDetailEditTextProps {
    edit?: boolean;
    value?: any;
    onChange?: () => void;
    render?: (value: any) => string | React.ReactElement | undefined;
}

const OrgDetailEditText: React.FC<OrgDetailEditTextProps> = React.memo(function OrgDetailEditText(props) {
    const { edit = false, value, onChange, render, children } = props;
    if (edit) {
        if (React.isValidElement(children)) {
            return React.cloneElement(children, {
                value,
                onChange,
            });
        }
        return null;
    }
    if (render) {
        return render(value) ?? null;
    }
    return value ?? null;
});

export default OrgDetailEditText;
