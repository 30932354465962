// import { merge } from 'lodash';
// import baseConfig from './config.base';
// import localConfig from './config.local';
// import productionConfig from './config.prod';
// import testConfig from './config.test';
// import uat from './config.uat';
// // import privateConfig from './config.private';
//
// export enum ENV_TYPE {
//     PRODUCTION = 'production',
//     TEST = 'test',
//     STAGING = 'staging',
//     LOCAL = 'local',
// }
//
// const ENV = process.env.REACT_APP_ENV;
//
// // eslint-disable-next-line import/no-mutable-exports
// let baseGlobalConfig: {
//     apiConfig: {
//         defaultEndpoint: string;
//         endpoints: {};
//         bizPrefix: {};
//     };
//     environment?: string;
// } = merge({}, baseConfig);
//
// if (ENV === 'production') {
//     baseGlobalConfig = merge(baseGlobalConfig, productionConfig);
// } else if (ENV === 'test') {
//     baseGlobalConfig = merge(baseGlobalConfig, testConfig);
// } else if (ENV === 'staging') {
//     baseGlobalConfig = merge(baseGlobalConfig, uat);
// } else if (ENV === 'private') {
//     // baseGlobalConfig = merge(baseGlobalConfig, privateConfig);
// } else {
//     baseGlobalConfig = merge(baseGlobalConfig, localConfig);
// }

export default {
    apiConfig: {
        // default api prefix
        defaultEndpoint: `/api`,
        // endpoints map
    },
    environment: 'test',
};
