import axios from '@/shared/utils/axios';
import { Response } from '@/shared/model/type/response.type';

export enum ComboType {
    Sku = 1,
    License,
}

export enum SkuType {
    Sku = 1,
    License,
}

export enum ComboStatus {
    Deleted = -1,
    Invalid,
    Valid,
    Auditing,
}
export const ComboStatusText = {
    [ComboStatus.Deleted]: '已删除',
    [ComboStatus.Invalid]: '无效',
    [ComboStatus.Valid]: '有效',
    [ComboStatus.Auditing]: '审批中',
};

export const queryCombo = (params: { page: number; pageSize: number; id?: string; name?: string; status?: number }) => {
    return axios.get('/v1/tp/combo/list', {
        params,
    }) as Promise<Response>;
};

export const createCombo = (data: {
    name: string;
    // status: number;
    couponBalance: number;
    balance: number;
    originalPrice: number;
    price: number;
    items: {
        skuType: number;
        name: string;
        skuId: number;
        licenseId: number;
        buyUsedAmount: number;
        num: number;
        originalPrice: number;
        price: number;
    }[];
}) => {
    return axios.post('/v1/tp/combo/create', data) as Promise<Response>;
};

export const sendCombo = (data: { comboId: number; orgId: number }) => {
    return axios.post('/v1/tp/combo/deliver', data) as Promise<Response>;
};

export const getCombo = (comboId: number | string) => {
    return axios.get(`/v1/tp/combo/detail/${comboId}`) as Promise<Response>;
};

export const updateCombo = (comboId: number | string, status: number) => {
    return axios.post(
        '/v1/tp/combo/update',
        {
            comboId,
            status,
        },
        {
            toastSuccess: '操作成功',
            toastFail: false,
        }
    ) as Promise<Response>;
};

export const getComboHistory = (orgId: number | string) => {
    return axios.get(`/v1/tp/combo/history/${orgId}`) as Promise<Response>;
};
