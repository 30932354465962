import axios from '@/shared/utils/axios';
import { AxiosResponse, Response } from '@/shared/model/type/response.type';
import { UsersListParams, NewUsersParams } from '@/shared/model/type/users/user.type';

export function fetchUsers(params: UsersListParams) {
    return axios.get('/v1/users', {
        toastFail: false,
        params: {
            ...params,
        },
    }) as Promise<Response>;
}

export function createUsers(params: NewUsersParams) {
    return axios.post(
        '/v1/users',
        {
            ...params,
        },
        {
            toastSuccess: '创建用户成功',
            toastFail: false,
        }
    ) as Promise<Response>;
}

export function createUsersV3(params: NewUsersParams) {
    return axios.post(
        '/v3/users',
        {
            ...params,
        },
        {
            toastSuccess: '创建用户成功',
            toastFail: false,
        }
    ) as Promise<Response>;
}

export function openFEPPermission(emails: string, open: number) {
    return axios.post(
        '/v1/users/fep',
        {
            emails,
            open,
        },
        {
            toastFail: open ? '开通失败' : '禁用失败',
            toastSuccess: open ? '开通成功' : '禁用成功',
        }
    );
}

export function updateUser(userId: number, data: any) {
    return axios.post('/v1/users/edit', {
        userId,
        ...data,
    }) as Promise<AxiosResponse<any>>;
}

export function resetUserPwd(userId: number) {
    return axios.post('/v1/users/reset_pwd', {
        userId,
    });
}
