import { Form, Input, InputNumber, Modal, ModalProps, Radio } from 'antd';
import { withdraw } from '@/services/api/finance';
import { BalanceTypeEnum, WithdrawTypeEnum } from '@/shared/model/enum/withdraw';

const { Item } = Form;
export const WithdrawModal = (
    props: ModalProps & {
        orgId: number;
        balance: number;
    }
) => {
    const { orgId, balance, visible, onCancel = () => {}, onOk = () => {}, ...restProps } = props;
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="余额提现"
            onCancel={onCancel}
            {...restProps}
            onOk={e => {
                withdraw({
                    orgId,
                    withdrawType: form.getFieldsValue().withdrawType,
                    balanceType: form.getFieldsValue().balanceType,
                    amount: form.getFieldsValue().amount,
                    remark: form.getFieldsValue().remark,
                });
                onOk(e);
            }}
        >
            <Form form={form}>
                <Item name="balanceType" label="余额类型" required>
                    <Radio.Group>
                        <Radio value={BalanceTypeEnum.cash}>账户余额</Radio>
                        <Radio value={BalanceTypeEnum.coupon}>优惠余额</Radio>
                    </Radio.Group>
                </Item>
                <Item name="amount" label="提现金额" required>
                    <InputNumber min={0} max={balance} />
                </Item>
                <Item name="withdrawType" label="提现类型" required>
                    <Radio.Group>
                        <Radio value={WithdrawTypeEnum.backgroundWithdraw}>后台提现</Radio>
                        <Radio value={WithdrawTypeEnum.userWithdraw}>客户提现</Radio>
                        <Radio value={WithdrawTypeEnum.adjustAccount}>调账</Radio>
                        <Radio value={WithdrawTypeEnum.logoutAccount}>账户注销</Radio>
                    </Radio.Group>
                </Item>
                <Item name="remark" label="备注">
                    <Input maxLength={500} />
                </Item>
            </Form>
        </Modal>
    );
};
