import { Button, DatePicker, Form, InputNumber, message, Modal, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { queryRate, setRate } from '@services/api/rate';

const { Item } = Form;
const { RangePicker } = DatePicker;
export const RateManagement = () => {
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const query = (params: { page: number; pageSize: number; date?: string }) => {
        setPage(params.page);
        setPageSize(params.pageSize);
        queryRate(params).then(rep => {
            setData(rep.data.items);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        query({ page: 1, pageSize });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Form form={form}>
                <Item label="日期" name="date">
                    <DatePicker />
                </Item>
            </Form>
            <Row justify="space-between" align="middle" style={{ marginBottom: 8 }}>
                <Row>
                    <Button
                        onClick={() => {
                            setPage(1);
                            query({ page: 1, pageSize, date: form.getFieldValue('date')?.format('YYYY-MM-DD') });
                        }}
                        type="primary"
                    >
                        搜索
                    </Button>
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                            form.resetFields();
                            query({ page: 1, pageSize });
                        }}
                    >
                        重置
                    </Button>
                </Row>
                <Button
                    type="primary"
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    新增
                </Button>
            </Row>
            <Table
                dataSource={data}
                columns={[
                    {
                        title: '日期',
                        dataIndex: 'date',
                    },
                    {
                        title: '汇率',
                        dataIndex: 'rate',
                    },
                    {
                        title: '币种',
                        render: () => '美元',
                    },
                    {
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '添加时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                ]}
                pagination={{
                    total,
                    pageSize,
                    current: page,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: (newPage, newPageSize) => {
                        query({
                            page: newPage ?? page,
                            pageSize: newPageSize ?? pageSize,
                            date: form.getFieldValue('date'),
                        });
                    },
                }}
            />
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    const { currency, date, rate } = newForm.getFieldsValue();
                    if (!currency || !date.length || !rate) {
                        return message.error('请填写完整');
                    }
                    setRate({
                        currency,
                        startTime: date[0].format('YYYY-MM-DD'),
                        endTime: date[1].format('YYYY-MM-DD'),
                        rate,
                    }).then(() => {
                        message.success('创建成功');
                        setVisible(false);
                        query({ page: 1, pageSize });
                    });
                }}
            >
                <Form
                    initialValues={{
                        currency: 'USD',
                    }}
                    form={newForm}
                >
                    <Item label="日期" name="date">
                        <RangePicker />
                    </Item>
                    <Item label="币种" name="currency">
                        <Select options={[{ value: 'USD', label: '美元' }]} />
                    </Item>
                    <Item label="汇率" name="rate">
                        <InputNumber />
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};
