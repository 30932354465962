export type OrgListParams = {
    perPage: number;
    page: number;
    name?: string;
    orgId?: number;
    orgType?: number;
    email?: string;
    phone?: string;
    startTime?: string;
    endTime?: string;
};

export interface OrgListData {
    id: number;
    name: string;
    orgType: number;
    orgTypeName: string;
    balance: number;
    pairs: number;
    updateTime: string;
    user: { email: string; id: number; phone: string };
    wallet: { balance: number; couponBalance: number };
}

export interface OrgType {
    id: number;
    name: string;
}

export interface OrgTag {
    id: number;
    tagType: number;
    name: string;
}

export enum ProductLine {
    Other,
    lebesgue,
    Hermite,
    HermitePrivate,
    Bohrium,
    Piloteye = 6,
    Unifinder = 7,
}

export type OrgDetailData = OrgListData & {
    createTime: string;
    fullName: string;
    contactName: string;
    contactPhone: string;
    telNo: string;
    saleName: string;
    certNo: string;
    remark: string;
    tag: Array<{ id: number; tagType: number; name: string }>;
};

export interface OrgEditParams {
    id?: number;
    name?: string;
    fullName?: string;
    orgType?: number;
    tag?: string;
    contactName?: string;
    contactPhone?: string;
    telNo?: string;
    registerSource?: number;
    productLine?: number[];
}

export const LICENSE_VOUCHER_RESOURCE = '/api/v1/finance/org_account/deliver';

export enum OrgVoucher {
    Register = 1,
    Compensate,
    Activity,
}
