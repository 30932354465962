export enum BalanceTypeEnum {
    cash = 1,
    coupon,
}

export enum WithdrawTypeEnum {
    backgroundWithdraw = 1,
    userWithdraw, // 客户提现
    adjustAccount,
    logoutAccount,
}
