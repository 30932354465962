import { Button, Form, Input, message, Modal, Row, Table, Col, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    ChargeTypeMsg,
    createSKU,
    fetchGoodAttributeList,
    fetchSKU,
    fetchSKUById,
    SKUStatus,
    SKUStatusMsg,
    updateSKU,
} from '@services/api/goods';
import { AttributeSelector, AttributeValueSelector } from '@shared/components/biz/sku/attribute-selector';
import { BusinessSelector, PriceTypeSelector, SKUStatusSelector } from '@shared/components/biz/selector';
import { CategorySelector } from '@shared/components/biz/sku/category-selector';
import { getConfig } from '@services/api/config';
import { getLeFEOrigin } from '@utils/util';

const { Item } = Form;
const getUnitPrice = (sku: any) => {
    if (sku.pricingElementUnit || sku.timeUnit) {
        return ['元', sku.timeUnit, sku.pricingElementUnit].filter(value => value).join('/');
    }
    return ['元', sku.quantityUnit].filter(value => value).join('/');
};

export const TradeSKULeFE = () => (
    <iframe className="admin-iframe" title="SKU管理" src={`${getLeFEOrigin()}/#/app/admin/sku`} />
);
export const TradeSKU = () => {
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>();
    const [config, setConfig] = useState<any>({});
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        if (params.skuId) {
            params.skuIds = [+params.skuId];
            delete params.skuId;
        }
        return fetchSKU({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        getConfig('sku_conf').then((rep: any) => {
            setConfig(JSON.parse(rep.data.conf_value));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {}, [detail?.categoryId]);
    return (
        <>
            <Form layout="inline" form={form}>
                <Row>
                    <Item label="商品类目" name="categoryId">
                        <CategorySelector style={{ width: 200 }} />
                    </Item>
                    <Item label="SKU name" name="skuName">
                        <Input placeholder="请输入" />
                    </Item>
                    <Item label="SKU ID" name="skuId">
                        <Input placeholder="请输入" />
                    </Item>
                    <Item label="上架状态" name="onSellStatus">
                        <SKUStatusSelector />
                    </Item>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Item label="产品线" name="productLine">
                        <BusinessSelector />
                    </Item>
                    <Item label="属性查询" name="attributeId">
                        <AttributeSelector style={{ width: 200 }} />
                    </Item>
                    <Item label="属性值" name="attributeValueName">
                        <Input />
                    </Item>
                    <Button
                        type="primary"
                        onClick={() => {
                            refresh(1, pageSize);
                        }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            refresh(1, pageSize);
                        }}
                        type="default"
                        style={{ marginLeft: 10 }}
                    >
                        重置
                    </Button>
                </Row>
            </Form>
            <Row style={{ marginBottom: 10, marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        newForm.resetFields();
                        setDetail({
                            priceInfos: [],
                            attributes: [],
                        });
                        setVisible(true);
                    }}
                >
                    添加
                </Button>
            </Row>
            <Table
                rowKey={row => `${row.skuId}-${row.priceId}`}
                size="small"
                scroll={{ x: true }}
                dataSource={data}
                columns={[
                    {
                        title: 'SKU ID',
                        dataIndex: 'skuId',
                        width: 80,
                    },
                    {
                        title: '类目',
                        dataIndex: 'categoryNameCrumbs',
                        width: 300,
                    },
                    {
                        title: 'SKU Name',
                        dataIndex: 'skuName',
                        width: 200,
                    },
                    {
                        title: '产品线',
                        dataIndex: 'productLine',
                        width: 80,
                    },
                    {
                        title: '属性信息',
                        dataIndex: 'attributes',
                        width: 200,
                        render: value =>
                            value.map((item: any) => (
                                <div key={`${item.attributeId}-${item.attributeValueId}`}>
                                    {item.attributeName}: {item.attributeValueName}
                                </div>
                            )),
                    },
                    {
                        title: '消费类型',
                        dataIndex: 'chargingType',
                        render: value => ChargeTypeMsg[value],
                        width: 100,
                    },
                    {
                        title: '状态',
                        dataIndex: 'onSellStatus',
                        render: value => SKUStatusMsg[value],
                        width: 100,
                    },
                    {
                        title: '数量单位',
                        dataIndex: 'quantityUnit',
                        width: 120,
                    },
                    {
                        title: '时长单位',
                        dataIndex: 'timeUnit',
                        width: 120,
                    },
                    {
                        title: '计费量单位',
                        dataIndex: 'pricingElementUnit',
                        width: 120,
                    },
                    {
                        title: '标准单价',
                        dataIndex: 'price',
                        width: 100,
                    },
                    {
                        title: '单位单价',
                        width: 100,
                        render: (value, row) => getUnitPrice(row),
                    },
                    {
                        fixed: 'right',
                        title: '操作',
                        align: 'center',
                        width: 80,
                        render: (value, row) => (
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    fetchSKUById(row.skuId).then(rep => {
                                        const { sku, priceInfos, attrs } = rep.data;
                                        newForm.setFieldsValue({
                                            skuId: sku.id,
                                            categoryId: sku.categoryBackId,
                                            name: sku.name,
                                            enName: sku.enName,
                                            quantityUnitId: sku.quantityUnitId,
                                            pricingElementUnitId: sku.pricingElementUnitId,
                                            measureUnitId: sku.measureUnitId,
                                        });
                                        setDetail({
                                            id: sku.id,
                                            priceInfos: priceInfos.map((item: any) => ({
                                                ...item,
                                                effectStartTime: moment(item.effectStartTime),
                                            })),
                                            attributes: attrs,
                                        });
                                        setVisible(true);
                                    });
                                }}
                            >
                                编辑
                            </Button>
                        ),
                    },
                ]}
                pagination={{
                    current: page,
                    pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        refresh(page, pageSize);
                    },
                    total,
                }}
            />
            <Modal
                width={1200}
                title={detail?.id ? '编辑SKU' : '添加SKU'}
                visible={visible}
                onCancel={() => {
                    setDetail(undefined);
                    setVisible(false);
                }}
                onOk={() => {
                    const data = newForm.getFieldsValue();
                    if (
                        ['skuId', 'categoryId', 'name'].some(key => !data[key]) ||
                        !detail.attributes?.length ||
                        !detail.priceInfos?.length ||
                        detail.attributes.some((attribute: any) => attribute.attributeValueId === undefined) ||
                        detail.priceInfos.some((price: any) => Object.keys(price).some(key => !price[key]))
                    ) {
                        return message.error('请填写完整表单');
                    }
                    const price = detail.priceInfos.reduce((map: any, item: any) => {
                        map[item.productLine] = map[item.productLine] || 0;
                        if (item.onSellStatus === SKUStatus.ONLINE) {
                            map[item.productLine]++;
                        }
                        return map;
                    }, {});

                    if (Object.keys(price).some(key => price[key] > 1)) {
                        return message.error('同产品线仅有一个上线价格');
                    }
                    const action = detail?.id
                        ? updateSKU(detail.id, {
                              ...data,
                              attrValueIds: detail.attributes.map((attribute: any) => attribute.attributeValueId),
                              priceInfos: detail.priceInfos.map((price: any) => ({
                                  id: price.id,
                                  onSellStatus: price.onSellStatus,
                                  price: price.price,
                                  pricingType: price.pricingType,
                                  productLine: price.productLine,
                                  effectStartTime: price.effectStartTime.format('YYYY-MM-DD HH:mm:ss'),
                              })),
                          })
                        : createSKU({
                              ...data,
                              skuId: +data.skuId,
                              attrValueIds: detail.attributes.map((attribute: any) => attribute.attributeValueId),
                              priceInfos: detail.priceInfos.map((price: any) => ({
                                  ...price,
                                  effectStartTime: price.effectStartTime.format('YYYY-MM-DD HH:mm:ss'),
                              })),
                          });
                    action.then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setVisible(false);
                            setDetail(undefined);
                            refresh(1, pageSize);
                        } else {
                            message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                        }
                    });
                }}
            >
                <Form form={newForm} labelCol={{ span: 8 }}>
                    <Row style={{ width: '100%' }}>
                        <Col span={12}>
                            <Item name="skuId" label="SKU ID" required>
                                <Input disabled={detail?.id} />
                            </Item>
                            <Item name="categoryId" label="类目" required>
                                <CategorySelector
                                    disabled={detail?.id}
                                    onSelect={id => {
                                        fetchGoodAttributeList(id).then(rep => {
                                            setDetail({
                                                ...detail,
                                                attributes: rep.data.map((item: any) => ({
                                                    attributeId: item.id,
                                                    attributeName: item.name,
                                                })),
                                            });
                                        });
                                    }}
                                />
                            </Item>
                            <Item label="名称" name="name" required>
                                <Input />
                            </Item>
                            <Item label="英文名" name="enName">
                                <Input />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="quantityUnitId" label="数量单位">
                                <AttributeValueSelector allowEmpty attrId={config?.quantityUnit} />
                            </Item>
                            <Item name="measureUnitId" label="时长单位">
                                <AttributeValueSelector allowEmpty attrId={config?.measureUnit} />
                            </Item>
                            <Item name="pricingElementUnitId" label="计费因子单位">
                                <AttributeValueSelector allowEmpty attrId={config?.pricingElementUnit} />
                            </Item>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: 10 }}>SKU 属性信息</Row>
                <Table
                    rowKey="attributeId"
                    size="small"
                    dataSource={detail?.attributes ?? []}
                    pagination={false}
                    columns={[
                        { title: '属性id', dataIndex: 'attributeId' },
                        { title: '属性名称', dataIndex: 'attributeName' },
                        {
                            title: '属性值',
                            dataIndex: 'attributeValueId',
                            render: (value, row, index) => (
                                <AttributeValueSelector
                                    style={{ width: 200 }}
                                    value={value}
                                    onChange={value => {
                                        detail.attributes[index].attributeValueId = value;
                                        setDetail({
                                            ...detail,
                                            attributes: [...detail.attributes],
                                        });
                                    }}
                                    attrId={row.attributeId}
                                />
                            ),
                        },
                    ]}
                />
                <Row style={{ margin: '10px 0' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            detail.priceInfos.push({});
                            setDetail({
                                ...detail,
                                priceInfos: [...detail.priceInfos],
                            });
                        }}
                    >
                        添加价格
                    </Button>
                </Row>
                <Table
                    size="small"
                    dataSource={detail?.priceInfos ?? []}
                    columns={[
                        { title: 'ID', dataIndex: 'id' },
                        {
                            title: '产品线',
                            dataIndex: 'productLine',
                            render: (value, row, index) => (
                                <BusinessSelector
                                    value={value}
                                    onChange={value => {
                                        detail.priceInfos[index].productLine = value;
                                        setDetail({
                                            ...detail,
                                            priceInfos: [...detail.priceInfos],
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            title: '状态',
                            dataIndex: 'onSellStatus',
                            render: (value, row, index) => (
                                <SKUStatusSelector
                                    value={value}
                                    onChange={value => {
                                        detail.priceInfos[index].onSellStatus = value;
                                        setDetail({
                                            ...detail,
                                            priceInfos: [...detail.priceInfos],
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            title: '价格类型',
                            dataIndex: 'pricingType',
                            render: (value, row, index) => (
                                <PriceTypeSelector
                                    value={value}
                                    onChange={value => {
                                        detail.priceInfos[index].pricingType = value;
                                        setDetail({
                                            ...detail,
                                            priceInfos: [...detail.priceInfos],
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            title: '单价(元)',
                            dataIndex: 'price',
                            render: (value, row, index) => (
                                <Input
                                    type="number"
                                    value={value}
                                    onChange={e => {
                                        if (+e.target.value < 0 || e.target.value.split('.')?.[1]?.length > 2) {
                                            message.error('请设置正确的价格（价格最多设置到小数点后2位)');
                                            return;
                                        }
                                        detail.priceInfos[index].price = e.target.value;
                                        setDetail({
                                            ...detail,
                                            priceInfos: [...detail.priceInfos],
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            title: '价格生效时间',
                            dataIndex: 'effectStartTime',
                            render: (value, row, index) => (
                                <DatePicker
                                    showTime
                                    value={value}
                                    onChange={value => {
                                        detail.priceInfos[index].effectStartTime = value;
                                        setDetail({
                                            ...detail,
                                            priceInfos: [...detail.priceInfos],
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            title: '操作',
                            render: (value, row, index) =>
                                row.id ? null : (
                                    <Button
                                        danger
                                        type="primary"
                                        onClick={() => {
                                            detail.priceInfos.splice(index, 1);
                                            setDetail({
                                                ...detail,
                                                priceInfos: [...detail.priceInfos],
                                            });
                                        }}
                                    >
                                        删除
                                    </Button>
                                ),
                        },
                    ]}
                />
            </Modal>
        </>
    );
};
