import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

export enum GOODS_STATUS {
    Paused,
    Normal,
}

export const GoodsStatusMsg = {
    [GOODS_STATUS.Paused]: '停用',
    [GOODS_STATUS.Normal]: '正常',
} as { [key: string]: string };

export enum SKUStatus {
    ONLINE = 1,
    OFFLINE,
}
export const SKUStatusMsg = {
    [SKUStatus.ONLINE]: '上架',
    [SKUStatus.OFFLINE]: '下架',
} as { [key: string]: string };

export enum CHARGE_TYPE {
    PRE = 1,
    POST,
}
export const ChargeTypeMsg = {
    [CHARGE_TYPE.PRE]: '预付费',
    [CHARGE_TYPE.POST]: '后付费',
} as { [key: string]: string };

export enum PRICE_TYPE {
    FIX = 1,
    STEP,
}
export const PriceTypeMsg = {
    [PRICE_TYPE.FIX]: '固定',
    [PRICE_TYPE.STEP]: '阶梯',
} as { [key: string]: string };

export function fetchClass() {
    return axios.get(`/v1/tp/goods/class`) as Promise<Response>;
}

export function fetchGoods(data: { classId?: number; skuName?: string; page: number; pageSize: number }) {
    return axios.post(`/v1/tp/goods/page`, data) as Promise<Response>;
}

export function getDiscount(orgId: number) {
    return axios.get(`/v1/tp/discount/${orgId}`) as Promise<Response>;
}

export function fetchGoodAttributes(data: { id?: number | string; name?: string; page: number; pageSize: number }) {
    return axios.post(`/v1/goods/attribute/page`, {
        ...data,
        id: data.id ? +data.id : undefined,
    }) as Promise<Response>;
}

export function fetchGoodAttributeList(categoryId?: number) {
    return axios.post(`/v1/goods/attribute/list`, { categoryId }) as Promise<Response>;
}

export function createGoodAttribute(data: { name: string; status: number }) {
    return axios.post(`/v1/goods/attribute/create`, data) as Promise<Response>;
}

export function updateGoodAttribute(id: number, data: { name: string; status: number }) {
    return axios.post(`/v1/goods/attribute/update`, { id, ...data }) as Promise<Response>;
}

export function fetchAttributeValueList(data: {
    attributeId?: number | string;
    attributeName?: string;
    attributeValId?: number | string;
    attributeValueName?: string;
    page: number;
    pageSize: number;
}) {
    return axios.post(`/v1/goods/attribute_value/page`, {
        ...data,
        attributeId: data.attributeId ? +data.attributeId : undefined,
        attributeValId: data.attributeValId ? +data.attributeValId : undefined,
    }) as Promise<Response>;
}

export function createAttributeValue(data: { attrId: number; name: string; status: number }) {
    return axios.post(`/v1/goods/attribute_value/create`, {
        ...data,
        attrId: data.attrId ? +data.attrId : undefined,
    }) as Promise<Response>;
}

export function updateAttributeValue(id: number, data: { value: string; status: number }) {
    return axios.post(`/v1/goods/attribute_value/update`, {
        id,
        ...data,
    }) as Promise<Response>;
}

export function fetchGoodCategories() {
    return axios.post(`/v1/goods/category/listTree`) as Promise<Response>;
}

export function fetchGoodCategory(id: number) {
    return axios.post(`/v1/goods/category/detail`, { id }) as Promise<Response>;
}

export function createGoodCategory(data: {
    attrIds: number[];
    chargingType: number;
    name: string;
    parentId: number;
    status: number;
}) {
    return axios.post(`/v1/goods/category/create`, data) as Promise<Response>;
}

export function updateGoodCategory(
    id: number,
    data: { name: string; status: number; parentId: number; chargingType: number }
) {
    return axios.post(`/v1/goods/category/update`, {
        id,
        ...data,
    }) as Promise<Response>;
}

export function fetchSKU(data: {
    categoryId?: number;
    licenseId?: number;
    page: number;
    pageSize: number;
    productLine?: string;
    skuIds?: number[];
    skuName?: string;
    onSellStatus?: number;
    attributeId?: number | string;
    attributeValueName?: string;
}) {
    return axios.post(`/v1/goods/sku/page`, {
        ...data,
        attributeId: data.attributeId ? +data.attributeId : undefined,
    }) as Promise<Response>;
}

export function fetchSKUById(id: number) {
    return axios.post(`/v1/goods/sku/detail`, { id }) as Promise<Response>;
}

export function createSKU(data: {
    attrValueIds: number[];
    categoryId: number;
    enName: string;
    name: string;
    priceInfos: {
        effectStartTime: string;
        onSellStatus: number;
        price: string;
        priceType: number;
        productLine: string;
    }[];
    measureUnitId: string;
    pricingElementUnitId: string;
    quantityUnitId: string;
    skuId: number;
}) {
    return axios.post(`/v1/goods/sku/create`, data) as Promise<Response>;
}

export function updateSKU(
    skuId: number,
    data: {
        attrValueIds: number[];
        enName: string;
        name: string;
        priceInfos: {
            id?: number;
            effectStartTime: string;
            onSellStatus: number;
            price: string;
            priceType: number;
            productLine: string;
        }[];
        measureUnitId: number;
        pricingElementUnitId: string;
        quantityUnitId: string;
    }
) {
    return axios.post(`/v1/goods/sku/update`, {
        skuId,
        ...data,
    }) as Promise<Response>;
}
