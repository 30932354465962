import React, { useEffect, useState } from 'react';
import { Layout, Menu, SubMenuProps } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { RouteAuthConfig } from '@shared/model/type/router.type';
import { transformRouteConfig, getPathParentMenu, ParentMenuKey } from './util';

const { Sider } = Layout;
const { SubMenu } = Menu;

type TitleEventEntity = Parameters<Required<SubMenuProps>['onTitleClick']>[0];

interface SiderWrapperProps {
    routeAuthConfig: Array<RouteAuthConfig>;
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    defaultOpenKeys?: ParentMenuKey;
    // onRouteChange?: () => void;
}

const SiderWrapper: React.FC<SiderWrapperProps> = function SiderWrapper({
    routeAuthConfig,
    // defaultOpenKeys,
    // onRouteChange = () => {},
}) {
    const location = useLocation();
    const [openKeysState, setOpenKeysState] = useState<ParentMenuKey>([]);
    const [selectKeyState, setSelectKeyState] = useState<ParentMenuKey>([]);

    useEffect(() => {
        transformRouteConfig(routeAuthConfig, []);
        const { pathname } = location;
        const pathArr = pathname.split('/');
        const currentPathKey = [pathArr[pathArr.length - 1]];
        setSelectKeyState(currentPathKey);
        const parentKeys = getPathParentMenu(currentPathKey[0]);
        setOpenKeysState(parentKeys);
    }, [location, routeAuthConfig]);

    const openMenu = (item: TitleEventEntity) => {
        let keysState = [...openKeysState];
        if (!openKeysState.includes(item.key as string)) {
            keysState.push(item.key as string);
            setOpenKeysState(keysState);
            return;
        }
        keysState = keysState.filter(key => key !== item.key);
        setOpenKeysState(keysState);
    };

    const renderMenu = (config: Array<RouteAuthConfig>) => {
        if (!Array.isArray(config) || config.length === 0) {
            return null;
        }
        return config.map(item => {
            return !item.children?.length && item.url ? (
                <Menu.Item key={item.key}>
                    <NavLink to={item.url}>{item.title}</NavLink>
                </Menu.Item>
            ) : (
                <SubMenu key={item.key} title={item.title} onTitleClick={openMenu}>
                    {renderMenu(item.children)}
                </SubMenu>
            );
        });
    };

    return (
        <Sider>
            <Menu theme="dark" openKeys={openKeysState} selectedKeys={selectKeyState} mode="inline">
                {renderMenu(routeAuthConfig)}
            </Menu>
        </Sider>
    );
};

export default SiderWrapper;
