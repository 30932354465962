import { Button, Form, Input, message, Modal, Row, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    BusinessMsg,
    createResource,
    fetchResources,
    ResourceStatus,
    ResourceStatusMsg,
    updateResource,
} from '@services/api/resource';
import { ResourceGroupSelector } from '@shared/components/biz/selector/resource-group';
import { ResourceStatusSelector, BusinessSelector, ActionSelector } from '@shared/components/biz/selector';

const { Item } = Form;
export const TechResourceAuth = () => {
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>();
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        return fetchResources({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Form layout="inline" form={form}>
                <Item name="id" label="ID">
                    <Input placeholder="请输入" allowClear />
                </Item>
                <Item name="name" label="名称">
                    <Input placeholder="请输入" allowClear />
                </Item>
                <Item name="identifier" label="标识符">
                    <Input placeholder="请输入" allowClear />
                </Item>
                <Item name="groupName" label="功能组">
                    <Input placeholder="请输入" allowClear />
                </Item>
                <Item name="businessId" label="产品线">
                    <BusinessSelector useId />
                </Item>
                <Button
                    type="primary"
                    onClick={() => {
                        refresh(1, pageSize);
                    }}
                >
                    搜索
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        refresh(1, pageSize);
                    }}
                    type="default"
                    style={{ marginLeft: 10 }}
                >
                    重置
                </Button>
            </Form>
            <Row style={{ marginBottom: 10, marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setDetail({});
                        setVisible(true);
                        newForm.resetFields();
                        newForm.setFieldsValue({ status: ResourceStatus.Normal });
                    }}
                >
                    添加
                </Button>
            </Row>
            <Table
                rowKey="id"
                scroll={{ x: true }}
                dataSource={data}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '资源标识符',
                        dataIndex: 'identifier',
                    },
                    {
                        title: 'action',
                        dataIndex: 'action',
                    },
                    {
                        width: 80,
                        title: '产品线',
                        dataIndex: 'businessId',
                        render: value => BusinessMsg[value],
                    },
                    {
                        width: 100,
                        title: '功能组ID',
                        dataIndex: 'groupId',
                    },
                    {
                        title: '功能组名称',
                        dataIndex: 'groupName',
                    },
                    {
                        width: 80,
                        title: '状态',
                        dataIndex: 'status',
                        render: value => ResourceStatusMsg[value],
                    },
                    {
                        width: 180,
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        width: 180,
                        title: '添加时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        fixed: 'right',
                        title: '操作',
                        render: (value, row) => (
                            <Button
                                type="primary"
                                onClick={() => {
                                    newForm.setFieldsValue({
                                        identifier: row.identifier,
                                        name: row.name,
                                        action: row.action,
                                        businessId: row.businessId,
                                    });
                                    setDetail(row);
                                    setVisible(true);
                                }}
                            >
                                编辑
                            </Button>
                        ),
                    },
                ]}
                pagination={{
                    current: page,
                    pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        refresh(page, pageSize);
                    },
                    total,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
            <Modal
                title={detail?.id ? '修改资源权限' : '添加资源权限'}
                visible={visible}
                onCancel={() => {
                    setDetail(undefined);
                    setVisible(false);
                }}
                onOk={() => {
                    const data = newForm.getFieldsValue();
                    if (Object.keys(data).some(key => !data[key])) {
                        return message.error('请填写完整表单');
                    }
                    const action = detail?.id
                        ? updateResource(detail.id, {
                              ...data,
                          })
                        : createResource({
                              ...detail,
                              ...data,
                          });
                    action.then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setVisible(false);
                            setDetail(undefined);
                            refresh(1, pageSize);
                        } else {
                            message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                        }
                    });
                }}
            >
                <Form form={newForm} labelCol={{ span: 4 }}>
                    <Item name="identifier" label="标识符" required>
                        <Input disabled={detail?.id} />
                    </Item>
                    <Item name="name" label="名称" required>
                        <Input />
                    </Item>
                    <Item label="功能组" required>
                        <ResourceGroupSelector
                            value={detail?.groupId}
                            onChange={value => {
                                setDetail((prev: any) => ({
                                    ...prev,
                                    groupId: value,
                                }));
                            }}
                        />
                    </Item>
                    <Item name="action" label="action" required>
                        {!detail?.id ? <ActionSelector /> : detail?.action}
                    </Item>
                    <Item name="businessId" label="产品线" required>
                        <BusinessSelector useId />
                    </Item>
                    {!detail?.id ? (
                        <Item name="status" label="状态" required>
                            <ResourceStatusSelector />
                        </Item>
                    ) : null}
                </Form>
            </Modal>
        </>
    );
};
