import { Form, Input, message, Modal, ModalProps, Select, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '@user/index.module.css';
import { ProductLine, OrgTag, OrgType } from '@shared/model/type/org/org.type';
import { createUsersV3 } from '@services/api/users';
import { HTTP_CODE } from '@shared/model/enum';
import { fetchOrgTags, fetchOrgTypes } from '@services/api/org';
import area from '@shared/model/constant/area.json';

const { Item } = Form;
const { TextArea } = Input;

export const NewOrgModal = (props: ModalProps & { onRefresh: () => void }) => {
    const { onCancel = () => {}, visible, onRefresh } = props;
    const [typesState, setTypesState] = useState<OrgType[]>([]);
    const [tagsState, setTagsState] = useState<OrgTag[]>([]);
    const [newUserForm] = Form.useForm();
    const [creatingUserState, setCreatingUserState] = useState<boolean>(false);

    const createUserSubmit = (e: any) => {
        newUserForm.validateFields().then(() => {
            const {
                emails,
                password,
                phoneNumber,
                orgName,
                orgFullName,
                types,
                tags,
                business,
                remark,
                productLine,
                isVerifyPhone,
                isOversea,
                areaCode,
                name,
            } = newUserForm.getFieldsValue();
            setCreatingUserState(true);
            createUsersV3({
                email: emails,
                password,
                phoneNumber,
                orgName,
                orgFullName,
                type: types,
                tagIds: tags,
                business,
                remark,
                productLine,
                isVerifyPhone: isVerifyPhone ? 1 : 0,
                isOversea: isOversea ? 1 : 0,
                areaCode: +areaCode?.split('-')?.[0],
                name,
            })
                .then(rep => {
                    if (!rep.code) {
                        onRefresh();
                        onCancel(e);
                    } else {
                        message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                    }
                })
                .catch(err => {
                    if (err.status === HTTP_CODE.SERVER_UNVALIABLE) {
                        onCancel(e);
                        Modal.info({
                            title: '新建账户',
                            okText: '知道了',
                            content: (
                                <Table
                                    dataSource={err.errorData}
                                    columns={[
                                        { title: '失败账号', dataIndex: 'email', key: 'email' },
                                        {
                                            title: '失败原因',
                                            dataIndex: 'message',
                                            key: 'message',
                                            render: (text: string) => {
                                                return <span className={styles.error}>{text}</span>;
                                            },
                                        },
                                    ]}
                                    pagination={{
                                        pageSize: 10,
                                        showSizeChanger: false,
                                        hideOnSinglePage: true,
                                        size: 'small',
                                    }}
                                />
                            ),
                        });
                        if (emails.length > err.errorData?.length) {
                            onRefresh();
                        }
                    }
                })
                .finally(() => {
                    setCreatingUserState(false);
                });
        });
    };
    useEffect(() => {
        if (visible) {
            fetchOrgTags().then(rep => {
                setTagsState(rep.data);
            });
            fetchOrgTypes().then(rep => {
                setTypesState(rep.data);
            });
            newUserForm.setFieldsValue({
                isVerifyPhone: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    return (
        <Modal
            title="新建客户"
            // visible={visible}
            // onCancel={closeNewUserModal}
            maskClosable={false}
            onOk={createUserSubmit}
            confirmLoading={creatingUserState}
            destroyOnClose
            centered
            visible={visible}
            onCancel={onCancel}
        >
            <div className={styles['form-container']}>
                <Form
                    form={newUserForm}
                    preserve={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    // labelAlign="left"
                    initialValues={{
                        areaCode: '86-China',
                    }}
                >
                    <Item name="emails" label="客户管理员邮箱" rules={[{ required: true, message: '请填写用户邮箱' }]}>
                        <Input placeholder="请输入管理员email" />
                    </Item>
                    <Item name="name" label="用户名">
                        <Input placeholder="用户名" />
                    </Item>
                    <Item name="areaCode" label="国际区号">
                        <Select
                            options={area.map(item => ({
                                label: <span>{`+${item.code} ${item.cn}`}</span>,
                                value: `${item.code}-${item.en}`,
                            }))}
                        />
                    </Item>
                    <Item name="phoneNumber" label="管理员手机号">
                        <Input placeholder="请输入手机号" />
                    </Item>
                    <Item label="管理员是否海外" name="isOversea">
                        <Switch />
                    </Item>
                    <Item label="手机号实名" name="isVerifyPhone">
                        <Switch defaultChecked />
                    </Item>
                    <Item name="password" label="管理员密码" rules={[{ required: true, message: '请填写初始密码' }]}>
                        <Input placeholder="请输入初始密码" />
                    </Item>
                    <Item name="orgName" label="组织名称" rules={[{ required: true, message: '请填写组织名称' }]}>
                        <Input placeholder="客户可修改的名称" />
                    </Item>
                    <Item name="orgFullName" label="组织全称" rules={[{ required: true, message: '请填写组织全称' }]}>
                        <Input placeholder="请输入商务维护的全称字段" />
                    </Item>
                    <Item name="types" label="客户分类" rules={[{ required: true, message: '请选择客户分类' }]}>
                        <Select placeholder="请选择客户分类">
                            {typesState.map(type => (
                                <Select.Option key={type.id} value={type.id}>
                                    {type.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Item>
                    <Item name="tags" label="客户标签" rules={[{ required: true, message: '请选择客户标签' }]}>
                        <Select placeholder="请选择客户标签" mode="tags">
                            {tagsState.map(type => (
                                <Select.Option key={type.id} value={type.id}>
                                    {type.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Item>
                    <Item name="business" label="对接商务">
                        <Input placeholder="请输入对接商务" />
                    </Item>
                    <Item name="productLine" label="注册来源" rules={[{ required: true, message: '请选择注册来源' }]}>
                        <Select placeholder="请选择注册来源">
                            <Select.Option value={ProductLine.Hermite}>Hermite</Select.Option>
                            <Select.Option value={ProductLine.HermitePrivate}>Hermite 私有化</Select.Option>
                            <Select.Option value={ProductLine.Bohrium}>Bohrium</Select.Option>
                            <Select.Option value={ProductLine.lebesgue}>lebesgue</Select.Option>
                            <Select.Option value={ProductLine.Piloteye}>Piloteye</Select.Option>
                            <Select.Option value={ProductLine.Unifinder}>Unifinder</Select.Option>
                            <Select.Option value={ProductLine.Other}>其他</Select.Option>
                        </Select>
                    </Item>
                    <Item name="remark" label="备注">
                        <TextArea placeholder="备注" />
                    </Item>
                </Form>
            </div>
        </Modal>
    );
};
