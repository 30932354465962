import axios from 'axios';
import { message } from 'antd';
import baseConfig from '../config/index';
import loginAxios from './axios-decorators/login';
import apiBaseEndpointAxios from './axios-decorators/api-base-endpoint';
import toastAxios from './axios-decorators/toast';
import processResponseAxios from './axios-decorators/process';
import { getToken, clearToken } from './token';

const { apiConfig } = baseConfig;

const AdminAxios = axios.create({
    validateStatus: status => status < 500,
    withCredentials: true,
});

const dpAdminToken = getToken();
if (dpAdminToken) {
    AdminAxios.defaults.headers.common.Authorization = `jwt ${dpAdminToken}`;
}

apiBaseEndpointAxios({
    defaultEndpoint: apiConfig.defaultEndpoint,
    endPoints: {},
    bizPrefix: {},
})(AdminAxios);

loginAxios(() => {
    if (window.location.pathname === '/internal-recharge') return;
    clearToken();
    window.location.href = '/login';
})(AdminAxios);

toastAxios({
    toastSuccess: content => {
        message.success({
            className: 'hrm-message-success',
            content,
            duration: 3,
        });
    },
    toastFail: content => {
        message.error({
            className: 'hrm-message-error',
            content,
            duration: 3,
        });
    },
})(AdminAxios);

processResponseAxios()(AdminAxios);

export default AdminAxios;
