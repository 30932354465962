import { BALANCE_TYPE } from '@user/model';

export enum ACCOUNT_FILLING {
    NOT = 4,
    FINISH = 3,
    PARTIAL = 2,
    UNFINISH = 1,
}

export enum RECHARGE_STATUS {
    Success = 2,
    Waiting = 1,
    Fail = -1,
}

export const ACCOUNT_FILLING_NAME = {
    [ACCOUNT_FILLING.FINISH]: '已结算',
    [ACCOUNT_FILLING.UNFINISH]: '未结算',
    [ACCOUNT_FILLING.PARTIAL]: '部分结算',
    [ACCOUNT_FILLING.NOT]: '不可结算',
};

export const RECHARGE_STATUS_MSG = {
    [RECHARGE_STATUS.Waiting]: '待充值',
    [RECHARGE_STATUS.Success]: '充值成功',
    [RECHARGE_STATUS.Fail]: '充值失效',
};

export enum PAYMENT {
    OFFLINE = 3,
    WECHAT = 1,
    ALIPAY = 2,
    PAYPAL = 5,
    WECHATMINIPROGRAM = 6,
}

export const PAYMENT_NAME = {
    [PAYMENT.OFFLINE]: '线下',
    [PAYMENT.WECHAT]: '微信',
    [PAYMENT.ALIPAY]: '支付宝',
    [PAYMENT.PAYPAL]: 'PayPal',
    [PAYMENT.WECHATMINIPROGRAM]: '微信小程序',
};

export const BALANCE_TYPE_MSG = {
    [BALANCE_TYPE.DISCOUNT_BALANCE]: '优惠充值',
    [BALANCE_TYPE.NORMAL_BALANCE]: '账户充值',
};
