import qs from 'qs';
import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

interface updatePayload {
    docId: number;
    isSend?: number;
    level?: number;
    showTime?: string;
    state: number;
    title: string;
    url?: string;
}

export function createBohriumUpdate(data: {
    isSend: number;
    level: number;
    showTime: string;
    state: number;
    title: string;
    url: string;
}) {
    return axios.post(`/v1/bohrium/doc/add`, data) as Promise<Response>;
}

export function getBohriumUpdatesList(data: { state?: number; page: number; pageSize: number; title?: string }) {
    return axios.get(`/v1/bohrium/doc/list`, {
        params: {
            ...data,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false });
        },
    }) as Promise<Response>;
}

export function getBohriumDetail(id: number) {
    return axios.get(`/v1/bohrium/doc/${id}`) as Promise<Response>;
}

export function updateBohriumDetail(data: updatePayload) {
    return axios.post('/v1/bohrium/doc/update', data) as Promise<Response>;
}
