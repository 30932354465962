import { Button, Form, Input, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ComboStatus, ComboStatusText, queryCombo, updateCombo } from '@services/api/combo';
import { TradeComboNewModal } from '@biz/trade/combo/new';
import { TradeComboDetailModal } from '@biz/trade/combo/detail';

const { Item } = Form;
export const TradeComboList = () => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [newVisible, setNewVisible] = useState<boolean>(false);
    const [detailVisible, setDetailVisible] = useState<boolean>(false);
    const [comboId, setComboId] = useState<number>();
    const query = (newPage: number, newPageSize?: number) => {
        const params = form.getFieldsValue();
        setPage(newPage);
        setPageSize(newPageSize || pageSize);
        queryCombo({
            ...params,
            page: newPage,
            pageSize: newPageSize || pageSize,
        }).then(rep => {
            setData(rep.data.items);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        query(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Form form={form} layout="inline">
                <Item label="套餐名称" name="name">
                    <Input placeholder="请填写套餐名称" />
                </Item>
                <Item label="套餐id" name="id">
                    <Input placeholder="请填写套餐id" />
                </Item>
                <Item>
                    <Button type="primary" onClick={() => query(1, pageSize)}>
                        搜索
                    </Button>
                    <Button
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            form.resetFields();
                            query(1, pageSize);
                        }}
                    >
                        重置
                    </Button>
                    <Button onClick={() => setNewVisible(true)} style={{ marginLeft: 12 }} type="primary">
                        添加
                    </Button>
                </Item>
            </Form>
            <Table
                style={{ marginTop: 12 }}
                dataSource={data}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                    },
                    {
                        title: '套餐名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        render: value => ComboStatusText[value as ComboStatus],
                    },
                    {
                        title: '添加时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作',
                        dataIndex: 'id',
                        render: (id, row: any) => (
                            <>
                                {row.status !== ComboStatus.Invalid ? (
                                    <Button
                                        style={{ marginRight: 8 }}
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            updateCombo(id, 0);
                                        }}
                                    >
                                        失效
                                    </Button>
                                ) : null}
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setComboId(id);
                                        setDetailVisible(true);
                                    }}
                                >
                                    查看详情
                                </Button>
                            </>
                        ),
                    },
                ]}
                pagination={{
                    total,
                    pageSize,
                    onChange: (newPage, newPageSize) => {
                        query(newPage, newPageSize);
                    },
                }}
            />
            <TradeComboNewModal
                onSubmit={() => {
                    setNewVisible(false);
                    query(1, pageSize);
                }}
                visible={newVisible}
                onCancel={() => setNewVisible(false)}
            />
            <TradeComboDetailModal
                id={comboId!}
                visible={detailVisible}
                onOk={() => {
                    setDetailVisible(false);
                }}
                onCancel={() => setDetailVisible(false)}
            />
        </>
    );
};
