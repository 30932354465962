import { GlobalContext } from '@/App';
import { ComboType, createCombo } from '@services/api/combo';
import { LicensePriceCycleMsg } from '@services/api/license';
import { LicenseModal } from '@shared/components/biz/license/modal';
import { SkuModal } from '@shared/components/biz/sku/modal';
import { Button, Form, Input, InputNumber, message, Modal, ModalProps, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';

const { Item } = Form;
export const TradeComboNewModal = (props: ModalProps & { onSubmit: () => void }) => {
    const globalData = useContext(GlobalContext);
    const isAWS = globalData?.env === 'aws';
    const { onSubmit, onCancel = () => {} } = props;
    const [sku, setSku] = useState<any>([]);
    const [licenseVisible, setLicenseVisible] = useState(false);
    const [skuVisible, setSkuVisible] = useState(false);
    const [form] = Form.useForm();
    const onReset = () => {
        form.resetFields();
        setSku([]);
    };
    useEffect(() => {
        form.setFieldsValue({
            status: true,
        });
    });
    return (
        <Modal
            width={800}
            title="新增套餐内容"
            {...props}
            onCancel={e => {
                onReset();
                onCancel(e);
            }}
            onOk={() => {
                const data = form.getFieldsValue();
                if (!data.name) {
                    return message.error('请填写套餐名称');
                }
                if (!sku.length) {
                    return message.error('请添加license或资源包');
                }
                createCombo({
                    ...data,
                    // status: data.status ? ComboStatus.Valid : ComboStatus.Invalid,
                    price: 0,
                    originalPrice: sku.reduce(
                        (total: number, s: any) =>
                            total + +s.originalPrice * (s.skuType === ComboType.License ? s.buyUsedAmount : s.num),
                        0
                    ),
                    items: sku.map((s: any) => ({
                        ...s,
                        originalPrice: +s.originalPrice,
                    })),
                }).then(rep => {
                    if (!rep.code) {
                        message.success('操作成功');
                        onReset();
                        onSubmit();
                    } else {
                        message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                    }
                });
            }}
        >
            <Form form={form}>
                <Item required label="套餐名称" name="name">
                    <Input placeholder="请输入套餐名称" />
                </Item>
                {/* <Item required label="是否有效" name="status"> */}
                {/*    <Switch /> */}
                {/* </Item> */}
                <Item label="优惠余额充值" name="couponBalance">
                    <InputNumber min={0} style={{ width: 200 }} placeholder="请输入金额" />
                </Item>
                {/* <Item label="客户充值金额" name="balance"> */}
                {/*    <InputNumber min={0} style={{ width: 200 }} placeholder="请输入金额" /> */}
                {/* </Item> */}
            </Form>
            <Row>
                {isAWS ? (
                    <span>
                        套餐原价: $
                        {sku.reduce(
                            (total: number, s: any) =>
                                total + +s.originalPrice * (s.skuType === ComboType.License ? s.buyUsedAmount : s.num),
                            0
                        )}
                    </span>
                ) : (
                    <span>
                        套餐原价:{' '}
                        {sku.reduce(
                            (total: number, s: any) =>
                                total + +s.originalPrice * (s.skuType === ComboType.License ? s.buyUsedAmount : s.num),
                            0
                        )}
                        元
                    </span>
                )}

                {isAWS ? (
                    <span style={{ marginLeft: 10 }}>套餐购买价: $ 0</span>
                ) : (
                    <span style={{ marginLeft: 10 }}>套餐购买价：0元</span>
                )}
            </Row>
            <Row style={{ margin: '10px 0' }}>
                <Button type="primary" onClick={() => setLicenseVisible(true)}>
                    增加License
                </Button>
                <Button type="primary" style={{ marginLeft: 12 }} onClick={() => setSkuVisible(true)}>
                    增加资源包
                </Button>
            </Row>
            <Table
                dataSource={sku}
                columns={[
                    {
                        title: '商品名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '类别',
                        dataIndex: 'skuType',
                        render: value => (value === ComboType.License ? 'license' : '资源包'),
                    },
                    {
                        title: '体验时长',
                        dataIndex: 'buyUsedAmount',
                        render: (value, row, index) =>
                            row.skuType === ComboType.License ? (
                                <InputNumber
                                    value={value}
                                    onChange={value => {
                                        sku[index].buyUsedAmount = value;
                                        setSku([...sku]);
                                    }}
                                    min={1}
                                    step={1}
                                />
                            ) : (
                                '--'
                            ),
                    },
                    {
                        title: '周期',
                        dataIndex: 'priceCycle',
                        render: value => LicensePriceCycleMsg[value] ?? '--',
                    },
                    {
                        title: '数量',
                        dataIndex: 'num',
                        render: (value, row, index) =>
                            row.skuType === ComboType.Sku ? (
                                <InputNumber
                                    value={value}
                                    onChange={value => {
                                        sku[index].num = value;
                                        setSku([...sku]);
                                    }}
                                    min={1}
                                    step={1}
                                />
                            ) : (
                                '--'
                            ),
                    },
                    {
                        title: '套餐价',
                        dataIndex: 'price',
                    },
                    {
                        title: '操作',
                        dataIndex: 'name',
                        render: (value, row, index) => (
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    sku.splice(index, 1);
                                    setSku([...sku]);
                                }}
                            >
                                删除
                            </Button>
                        ),
                    },
                ]}
                pagination={false}
            />
            <LicenseModal
                visible={licenseVisible}
                onCancel={() => setLicenseVisible(false)}
                onSubmit={data => {
                    const valid = data.filter((item: any) => item.licenseId !== 1 && item.licenseId !== 2);
                    const inValid = data.filter((item: any) => item.licenseId === 1 || item.licenseId === 2);
                    if (inValid.length) {
                        return message.error(
                            `${inValid.map((item: any) => item.name).join('、')}license不能加入到套餐中`
                        );
                    }
                    setSku(
                        [...sku].concat(
                            valid.map((item: any) => ({
                                skuId: item.skuId,
                                licenseId: item.licenseId,
                                name: item.licenseName,
                                skuType: ComboType.License,
                                buyUsedAmount: 1,
                                num: 1,
                                originalPrice: item.unitPrice,
                                price: 0,
                                priceCycle: item.priceCycle,
                            }))
                        )
                    );
                    setLicenseVisible(false);
                }}
            />
            <SkuModal
                visible={skuVisible}
                onCancel={() => {
                    setSkuVisible(false);
                }}
                onSubmit={selection => {
                    setSku(
                        [...sku].concat(
                            selection.map((item: any) => ({
                                skuId: item.skuId,
                                name: item.skuName,
                                skuType: ComboType.Sku,
                                num: 1,
                                originalPrice: item.price,
                                price: 0,
                            }))
                        )
                    );
                }}
            />
        </Modal>
    );
};
