import React from 'react';

export interface OperationProps {
    status: number;
}

function getStatusInfo(status: number): string {
    switch (status) {
        case 1:
            return '机时费';
        case 2:
            return 'Pairs';
        case 3:
            return '变更FEP权限';
        case 4:
            return '创建用户';
        case 5:
            return '更新用户';
        case 6:
            return '操作记录';
        default:
            return '-';
    }
}

const Status: React.FC<OperationProps> = function Status({ status }) {
    const statusText = getStatusInfo(status);
    return <span>{statusText}</span>;
};

export default Status;
