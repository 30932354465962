import axios from '@/shared/utils/axios';
import { IncomeRecordParams } from '@shared/model/type/finance/income-record.type';
import { Response } from '@shared/model/type/response.type';
import { IncomeListParams } from '@shared/model/type/finance/income-list.type';

export function queryRechargeList(params: IncomeRecordParams) {
    return axios.post('/v1/business_recharge/list', params) as Promise<Response>;
}

export function reverseSettlementWithRecharge(rechargeId: number) {
    return axios.put(`/v1/business_recharge/reverse_settlement/${rechargeId}`) as Promise<Response>;
}

export function updateRecharge(rechargeId: number, data: { settleStatus: number }) {
    return axios.put(`/v1/business_recharge/${rechargeId}`, data) as Promise<Response>;
}

export function claimOffline(id: number, data: { offlineAmount: number; offlineRemark: string }) {
    return axios.put(`/v1/bank/offline_claim/${id}`, data);
}

export function fetchLastRechargeAccount(orgId: number) {
    return axios.get(`/v1/bank/query_last_recharge_account/${orgId}`) as Promise<Response>;
}

export function associateIncome(rechargeId: number, data: { bankBillId: number; relevanceAmount: number }[]) {
    return axios.put(`/v1/bank/relevance_bill/${rechargeId}`, { relevanceBillList: data }) as Promise<Response>;
}

export function bulkDelete(ids: number[]) {
    return axios.delete('/v1/bank/delete', { data: { billIds: ids } }) as Promise<Response>;
}

export function fetchIncomeList(data: IncomeListParams) {
    return axios.post('/v1/bank/list', {
        ...data,
        minAmount: data.minAmount ? +data.minAmount : undefined,
        maxAmount: data.maxAmount ? +data.maxAmount : undefined,
    }) as Promise<Response>;
}

export function importFinanceRecord(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('/v1/bank/import', formData) as Promise<Response>;
}
