import axios from '@/shared/utils/axios';
import { Response } from '@/shared/model/type/response.type';
import { JobListParams } from '@/shared/model/type/jobs/job.type';

export function fetchJobs(params: JobListParams) {
    return axios.get('/v1/jobs', {
        toastFail: false,
        params: {
            ...params,
        },
    }) as Promise<Response>;
}
