export enum ACCOUNT_STATUS {
    DELETE = -1,
    NORMAL = 1,
    FREEZE,
}

export const ACCOUNT_STATUS_NAME = {
    [ACCOUNT_STATUS.DELETE]: '已删除',
    [ACCOUNT_STATUS.NORMAL]: '正常',
    [ACCOUNT_STATUS.FREEZE]: '冻结',
};
