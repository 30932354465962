import Crypto from 'crypto-js';
import axios from '@/shared/utils/axios';
import { UserParams } from '@/shared/model/type/service/auth.type';
import { Response, ResponseData } from '@/shared/model/type/response.type';
import { encodeString } from '@utils/token';

export function signIn({ email, password }: UserParams) {
    return axios.post(
        '/v1/login',
        {
            email,
            password: encodeString(password),
            ext: Crypto.enc.Base64.stringify(
                Crypto.enc.Utf8.parse(
                    JSON.stringify({
                        encrypt_type: 'aes',
                    })
                )
            ),
        },
        {
            toastFail: false,
        }
    ) as Promise<Response>;
}

export function signOut() {}

export function fetchLangConfig() {
    return axios.get('/v1/admin/lang/config') as Promise<
        ResponseData<{
            env: string;
        }>
    >;
}
