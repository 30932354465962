import Crypto from 'crypto-js';

const TOKEN_KEY = 'dpAdminToken';

export function getToken() {
    let dpAdminToken;
    try {
        dpAdminToken = localStorage.getItem(TOKEN_KEY);
    } catch (err) {
        return '';
    }
    return dpAdminToken;
}

export function setToken(token: string) {
    try {
        localStorage.setItem(TOKEN_KEY, token);
        // eslint-disable-next-line no-empty
    } catch (err) {}
}

export function clearToken() {
    setToken('');
}

export function decodeToken() {
    try {
        const token = getToken();
        if (!token) {
            return;
        }
        const tokenInfoArr = token?.split('.') || [];
        if (tokenInfoArr[1]) {
            const decodeUserInfo = atob(tokenInfoArr[1]);
            return JSON.parse(decodeUserInfo);
        }
        // eslint-disable-next-line no-empty
    } finally {
    }
}

export function encodeString(content: string) {
    const keyHex = Crypto.enc.Utf8.parse('pxQ$|2ydTBu<5@+P');
    const ivHex = keyHex.clone();
    const encrypted = Crypto.AES.encrypt(content, keyHex, {
        iv: ivHex,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7,
    });
    return Crypto.enc.Base64.stringify(Crypto.enc.Utf8.parse(encrypted.toString()));
}
