import { Button, Form, Input, Modal, ModalProps, Select, Switch, Table } from 'antd';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { getOrder } from '@services/api/order';
import { MarketingType, MarketingTypeText } from '@shared/model/type/goods/goods.type';
import { ProductLine } from '@shared/model/type/order/order.type';
import { getDiscount } from '@services/api/goods';
import { fetchOrgDetail } from '@services/api/org';
import { SkuModal } from '@shared/components/biz/sku/modal';
import { LicenseModal } from '@shared/components/biz/license/modal';
import { LicensePriceCycleMsg } from '@services/api/license';
import { SkuType } from '@services/api/combo';

const { Item } = Form;
export const OrderModal = (
    props: ModalProps & { editable: boolean; marketingEditable: boolean; extraData: any; onSubmit: (data: any) => void }
) => {
    const { onSubmit = () => {}, onCancel = () => {}, editable, marketingEditable, extraData, ...restProps } = props;
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [licenseVisible, setLicenseVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>({ items: [] });

    const [skuEditable, setSkuEditable] = useState(-1);
    const [skuEditData, setSkuEditData] = useState<any>({});
    const [discount, setDiscount] = useState<any>({});

    useEffect(() => {
        if (restProps.visible) {
            if (editable && extraData?.orgId) {
                getDiscount(extraData?.orgId).then(rep => {
                    if (rep.data.items?.length) {
                        setDiscount(
                            rep.data.items.reduce((total: any, item: any) => {
                                total[item.skuId] = item.discountRate;
                                return total;
                            }, {})
                        );
                    }
                });
            }
            if (extraData?.orderId) {
                getOrder(extraData.orderId).then(rep => {
                    setDetail(rep.data);
                    getDiscount(rep.data.orgId).then(rep => {
                        if (rep.data.items?.length) {
                            setDiscount(
                                rep.data.items.reduce((total: any, item: any) => {
                                    total[item.skuId] = item.discountRate;
                                    return total;
                                }, {})
                            );
                        }
                    });
                    form.setFieldsValue({ productLine: rep.data.productLine });
                });
            }
            if (extraData?.orgId) {
                fetchOrgDetail(extraData?.orgId).then(rep => {
                    setDetail((old: any) => ({
                        ...old,
                        orgName: rep.data.name,
                        orgFullName: rep.data.fullName,
                        email: rep.data.user.email,
                    }));
                });

                getDiscount(extraData?.orgId).then(rep => {
                    if (rep.data.items?.length) {
                        setDiscount(
                            rep.data.items.reduce((total: any, item: any) => {
                                total[item.skuId] = item.discountRate;
                                return total;
                            }, {})
                        );
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.visible]);
    return (
        <>
            <Modal
                width="80%"
                title="代下单"
                {...restProps}
                onOk={() => {
                    onSubmit({
                        ...form.getFieldsValue(),
                        items: detail.items,
                    });
                    form.resetFields();
                }}
                onCancel={e => {
                    form.resetFields();
                    setDetail({ items: [] });
                    onCancel(e);
                }}
            >
                <Form
                    labelCol={{
                        span: 3,
                    }}
                    form={form}
                >
                    <Item label="客户名称">{detail.orgName}</Item>
                    <Item label="客户全称">{detail.orgFullName}</Item>
                    <Item label="客户管理员邮箱">{detail.email}</Item>
                    <Item label="订单产品线" name="productLine">
                        <Select
                            disabled={extraData?.orderId}
                            style={{ width: 150 }}
                            options={[
                                {
                                    label: ProductLine.Bohrium,
                                    value: ProductLine.Bohrium,
                                },
                                {
                                    label: ProductLine.Hermite,
                                    value: ProductLine.Hermite,
                                },
                                {
                                    label: ProductLine.Piloteye,
                                    value: ProductLine.Piloteye,
                                },
                                {
                                    label: ProductLine.Unifinder,
                                    value: ProductLine.Unifinder,
                                },
                            ]}
                        />
                    </Item>
                    <Item>
                        {editable && !detail.orderId ? (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setVisible(true);
                                    }}
                                >
                                    添加资源包商品
                                </Button>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    type="primary"
                                    onClick={() => {
                                        setLicenseVisible(true);
                                    }}
                                >
                                    添加License
                                </Button>
                            </>
                        ) : null}

                        <Table
                            rowKey="skuId"
                            style={{ marginTop: 8 }}
                            dataSource={detail.items}
                            columns={[
                                {
                                    title: '序号',
                                    render: (value, row, index) => index + 1,
                                },
                                // {
                                //     title: '商品名称',
                                //     dataIndex: 'goodsName',
                                // },
                                {
                                    title: extraData?.orderId ? '活动' : '是否赠品',
                                    dataIndex: 'marketingType',
                                    render: (value, row, index) => {
                                        if (index !== skuEditable || !editable || !marketingEditable)
                                            return MarketingTypeText[value as MarketingType];
                                        return (
                                            <Switch
                                                disabled={index !== skuEditable || !editable}
                                                onChange={value =>
                                                    setSkuEditData((old: any) => ({
                                                        ...old,
                                                        marketingType: value
                                                            ? MarketingType.Free
                                                            : MarketingType.Normal,
                                                    }))
                                                }
                                                checked={skuEditData.marketingType === MarketingType.Free}
                                                checkedChildren="赠品"
                                                unCheckedChildren="商品"
                                            />
                                        );
                                    },
                                },
                                {
                                    title: 'SKU名称',
                                    dataIndex: 'skuName',
                                },
                                {
                                    title: 'SKU ID',
                                    dataIndex: 'skuId',
                                },
                                {
                                    title: 'SKU 属性',
                                    dataIndex: 'attributes',
                                    render: value =>
                                        (value || []).map((item: any) => (
                                            <span key={item.attribute}>
                                                {item.attributeName}: {item.attributeValueName}
                                            </span>
                                        )),
                                },
                                {
                                    title: '购买数量',
                                    dataIndex: 'num',
                                    render: (value, row, index) => {
                                        if (row.goodsType === SkuType.License) return '--';
                                        if (index !== skuEditable || !editable) return value;
                                        return (
                                            <Input
                                                type="number"
                                                value={skuEditData.num}
                                                onChange={e =>
                                                    setSkuEditData((value: any) => ({
                                                        ...value,
                                                        num: e.target.value,
                                                    }))
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    title: '购买时长',
                                    dataIndex: 'buyUsedAmount',
                                    render: (value, row, index) => {
                                        if (row.goodsType === SkuType.Sku) return '--';
                                        if (index !== skuEditable || !editable)
                                            return `${value} ${LicensePriceCycleMsg[row.priceCycle] ?? ''}`;
                                        return (
                                            <Input
                                                type="number"
                                                value={skuEditData.buyUsedAmount}
                                                onChange={e =>
                                                    setSkuEditData((value: any) => ({
                                                        ...value,
                                                        buyUsedAmount: e.target.value,
                                                    }))
                                                }
                                            />
                                        );
                                    },
                                },
                                // {
                                //     title: '时长单位',
                                //     dataIndex: 'timeUnit',
                                // },
                                {
                                    title: '原价',
                                    dataIndex: 'price',
                                    render: (value, row) => value ?? row.rawPrice,
                                },
                                {
                                    title: 'SKU 折扣',
                                    dataIndex: 'discountRate',
                                    render: (_, row) => discount[row.skuId] || '',
                                },
                                {
                                    title: '协议单价',
                                    dataIndex: 'agreementPrice',
                                    render: (value, row, index) => {
                                        if (index !== skuEditable || !editable) return value;
                                        return (
                                            <Input
                                                value={skuEditData.agreementPrice}
                                                onChange={e =>
                                                    setSkuEditData((value: any) => {
                                                        return {
                                                            ...value,
                                                            agreementPrice:
                                                                Number.isNaN(parseFloat(e.target.value)) ||
                                                                parseFloat(e.target.value) < 0
                                                                    ? undefined
                                                                    : e.target.value,
                                                        };
                                                    })
                                                }
                                            />
                                        );
                                    },
                                },
                                {
                                    title: '市场价',
                                    render: (_, row) =>
                                        new BigNumber(row.price ?? row.rawPrice)
                                            .multipliedBy(
                                                row.goodsType === SkuType.License ? row.buyUsedAmount : row.num
                                            )
                                            .toFixed(2),
                                },
                                {
                                    title: '价格小计',
                                    render: (_, row) =>
                                        row.marketingType === MarketingType.Free
                                            ? 0
                                            : row.agreementPrice !== undefined && row.agreementPrice !== null
                                            ? new BigNumber(row.agreementPrice)
                                                  .multipliedBy(
                                                      row.goodsType === SkuType.License ? row.buyUsedAmount : row.num
                                                  )
                                                  .toFixed(2)
                                            : new BigNumber(row.price ?? +row.rawPrice)
                                                  .multipliedBy(
                                                      row.goodsType === SkuType.License ? row.buyUsedAmount : row.num
                                                  )
                                                  .multipliedBy(
                                                      discount[row.skuId] ? parseFloat(discount[row.skuId]) : 10
                                                  )
                                                  .dividedBy(10)
                                                  .toFixed(2),
                                },
                                {
                                    title: '操作',
                                    render: (_, row, index) => {
                                        return editable ? (
                                            <>
                                                {index === skuEditable ? (
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setDetail((old: any) => ({
                                                                ...old,
                                                                items: detail.items.map((row: any, index: number) => {
                                                                    if (index === skuEditable) {
                                                                        return {
                                                                            ...row,
                                                                            ...skuEditData,
                                                                            agreementPrice: !Number.isNaN(
                                                                                parseFloat(skuEditData.agreementPrice)
                                                                            )
                                                                                ? parseFloat(
                                                                                      skuEditData.agreementPrice
                                                                                  ).toFixed(2)
                                                                                : undefined,
                                                                        };
                                                                    }
                                                                    return { ...row };
                                                                }),
                                                            }));
                                                            setSkuEditable(-1);
                                                        }}
                                                    >
                                                        保存
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setSkuEditable(index);
                                                            setSkuEditData({ ...row });
                                                        }}
                                                    >
                                                        修改
                                                    </Button>
                                                )}
                                                {!extraData.orderId ? (
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() => {
                                                            const newItems = detail.items;
                                                            newItems.splice(index, 1);
                                                            setDetail((old: any) => ({
                                                                ...old,
                                                                items: [...newItems],
                                                            }));
                                                        }}
                                                    >
                                                        删除
                                                    </Button>
                                                ) : null}
                                            </>
                                        ) : (
                                            '--'
                                        );
                                    },
                                },
                            ]}
                            pagination={false}
                        />
                    </Item>
                    <Item label="原价合计">
                        {detail.items.reduce(
                            (total: number, item: any) =>
                                new BigNumber(item.price ?? +item.rawPrice)
                                    .multipliedBy(item.goodsType === SkuType.License ? item.buyUsedAmount : item.num)
                                    .plus(total)
                                    .toFixed(2),
                            0
                        )}
                    </Item>
                    {/* {extraData?.orderId ? ( */}
                    {/*    <Item label="应付合计">{detail.payableFee}</Item> */}
                    {/* ) : ( */}
                    <Item label="应付合计">
                        {detail.items.reduce((total: number, item: any) => {
                            if (item.marketingType === MarketingType.Free) return total;
                            if (item.agreementPrice !== undefined && item.agreementPrice !== null)
                                return new BigNumber(item.agreementPrice)
                                    .multipliedBy(item.goodsType === SkuType.License ? item.buyUsedAmount : item.num)
                                    .plus(total)
                                    .toFixed(2);
                            return new BigNumber(item.price ?? +item.rawPrice)
                                .multipliedBy(item.goodsType === SkuType.License ? item.buyUsedAmount : item.num)
                                .multipliedBy(discount[item.skuId] ? parseFloat(discount[item.skuId]) : 10)
                                .dividedBy(10)
                                .plus(total)
                                .toFixed(2);
                        }, 0)}
                    </Item>
                    {/* )} */}

                    <Item label="支付方式">余额支付</Item>
                </Form>
            </Modal>
            <SkuModal
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                onSubmit={selection => {
                    setDetail((value: any) => ({
                        ...value,
                        items: detail.items.concat(
                            selection.map((item: any) => ({
                                ...item,
                                num: 1,
                                agreementPrice: undefined,
                                marketingType: MarketingType.Normal,
                                goodsType: SkuType.Sku,
                            }))
                        ),
                    }));
                }}
            />
            <LicenseModal
                visible={licenseVisible}
                onCancel={() => setLicenseVisible(false)}
                onSubmit={data => {
                    setDetail((value: any) => ({
                        ...value,
                        items: detail.items.concat(
                            data.map((item: any) => ({
                                ...item,
                                num: 1,
                                price: item.unitPrice,
                                timeUnit: LicensePriceCycleMsg[item.priceCycle],
                                skuName: item.licenseName,
                                buyUsedAmount: 1,
                                agreementPrice: undefined,
                                marketingType: MarketingType.Normal,
                                goodsType: SkuType.License,
                            }))
                        ),
                    }));
                    setLicenseVisible(false);
                }}
            />
        </>
    );
};
