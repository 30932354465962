import axios from '@/shared/utils/axios';
import { Response } from '@shared/model/type/response.type';

export enum EntityType {
    Individual = 1,
    Corporation,
    University,
    Institute,
    Inner,
    Gov,
    Other,
}

export const ENTITY_TYPE_OPTIONS = [
    { label: '个人', value: EntityType.Individual },
    { label: '企业', value: EntityType.Corporation },
    { label: '高校', value: EntityType.University },
    { label: '科研单位', value: EntityType.Institute },
    { label: '内部', value: EntityType.Inner },
    { label: '政府', value: EntityType.Gov },
    { label: '其他', value: EntityType.Other },
];

export function queryBizEntity(params: {
    endTime?: string;
    startTime?: string;
    orgId?: number;
    id?: number;
    certNo?: string;
    entityType?: number;
    name?: string;
    page: number;
    pageSize: number;
}) {
    return axios.get('/v1/business_entity/list', {
        params,
    }) as Promise<Response>;
}

export function getBizEntityDetail(params: { certNo?: string; id?: number }) {
    return axios.get('/v1/business_entity/detail', {
        params,
    }) as Promise<Response>;
}

export function createBizEntity(data: {
    orgId: number;
    name: string;
    certNo: string;
    contactPhone: string;
    address: string;
    legalRepresentative: string;
    entityType: number;
    remark: string;
    paymentAccount: {
        accountName: string;
        accountNo: string;
        bankName: string;
        openingBank: string;
    }[];
}) {
    return axios.post('/v1/business_entity/create', data) as Promise<Response>;
}

export function hasBizEntity(orgId: number) {
    return axios.post('/v1/business_entity_org_mapping/is_certificate', { orgId }) as Promise<Response>;
}

export function updateBizEntity(id: number, data: {}) {
    return axios.put(`/v1/business_entity/update/${id}`, data) as Promise<Response>;
}

export function deleteBizEntity(entityId: number, orgId: number) {
    return axios.delete(`/v1/business_entity_org_mapping/delete/${entityId}/${orgId}`) as Promise<Response>;
}
