import React from 'react';
import styles from './index.module.css';

const Home: React.FC<{}> = function Home() {
    return (
        <section className={styles.welcome}>
            <h1>欢迎</h1>
        </section>
    );
};

export default Home;
