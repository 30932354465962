export const STATUS_OPTIONS = [
    {
        label: '成功',
        value: '1',
    },
    {
        label: '运行',
        value: '0,3,4',
    },
    {
        label: '失败',
        value: '2',
    },
    {
        label: '取消',
        value: '5',
    },
    {
        label: '等待',
        value: '6',
    },
];
