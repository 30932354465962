import axios from '@/shared/utils/axios';
import { Response } from '@/shared/model/type/response.type';
import { LogListParams } from '@shared/model/type';

export function fetchLogs(params: LogListParams) {
    return axios.get('/v1/logs', {
        toastFail: false,
        params: {
            ...params,
        },
    }) as Promise<Response>;
}
