import { Select, SelectProps } from 'antd';
import { LicenseStatus, LicenseStatusMsg } from '@services/api/license';
import {
    CHARGE_TYPE,
    ChargeTypeMsg,
    GOODS_STATUS,
    GoodsStatusMsg,
    PRICE_TYPE,
    PriceTypeMsg,
    SKUStatus,
    SKUStatusMsg,
} from '@services/api/goods';
import { Business, ResourceStatus, ResourceStatusMsg } from '@services/api/resource';

// eslint-disable-next-line react/require-default-props
export const BusinessSelector = (props: SelectProps<any> & { useId?: boolean }) => {
    const { useId = false, ...restProps } = props;
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={
                !useId
                    ? [
                          { value: Business.Bohrium, label: 'Bohrium' },
                          { value: Business.Hermite, label: 'Hermite' },
                          { value: Business.Unifinder, label: 'Unifinder' },
                      ]
                    : [
                          { value: 2, label: 'Bohrium' },
                          { value: 1, label: 'Hermite' },
                          { value: 4, label: 'Unifinder' },
                      ]
            }
            {...restProps}
        />
    );
};

export const ResourceStatusSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: ResourceStatus.Normal, label: ResourceStatusMsg[ResourceStatus.Normal] },
                { value: ResourceStatus.Paused, label: ResourceStatusMsg[ResourceStatus.Paused] },
            ]}
            {...props}
        />
    );
};

export const GoodsStatusSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: GOODS_STATUS.Normal, label: GoodsStatusMsg[GOODS_STATUS.Normal] },
                { value: GOODS_STATUS.Paused, label: GoodsStatusMsg[GOODS_STATUS.Paused] },
            ]}
            {...props}
        />
    );
};

export const LicenseStatusSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: LicenseStatus.Normal, label: LicenseStatusMsg[LicenseStatus.Normal] },
                { value: LicenseStatus.Offline, label: LicenseStatusMsg[LicenseStatus.Offline] },
                { value: LicenseStatus.Inner, label: LicenseStatusMsg[LicenseStatus.Inner] },
            ]}
            {...props}
        />
    );
};

export const ActionSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: 'GET', label: 'GET' },
                { value: 'POST', label: 'POST' },
            ]}
            {...props}
        />
    );
};

export const ChargeTypeSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: CHARGE_TYPE.POST, label: ChargeTypeMsg[CHARGE_TYPE.POST] },
                { value: CHARGE_TYPE.PRE, label: ChargeTypeMsg[CHARGE_TYPE.PRE] },
            ]}
            {...props}
        />
    );
};

export const SKUStatusSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: SKUStatus.ONLINE, label: SKUStatusMsg[SKUStatus.ONLINE] },
                { value: SKUStatus.OFFLINE, label: SKUStatusMsg[SKUStatus.OFFLINE] },
            ]}
            {...props}
        />
    );
};

export const PriceTypeSelector = (props: SelectProps<any>) => {
    return (
        <Select
            allowClear
            style={{ width: 175 }}
            options={[
                { value: PRICE_TYPE.FIX, label: PriceTypeMsg[PRICE_TYPE.FIX] },
                { value: PRICE_TYPE.STEP, label: PriceTypeMsg[PRICE_TYPE.STEP] },
            ]}
            {...props}
        />
    );
};
