import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

export function setRate(data: { startTime: string; endTime: string; currency: string; rate: number }) {
    return axios.post('/v1/exchange_rate/add', data) as Promise<Response>;
}

export function queryRate(data: { pageSize: number; page: number; date?: string }) {
    return axios.post('/v1/exchange_rate/list', data) as Promise<Response>;
}
