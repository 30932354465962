import { GlobalContext } from '@/App';
import { fetchWalletInfo } from '@services/api/finance';
import { getLicensesByOrderId } from '@services/api/license';
import { getOrder, payOrder } from '@services/api/order';
import { LicenseEnableModal } from '@shared/components/biz/license/enable';
import { Form, message, Modal, ModalProps } from 'antd';
import { useContext, useEffect, useState } from 'react';

const { Item } = Form;
export const PayModal = (props: ModalProps & { orderId: number }) => {
    const globalData = useContext(GlobalContext);
    const isAWS = globalData?.env === 'aws';
    const { visible, orderId, onCancel = () => {}, onOk = () => {}, ...restProps } = props;
    const [balance, setBalance] = useState(0);
    const [order, setOrder] = useState<any>({});
    const [licenseVisible, setLicenseVisible] = useState<boolean>(false);
    useEffect(() => {
        if (visible) {
            getOrder(orderId).then(rep => {
                setOrder(rep.data);
                fetchWalletInfo(rep.data.orgId, '').then(rep => {
                    setBalance(rep.data.balance);
                });
            });
        }
    }, [orderId, visible]);
    return (
        <>
            <Modal
                visible={visible}
                title="确认支付"
                onCancel={onCancel}
                {...restProps}
                onOk={e => {
                    if (parseFloat(order.payableFee) !== 0 && order.payableFee > balance) {
                        message.error('余额不足，支付失败');
                        return;
                    }
                    payOrder(orderId, +order.payableFee).then(rep => {
                        if (!rep.code) {
                            getLicensesByOrderId(orderId).then(rep => {
                                if (rep?.data?.length) {
                                    setLicenseVisible(true);
                                } else {
                                    onOk(e);
                                }
                            });
                        } else {
                            message.error('支付失败');
                        }
                    });
                }}
            >
                <Form>
                    {isAWS ? (
                        <Item label="订单应付金额">$ {order?.payableFee}</Item>
                    ) : (
                        <Item label="订单应付金额">{order?.payableFee}元</Item>
                    )}
                    {isAWS ? (
                        <Item label="当前账户余额">$ {balance}</Item>
                    ) : (
                        <Item label="当前账户余额">{balance}元</Item>
                    )}
                </Form>
            </Modal>
            <LicenseEnableModal
                visible={licenseVisible}
                orderId={orderId}
                onCancel={e => {
                    setLicenseVisible(false);
                    onCancel(e);
                }}
                onSubmit={e => {
                    setLicenseVisible(false);
                    onOk(e);
                }}
            />
        </>
    );
};
