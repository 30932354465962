import { useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type SearchParams = Record<string, string | number | boolean | undefined>;

type LocationSearchParams = {
    [key: string]: string;
};

export function useLocationSearchParams() {
    const initParams = useRef<LocationSearchParams>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const setLocationParams = (params: SearchParams) => {
        const searchParams: LocationSearchParams = {};
        const exclude = ['page', 'perPage'];
        [...Object.entries(params)].forEach(([key, value]) => {
            if (exclude.includes(key)) {
                return;
            }
            if (value === undefined || value === '') {
                return;
            }
            searchParams[key] = String(value);
        });
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const params: LocationSearchParams = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        initParams.current = params;
    }, [searchParams]);

    return {
        initParams,
        setLocationParams,
    };
}
