import { Modal, Form, Select, ModalProps, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { ComboStatus, ComboType, getCombo, queryCombo } from '@services/api/combo';
import { LicensePriceCycleMsg } from '@services/api/license';

const { Item } = Form;
export const ComboSelectorModal = (props: ModalProps & { onSubmit: (data: any) => void }) => {
    const { visible, onSubmit } = props;
    const [comboList, setComboList] = useState([]);
    const [comboId, setComboId] = useState<any>();
    const [combo, setCombo] = useState<any>({});
    const onReset = () => {
        setComboList([]);
        setCombo({});
        setComboId(undefined);
    };
    useEffect(() => {
        if (visible) {
            onReset();
            queryCombo({ page: 1, pageSize: 999, status: ComboStatus.Valid }).then(rep => {
                setComboList(rep.data.items);
            });
        }
    }, [visible]);
    useEffect(() => {
        if (comboId) {
            getCombo(comboId).then(rep => {
                setCombo(rep.data);
            });
        }
    }, [comboId]);
    return (
        <Modal
            title="请选择套餐"
            width={800}
            {...props}
            onOk={e => {
                onSubmit(combo);
                if (props.onCancel) {
                    props.onCancel(e);
                }
            }}
        >
            <Form>
                <Item label="请选择套餐">
                    <Select
                        value={comboId}
                        onChange={value => setComboId(value)}
                        options={comboList.map((combo: any) => ({
                            value: combo.id,
                            label: combo.name,
                        }))}
                    />
                </Item>
                <Item label="优惠余额充值">{combo.couponBalance}</Item>
                <Item label="客户充值金额">{combo.balance}</Item>
                <Row>
                    <span>套餐原价: {combo.originalPrice}</span>
                    <span>套餐购买价: {combo.price}</span>
                </Row>
            </Form>
            <Table
                dataSource={combo.items}
                columns={[
                    {
                        title: '商品名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '类别',
                        dataIndex: 'skuType',
                        render: value => (value === 1 ? '资源包' : 'license'),
                    },
                    {
                        title: '体验周期',
                        dataIndex: 'buyUsedAmount',
                        render: (value, row) =>
                            row.skuType === ComboType.License
                                ? `${value} ${LicensePriceCycleMsg[row.priceCycle]}`
                                : '--',
                    },
                    {
                        title: '数量',
                        dataIndex: 'num',
                        render: (value, row) => (row.skuType === ComboType.Sku ? `${value}` : '--'),
                    },
                    {
                        title: '套餐价',
                        dataIndex: 'price',
                    },
                ]}
            />
        </Modal>
    );
};
