// import { ChargeParams } from '@/shared/model/type/service/finance.type';
import { BalanceTypeEnum, WithdrawTypeEnum } from '@/shared/model/enum/withdraw';
import axios from '@/shared/utils/axios';
import { Response } from '@shared/model/type/response.type';

export function charge(params: FormData) {
    return axios.post('/v1/feishu/approval', params, {
        // toastFail: '充值失败',
        // toastSuccess: '充值成功',
        headers: { 'Content-Type': 'multipart/form-data' },
    }) as Promise<Response>;
}

export function financeAdjust(formData: FormData) {
    return axios.post('/v1/feishu/approval/finance_adjust', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }) as Promise<Response>;
}

export function signOut() {}

export function fetchWalletInfo(id: number, chargeSponsor: string) {
    return axios.get(`/v1/finance/wallet/info/${id}?chargeSponsor=${chargeSponsor}`) as Promise<Response>;
}

export function fetchPairsInfo(id: number, chargeSponsor: string) {
    return axios.get(`/v1/finance/pairs/info/${id}?chargeSponsor=${chargeSponsor}`) as Promise<Response>;
}

export function fetchStock(orgId: number) {
    return axios.get(`/v1/tp/stock/${orgId}`) as Promise<Response>;
}

export function withdraw(data: {
    orgId: number;
    withdrawType: WithdrawTypeEnum;
    balanceType: BalanceTypeEnum;
    amount: number;
    remark: string;
}) {
    return axios.post('/api/wallet/withdraw', data) as Promise<Response>;
}

export function excludeChangeInfo(data: { ids: number[] }) {
    return axios.put('/v1/finance/fifo/exclude/change_info', data) as Promise<Response>;
}

export function fifoOrgRerun(data: { orgId: number; beginDate: string; endDate: string }) {
    return axios.post('/v1/finance/fifo/org/rerun', data) as Promise<Response>;
}
