import axios from '@/shared/utils/axios';
import { Response } from '@/shared/model/type/response.type';

export type QueryParams = {
    page: number;
    pageSize: number;
    applyAccount?: string;
    applyTime?: [string, string];
    issueType?: string;
    invoiceType?: string;
    billingStatus?: string;
    applyTimeStart: any; // 添加一个索引签名
    applyTimeEnd: any;
};

// 发票开具类型
export enum InvoiceIssueType {
    // 0 个人，  1 企业
    Personal = 0,
    Enterprise = 1,
}

export const InvoiceIssueTypeText = {
    [InvoiceIssueType.Personal]: '个人',
    [InvoiceIssueType.Enterprise]: '企业',
};

// InvoiceStatus 0 处理中、1 完成 2 驳回，3 存疑 4 用户取消
export enum InvoiceStatus {
    Invalid = -2,
    Processing = 0,
    Completed = 1,
    Doubtful = 3,
    Canceled,
}

export const InvoiceStatusText = {
    [InvoiceStatus.Processing]: '处理中',
    [InvoiceStatus.Completed]: '完成',
    [InvoiceStatus.Doubtful]: '存疑',
    [InvoiceStatus.Canceled]: '用户取消',
    [InvoiceStatus.Invalid]: '作废',
};

// 0 增值税普通发票(电子) 1 增值税专业发票(纸质)
export enum InvoiceType {
    Normal,
    Professional,
}

export const InvoiceTypeText = {
    [InvoiceType.Normal]: '增值税普通发票',
    [InvoiceType.Professional]: '增值税专用发票',
};

// 发票介质
export const InvoiceMediumText = {
    [InvoiceType.Normal]: '电子',
    [InvoiceType.Professional]: '纸质',
};

export const InvoiceSendTypeText = {
    [InvoiceType.Normal]: '电子邮件',
    [InvoiceType.Professional]: '邮寄',
};

export const InvoiceStatusOptions = [
    {
        label: '已完成',
        value: InvoiceStatus.Completed,
    },
    {
        label: '存疑待确认',
        value: InvoiceStatus.Doubtful,
    },
    {
        label: '处理中',
        value: InvoiceStatus.Processing,
    },
    {
        label: '用户取消',
        value: InvoiceStatus.Canceled,
    },
    {
        label: '作废',
        value: InvoiceStatus.Invalid,
    },
];

export const queryInvoice = (params: QueryParams) => {
    return axios.get('/v1/finance/invoice/list', {
        params,
    }) as Promise<Response>;
};

// param of query invoiceId
export const queryInvoiceRecharge = (params: { invoiceId: number; page: number; pageSize: number }) => {
    return axios.get('/v1/finance/invoice_recharge/list', {
        params,
    }) as Promise<Response>;
};

export const updateInvoice = (
    id: number,
    params: { status: number; sendType: number; invoiceCode: string; remark?: string }
) => {
    return axios.put(`/v1/finance/invoice/update/${id}`, params) as Promise<Response>;
};
