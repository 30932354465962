import React from 'react';
import { Input } from 'antd';
// import { PasswordHideIcon, PasswordShowIcon } from '../../../static/icon/login/index';
import styles from './index.module.css';
import { joinClassName } from '../../../utils/util';

const { Password } = Input;

export const WrapperInput = React.forwardRef<any, any>(function HrmWrapperInput({ style, className, ...props }, ref) {
    React.useImperativeHandle(ref, () => ({}), []);
    return <Input className={joinClassName(className, 'dp-input', styles['dp-input'])} {...props} style={style} />;
});

export const WrapperPassword = React.forwardRef<any, any>(function HrmWrapperPassword(
    { style, className, ...props },
    ref
) {
    React.useImperativeHandle(ref, () => ({}), []);
    return (
        <Password
            className={joinClassName(className, 'dp-input', styles['dp-input'])}
            suffix={'X'}
            {...props}
            style={style}
            // iconRender={visible => (visible ? <PasswordShowIcon /> : <PasswordHideIcon />)}
        />
    );
});
