import { Form, Input, InputNumber, Modal, ModalProps, Select } from 'antd';
import { useState } from 'react';
import { OrgVoucher } from '@shared/model/type/org/org.type';
import { postOrgVoucherDeliver } from '@/services/api/org';
import styles from '@user/index.module.css';

const { Item } = Form;

export const VoucherModal = (props: ModalProps) => {
    const { onCancel = () => {}, visible } = props;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    return (
        <Modal
            title="批量发放体验卡"
            // visible={visible}
            // onCancel={closeNewUserModal}
            maskClosable={false}
            onOk={() => form.submit()}
            confirmLoading={loading}
            destroyOnClose
            centered
            visible={visible}
            onCancel={onCancel}
        >
            <div className={styles['form-container']}>
                <Form
                    form={form}
                    preserve={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={values => {
                        const { phones, ...params } = values;
                        const mobileList = (phones as string).split('\n').filter(s => !!s);
                        setLoading(true);
                        postOrgVoucherDeliver({
                            mobileList,
                            ...params,
                        })
                            .then(({ data }) => {
                                const { successCount, failList, failMsg } = data;
                                if (!failList?.length) {
                                    Modal.success({
                                        title: '体验卡发放成功',
                                        content: `体验卡发放成功，共发送 ${successCount} 张体验卡`,
                                    });
                                    (onCancel as () => void)();
                                    return;
                                }
                                if (successCount) {
                                    Modal.error({
                                        title: '发放失败',
                                        content: (
                                            <div>
                                                <p>成功发送了 {successCount} 张体验卡</p>
                                                <p>失败原因： {failMsg}</p>
                                                <div>失败手机号：</div>
                                                {failList.map(phone => (
                                                    <div key={phone}>{phone}</div>
                                                ))}
                                            </div>
                                        ),
                                    });
                                    return;
                                }
                                Modal.error({
                                    title: '发放失败',
                                    content: (
                                        <div>
                                            <p>失败原因： {failMsg}</p>
                                            <div>失败手机号：</div>
                                            {failList.map(phone => (
                                                <div key={phone}>{phone}</div>
                                            ))}
                                        </div>
                                    ),
                                });
                            })
                            .catch(() => {
                                Modal.error({
                                    title: '发放失败',
                                    content: '体验卡发放失败，请检查网络或联系管理员',
                                });
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    // labelAlign="left"
                >
                    <Item
                        name="phones"
                        label="组织管理员手机号"
                        rules={[
                            { required: true, message: '请填写组织管理员手机号' },
                            {
                                validator(rule, value: string) {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const phones = value.split('\n').filter(s => !!s);
                                    if (phones.length > 100) {
                                        return Promise.reject(new Error('手机号最多填写100行'));
                                    }
                                    if (!phones.length) {
                                        return Promise.reject(new Error('请填写组织管理员手机号'));
                                    }
                                    const reg = /^[0-9]+$/;
                                    const validate = phones.every(p => {
                                        return reg.test(p);
                                    });
                                    return validate
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('格式错误，请检查手机号格式'));
                                },
                            },
                        ]}
                    >
                        <Input.TextArea
                            placeholder="请填写组织管理员手机号，多个账号换行填写"
                            style={{
                                height: 200,
                            }}
                        />
                    </Item>
                    <Item name="sceneType" label="发放场景" rules={[{ required: true, message: '请选择发放场景' }]}>
                        <Select placeholder="请选择发放场景">
                            <Select.Option value={OrgVoucher.Register}>注册发放</Select.Option>
                            <Select.Option value={OrgVoucher.Compensate}>补偿发放</Select.Option>
                            <Select.Option value={OrgVoucher.Activity}>活动发放</Select.Option>
                        </Select>
                    </Item>
                    <Item
                        name="amount"
                        label="体验卡金额"
                        rules={[
                            { required: true, message: '请输入金额' },
                            {
                                validator(rule, value) {
                                    if (value > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('请输入大于0的金额'));
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder="请输入金额"
                            max={1200}
                            min={0}
                            precision={2}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Item>
                    <Item
                        name="expireDayCount"
                        label="体验卡天数"
                        rules={[
                            { required: true, message: '请输入天数' },
                            {
                                validator(rule, value) {
                                    if (value > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('请输入大于0的天数'));
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder="请输入天数"
                            max={365}
                            min={0}
                            precision={0}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Item>
                    <Item name="remark" label="备注" rules={[{ required: true, message: '请输入备注' }]}>
                        <Input placeholder="请输入备注" />
                    </Item>
                </Form>
            </div>
        </Modal>
    );
};
