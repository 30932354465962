import qs from 'qs';
import axios from '@/shared/utils/axios';
import { Response } from '@/shared/model/type/response.type';
import { OrgEditParams, OrgListParams, OrgTag, OrgType } from '@/shared/model/type/org/org.type';
import { SubAccountParams } from '@/shared/model/type/org/sub-account.type';

export function fetchOrgList(params: OrgListParams) {
    return axios.get('/v1/orgs', {
        params: {
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { indices: false });
        },
    }) as Promise<Response>;
}

export function fetchOrgTags() {
    return axios.get('/v1/orgs/tags') as Promise<{ data: OrgTag[] }>;
}

export function fetchOrgTypes() {
    return axios.get('/v1/orgs/types') as Promise<{ data: OrgType[] }>;
}

export function fetchOrgDetail(id: number) {
    return axios.get(`/v1/orgs/${id}`) as Promise<Response>;
}

export function modifyOrgDetail(params: OrgEditParams) {
    const { id, ...info } = params;
    return axios.put(`/v1/orgs/${id}`, info) as Promise<Response>;
}

export function fetchSubAccount(params: SubAccountParams) {
    return axios.get('/v2/users', {
        params,
    }) as Promise<Response>;
}

export function fetchAdminLicense(resource: string) {
    return axios.get(`/v1/admin/get_permission`, {
        params: { resource },
    }) as Promise<{
        data: {
            permission: boolean;
        };
    }>;
}

export function postOrgVoucherDeliver(params: {
    mobileList: string[];
    sceneType: number;
    amount: number;
    expireDayCount: number;
    remark: string;
}) {
    return axios.post(`/v1/finance/org_account/deliver`, params) as Promise<{
        data: {
            successCount: number;
            failList: string[];
            failMsg: string;
        };
    }>;
}
