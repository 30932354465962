import { Modal, Table, Input, Form, Descriptions, Select, Checkbox } from 'antd';
import { useEffect, useState, useCallback, memo } from 'react';
import {
    // queryInvoice,
    InvoiceIssueTypeText,
    InvoiceIssueType,
    queryInvoiceRecharge,
    InvoiceStatus,
    InvoiceStatusText,
    InvoiceTypeText,
    InvoiceType,
    // InvoiceTypeText,
    // InvoiceType,
    // InvoiceMediumText,
    InvoiceSendTypeText,
    InvoiceStatusOptions,
} from '@services/api/invoice';

const { Item } = Form;
const { TextArea } = Input;

interface InvoiceDetailModalProps {
    data: any;
    invoiceBalance: number;
    visible: boolean;
    onSubmit: (data: any) => void;
    onCancel: () => void;
}

const InvoiceDetailModal: React.FC<InvoiceDetailModalProps> = memo(
    ({ data, invoiceBalance, visible, onSubmit, onCancel }) => {
        const [rechargeData, setRechargeData] = useState([]);
        const [page, setPage] = useState(1);
        const [pageSize, setPageSize] = useState(10);
        const [invoiceId, setInvoiceId] = useState(0);
        const [total, setTotal] = useState(0);
        const [form] = Form.useForm();

        function parseLocationToAddrObj(address: string): string {
            const sp = address.split('|');
            const parse = (s: string): string => {
                const parseSp = s.split(':');
                if (parseSp.length === 2) {
                    return parseSp[1];
                }
                return '';
            };
            let addressRet = '';
            // sp[0] 省、sp[1]市、sp[2] 区、 sp[3]详细地址
            if (sp.length >= 4) {
                addressRet = parse(sp[0]) + parse(sp[1]) + parse(sp[2]) + sp[3];
            }

            return addressRet;
        }

        const query = useCallback(
            (newPage: number, newPageSize?: number, newInvoiceId?: number) => {
                setPage(newPage || page);
                setPageSize(newPageSize || pageSize);
                queryInvoiceRecharge({
                    invoiceId: newInvoiceId || invoiceId,
                    page: newPage,
                    pageSize: newPageSize || pageSize,
                }).then(rep => {
                    setRechargeData(rep.data?.recharges);
                    setTotal(rep.data?.total);
                });
            },
            [page, pageSize, invoiceId]
        );

        useEffect(() => {
            const initialValues = {
                status: data?.status as InvoiceStatus,
                amountYuan: data?.amount ? `${(data.amount / 100).toFixed(2)}元` : '',
                invoiceCode: data?.invoiceCode,
                isDelivery: data?.sendType === 2,
                remark: data?.remark,
            };
            form.setFieldsValue(initialValues);
            if (data && typeof data.id !== 'undefined') {
                setInvoiceId(data.id);
                query(page, pageSize, data.id);
            }
        }, [data, form, query, page, pageSize]);

        return (
            <Modal
                title={'开票申请详情'}
                visible={visible}
                onOk={() => {
                    const values = form.getFieldsValue();
                    data = {};
                    data.id = invoiceId;
                    // values parse to params like {sendType, invoiceCode, status, remark}
                    const params = {
                        sendType: values.isDelivery ? 2 : 1,
                        status: values.status,
                        invoiceCode: values.invoiceCode,
                        remark: values.remark,
                    };
                    data.params = params;
                    onSubmit(data);
                }}
                onCancel={onCancel}
                width={800}
            >
                <Descriptions title={`申请开票金额: ${data?.amount / 100}`} />
                <Descriptions title={`待开票金额: ${invoiceBalance ?? 0 / 100}`} />
                <Table
                    dataSource={rechargeData}
                    columns={[
                        {
                            title: '充值ID',
                            dataIndex: 'id',
                        },
                        {
                            title: '充值时间',
                            dataIndex: 'createdTime',
                        },
                        {
                            title: '充值类型',
                            dataIndex: 'rechargeType',
                        },
                        {
                            title: '充值渠道',
                            dataIndex: 'channel',
                        },
                        {
                            title: '充值组织id',
                            dataIndex: 'orgId',
                        },
                        {
                            title: '充值金额',
                            dataIndex: 'rechargeAmountYuan',
                        },
                        {
                            title: '费用类型',
                            dataIndex: 'notes',
                        },
                        {
                            title: '开票状态',
                            dataIndex: 'invoiceStatus',
                            render: value => InvoiceStatusText[value as InvoiceStatus],
                        },
                    ]}
                    pagination={{
                        total,
                        pageSize,
                        onChange: (newPage, newPageSize) => {
                            query(newPage, newPageSize, data.invoiceId);
                        },
                    }}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* 专票 */}
                    {data?.invoiceType === 0 ? (
                        <div style={{ width: '65%' }}>
                            <Descriptions title="开票信息" column={1}>
                                <Descriptions.Item label="开具类型">
                                    {InvoiceIssueTypeText[data?.invoiceToType as InvoiceIssueType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="发票抬头">{data?.company}</Descriptions.Item>
                                <Descriptions.Item label="发票类型">
                                    {InvoiceTypeText[data?.invoiceType as InvoiceType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="税务登记号">{data?.taxRegistrationNumber}</Descriptions.Item>
                                <Descriptions.Item label="获取方式">
                                    {InvoiceSendTypeText[data?.invoiceType as InvoiceType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="邮箱地址">{data?.emailOrAddr}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    ) : null}
                    {/* 普票 */}
                    {data?.invoiceType === 1 ? (
                        <div style={{ width: '65%' }}>
                            <Descriptions title="开票信息" column={1}>
                                <Descriptions.Item label="开具类型">
                                    {InvoiceIssueTypeText[data?.invoiceToType as InvoiceIssueType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="发票类型">
                                    {InvoiceTypeText[data?.invoiceType as InvoiceType]}
                                </Descriptions.Item>
                                <Descriptions.Item label="企业名称">{data?.company}</Descriptions.Item>
                                <Descriptions.Item label="税务登记号">{data?.taxRegistrationNumber}</Descriptions.Item>
                                <Descriptions.Item label="开户银行名称">{data?.bankName}</Descriptions.Item>
                                {/* 开户账号 */}
                                <Descriptions.Item label="开户账号">{data?.bankAccount}</Descriptions.Item>
                                {/* 注册场所地址 */}
                                <Descriptions.Item label="注册场所地址">{data?.registerAddress}</Descriptions.Item>
                                {/* 注册固定电话 */}
                                <Descriptions.Item label="注册固定电话">{data?.registerPhone}</Descriptions.Item>
                                {/* 获取方式 */}
                                <Descriptions.Item label="获取方式">
                                    {InvoiceSendTypeText[data?.invoiceType as InvoiceType]}
                                </Descriptions.Item>
                                {/* 收件地址 */}
                                <Descriptions.Item label="收件地址">
                                    {parseLocationToAddrObj(data?.emailOrAddr)}
                                </Descriptions.Item>
                                {/* 联系人 */}
                                <Descriptions.Item label="联系人">{data?.contactPerson}</Descriptions.Item>
                                {/* 联系电话 */}
                                <Descriptions.Item label="联系电话">{data?.contactPhone}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    ) : null}
                    <div style={{ width: '30%' }}>
                        <Form form={form}>
                            {/* 开票状态的下拉菜单 */}
                            <Item name="status" label="开票状态">
                                <Select options={InvoiceStatusOptions} placeholder="请选择" />
                            </Item>
                            <Item name="amountYuan" label="开票金额">
                                <Input style={{ width: '100%' }} disabled />
                            </Item>
                            <Item name="invoiceCode" label="发票号码" rules={[{ required: true }]}>
                                <Input placeholder="请输入" />
                            </Item>
                            <Item name="isDelivery" label="是否邮寄" valuePropName="checked">
                                <Checkbox>是</Checkbox>
                            </Item>
                            {/* 快递单号 */}
                            <Item name="trackingNumber" label="快递单号">
                                <Input placeholder="请输入" />
                            </Item>
                            <Item name="remark" label="备注">
                                <TextArea maxLength={200} />
                            </Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }
);

export default InvoiceDetailModal;
