import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';
import { ProductLine } from '@shared/model/type/order/order.type';
import { MarketingType } from '@shared/model/type/goods/goods.type';

export function createOrder(data: {
    productLine: ProductLine;
    orgId: number;
    items: {
        skuId: number;
        marketingType: MarketingType;
        agreementPrice: number;
        skuNum: number;
    };
}) {
    return axios.post(`/v1/tp/order/create`, data) as Promise<Response>;
}

export function putOrder(
    orderId: number,
    items: {
        itemId: number;
        num: number;
        agreementPrice: number;
    }[]
) {
    return axios.put(`/v1/tp/order/modify`, {
        orderId,
        items,
    }) as Promise<Response>;
}

export function getOrder(orderId: number) {
    return axios.get(`/v1/tp/order/detail`, {
        params: {
            orderId,
        },
    }) as Promise<Response>;
}

export function payOrder(orderId: number, payAmount: number) {
    return axios.post(`/v1/finance/pay`, {
        orderId,
        payAmount,
    }) as Promise<Response>;
}

export function queryOrder(data: {
    skuId?: number;
    skuName?: string;
    page: number;
    pageSize: number;
    orgId?: number;
    orgName?: string;
    createStartTime?: string;
    createEndTime?: string;
    orderId?: string;
}) {
    return axios.post(`/v1/tp/order/page`, {
        ...data,
        skuId: data?.skuId ? +data.skuId : undefined,
        orgId: data?.orgId ? +data.orgId : undefined,
        orderId: data?.orderId ? +data.orderId : undefined,
    }) as Promise<Response>;
}
