import { Button, Form, Input, message, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    createAttributeValue,
    fetchAttributeValueList,
    GOODS_STATUS,
    GoodsStatusMsg,
    updateAttributeValue,
} from '@services/api/goods';
import { GoodsStatusSelector } from '@shared/components/biz/selector';
import { AttributeSelector } from '@shared/components/biz/sku/attribute-selector';

const { Item } = Form;
export const TechGoodsValue = () => {
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>();
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        return fetchAttributeValueList({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Form layout="inline" form={form}>
                <Item label="属性ID" name="attributeId">
                    <Input type="number" placeholder="请输入" />
                </Item>
                <Item label="属性名称" name="attributeName">
                    <Input placeholder="请输入" />
                </Item>
                <Item label="属性值ID" name="attributeValId">
                    <Input type="number" placeholder="请输入" />
                </Item>
                <Item label="名称" name="attributeValueName">
                    <Input placeholder="请输入" />
                </Item>
                <Button
                    type="primary"
                    onClick={() => {
                        refresh(1, pageSize);
                    }}
                >
                    搜索
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        refresh(1, pageSize);
                    }}
                    type="default"
                    style={{ marginLeft: 10 }}
                >
                    重置
                </Button>
            </Form>
            <Row style={{ marginBottom: 10, marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        newForm.resetFields();
                        newForm.setFieldsValue({
                            status: GOODS_STATUS.Normal,
                        });
                        setDetail({});
                        setVisible(true);
                    }}
                >
                    添加
                </Button>
            </Row>
            <Table
                scroll={{ x: true }}
                dataSource={data}
                columns={[
                    {
                        title: '属性值ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '属性ID',
                        dataIndex: 'attributeId',
                    },
                    {
                        title: '属性名称',
                        dataIndex: 'attributeName',
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        render: value => GoodsStatusMsg[value],
                    },
                    {
                        width: 180,
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        width: 180,
                        title: '添加时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        fixed: 'right',
                        title: '操作',
                        render: (value, row) => (
                            <Button
                                type="primary"
                                onClick={() => {
                                    newForm.setFieldsValue({
                                        value: row.name,
                                        status: row.status,
                                    });
                                    setDetail(row);
                                    setVisible(true);
                                }}
                            >
                                编辑
                            </Button>
                        ),
                    },
                ]}
                pagination={{
                    current: page,
                    pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        refresh(page, pageSize);
                    },
                    total,
                }}
            />
            <Modal
                title={detail?.id ? '修改属性值' : '添加属性值'}
                visible={visible}
                onCancel={() => {
                    setDetail(undefined);
                    setVisible(false);
                }}
                onOk={() => {
                    const data = newForm.getFieldsValue();
                    if (Object.keys(data).some(key => !data[key] && data[key] !== 0)) {
                        return message.error('请填写完整表单');
                    }
                    const action = detail?.id
                        ? updateAttributeValue(detail.id, {
                              ...data,
                          })
                        : createAttributeValue({
                              ...detail,
                              ...data,
                          });
                    action.then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setVisible(false);
                            setDetail(undefined);
                            refresh(1, pageSize);
                        } else {
                            message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                        }
                    });
                }}
            >
                <Form form={newForm} labelCol={{ span: 4 }}>
                    {detail?.id ? (
                        <>
                            <Item label="id">{detail.id}</Item>
                            <Item label="属性">
                                {detail.attributeName}({detail.attributeId})
                            </Item>
                        </>
                    ) : (
                        <Item name="attrId" label="属性" required>
                            <AttributeSelector />
                        </Item>
                    )}
                    <Item name="value" label="名称" required>
                        <Input />
                    </Item>
                    <Item name="status" label="状态" required>
                        <GoodsStatusSelector />
                    </Item>
                </Form>
            </Modal>
        </>
    );
};
