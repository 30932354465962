import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { ACCOUNT_FILLING_OPTIONS, PAYMENT_OPTIONS } from '@/shared/model/constant/finance/recharge';
import { BALANCE_TYPE_MSG } from '@shared/model/enum/finance/recharge.enum';
import { BALANCE_TYPE } from '@user/model';
import { RechargeListParams } from '@/shared/model/type/finance/recharge-list.type';

const { RangePicker } = DatePicker;
interface RechargeListFormProps {
    onSubmit: (values: Partial<RechargeListParams>) => void;
}

const RechargeListForm: React.FC<RechargeListFormProps> = React.memo(function RechargeListForm({ onSubmit }) {
    const [form] = Form.useForm();
    const colProps = useMemo(
        () => ({
            span: 6,
        }),
        []
    );
    return (
        <Form
            form={form}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{ span: 17 }}
            onFinish={values => {
                const { time, ...params } = values;
                const [startTime, endTime] = (time || []).map((m: Moment) => m.format('YYYY-MM-DD'));
                onSubmit({
                    ...params,
                    balanceType: +params?.balanceType || undefined,
                    orgManage: params?.orgManage || undefined,
                    operatorEmail: params?.operatorEmail || undefined,
                    id: +values?.id || undefined,
                    orgId: +values?.orgId || undefined,
                    startTime: startTime ? `${startTime} 00:00:00` : undefined,
                    endTime: endTime ? `${endTime} 23:59:59` : undefined,
                });
            }}
        >
            <Row>
                <Col {...colProps}>
                    <Form.Item label="充值ID" name={'id'}>
                        <Input
                            type={'number'}
                            allowClear
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="请输入记录id"
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="充值操作人" name={'operatorEmail'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入操作人账号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="充值时间" name={'time'}>
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="结算状态" name={'settleStatusList'}>
                        <Select
                            mode="multiple"
                            options={ACCOUNT_FILLING_OPTIONS}
                            allowClear
                            placeholder="已结算/未结算/部分结算/不可结算"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="组织ID" name={'orgId'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入组织id" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="管理员邮箱" name={'orgManage'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入组织管理员邮箱" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="组织全称" name={'orgFullname'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入组织账号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="支付方式" name={'channel'}>
                        <Select options={PAYMENT_OPTIONS} allowClear placeholder="线下/微信/支付宝/PayPal/微信小程序" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="手机号" name={'phone'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入手机号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="余额类型" name={'balanceType'}>
                        <Select
                            allowClear
                            options={Object.keys(BALANCE_TYPE_MSG).map(key => ({
                                label: BALANCE_TYPE_MSG[(key as unknown) as BALANCE_TYPE],
                                value: key,
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="排除全称" name={'excluded'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入" />
                    </Form.Item>
                </Col>
                <Button style={{ marginRight: 10, marginLeft: 10 }} type="primary" htmlType="submit">
                    搜索
                </Button>{' '}
                <Button
                    onClick={() => {
                        form.resetFields();
                        form.submit();
                    }}
                >
                    重置
                </Button>
            </Row>
        </Form>
    );
});

export default RechargeListForm;
