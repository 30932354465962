import { Button, Form, Input, Modal, ModalProps, Select, Radio, InputNumber, Upload, message } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { fetchWalletInfo } from '@/services/api/finance';

// import BigNumber from 'bignumber.js';
// import { getOrder } from '@services/api/order';
// import { MarketingType, MarketingTypeText } from '@shared/model/type/goods/goods.type';
// import { ProductLine } from '@shared/model/type/order/order.type';
// import { getDiscount } from '@services/api/goods';
// import { fetchOrgDetail } from '@services/api/org';
// import { SkuModal } from '@shared/components/biz/sku/modal';
// import { LicenseModal } from '@shared/components/biz/license/modal';
// import { LicensePriceCycleMsg } from '@services/api/license';
// import { SkuType } from '@services/api/combo';

import {
    BALANCE_TYPE,
    // CHARGE_TYPE_OPTIONS,
    // MONEY_ARRIVAL_STATUS,
    // VIP_OPTIONS,
    BIZLINE_OPTIONS,
    // WalletInfo,
    // PairsInfo,
} from '@/biz/user/model';

import { FINANCE_ADJUST_TYPE_OPTIONS, USER_SEE_OR_NOT, FINANCE_ADJUST_TYPE } from '@/biz/finance/finance-adjust/model';

const { Item } = Form;
const { TextArea } = Input;

export const FinanceAdjustModal = (props: ModalProps & { extraData: any; onSubmit: (data: any) => void }) => {
    const [form] = Form.useForm();
    const { onSubmit = () => {}, onCancel = () => {}, extraData, ...restProps } = props;
    const attachmentRef = useRef<any>([]);
    const [orgOutDetail, setOrgOutDetail] = useState('');
    const [orgInDetail, setOrgInDetail] = useState<any>('');

    const generateTransferDetail = (transferType: string, rep: any) => {
        return `${transferType}账号组织全称: ${rep.data?.fullName}，管理员邮箱: ${rep.data?.email}，当前付费余额：${rep.data?.balance}美元，优惠余额：${rep.data?.couponBalance}美元，
                    锁定余额：${rep.data?.lockBalance}美元，锁定优惠余额：${rep.data?.lockCouponBalance}美元`;
    };

    const handleOrgIdBlur = (transferType: string, setDetail: Function) => (e: any) => {
        const orgId = e.target.value;
        fetchWalletInfo(orgId, 'admin').then(rep => {
            setDetail(generateTransferDetail(transferType, rep));
        });
    };

    useEffect(() => {
        if (extraData?.orgId) {
            fetchWalletInfo(extraData?.orgId, 'admin').then(rep => {
                setOrgOutDetail(generateTransferDetail('转出', rep));
            });
        }
    }, [restProps?.visible, extraData?.orgId]);

    return (
        // a div with a p of some text
        <div>
            <Modal
                title="调账"
                {...restProps}
                onCancel={e => {
                    form.resetFields();
                    onCancel(e);
                }}
                onOk={async () => {
                    form.validateFields().then(() => {
                        try {
                            const formValues = form.getFieldsValue();
                            if (formValues.amount <= 0) {
                                message.error('调账金额必须大于0');
                                return;
                            }
                            // 上传附件场景验证
                            if (attachmentRef.current.length === 0) {
                                if (
                                    formValues.adjustType === FINANCE_ADJUST_TYPE.BUSINESS_CHANGE ||
                                    formValues.adjustType === FINANCE_ADJUST_TYPE.ACCOUNT_TRANSFER
                                ) {
                                    message.error('账户迁移或者业务调整必须上传附件');
                                    return;
                                }
                                if (
                                    formValues.adjustType === FINANCE_ADJUST_TYPE.WRONG_RECHARGE &&
                                    formValues.amount > 5000
                                ) {
                                    message.error('误充值操作调整超过5000元，必须上传附件');
                                    return;
                                }
                            }

                            if (
                                formValues.orgOutId === formValues.orgInId &&
                                formValues.balanceTypeOut === formValues.balanceTypeIn
                            ) {
                                message.error('转出组织id=转入组织ID时，余额类型必不相同');
                                return;
                            }

                            const formData = new FormData();

                            Object.keys(formValues).forEach(key => {
                                if (formValues[key] !== null && formValues[key] !== undefined) {
                                    formData.append(key, formValues[key]);
                                }
                            });
                            formData.append('orgOutInfo', orgOutDetail);
                            formData.append('orgInInfo', orgInDetail);
                            if (attachmentRef.current.length > 0) {
                                formData.append('attachment', attachmentRef.current[0], attachmentRef.current[0].name);
                            }

                            onSubmit(formData);
                        } catch (error) {
                            console.log('表单验证失败:', error);
                        }
                    });
                }}
            >
                <Form
                    initialValues={{
                        orgOutId: Number(extraData?.orgId),
                        remark: '',
                        amount: 0,
                        payRecved: -1,
                    }}
                    form={form}
                    preserve={false}
                    labelCol={{ span: 5 }}
                >
                    <Item name="businessLine" label="产品线" rules={[{ required: true, message: '请输入产品线' }]}>
                        <Select options={BIZLINE_OPTIONS} placeholder="请选择: Bohrium/Hermite" />
                    </Item>

                    <Item name="adjustType" label="类型" rules={[{ required: true }]}>
                        <Select
                            options={FINANCE_ADJUST_TYPE_OPTIONS}
                            placeholder="请选择: 误充值调整/业务变动/账号转移"
                        />
                    </Item>

                    <Item name="amount" label="金额" rules={[{ required: true, message: '请输入金额' }]}>
                        <InputNumber min={0} precision={2} style={{ width: '200px' }} />
                    </Item>

                    <Item
                        name="remark"
                        label="备注(内部可见)"
                        rules={[{ required: true }]}
                        labelCol={{ style: { textAlign: 'left' } }}
                    >
                        <TextArea maxLength={200} />
                    </Item>

                    <Item name="canUserSee" label="是否用户可见" rules={[{ required: true }]}>
                        <Select options={USER_SEE_OR_NOT} placeholder="请选择: 是/否" />
                    </Item>

                    <Item
                        label="附件（账户迁移必填）"
                        labelCol={{ style: { textAlign: 'left' } }}
                        style={{ height: '40px' }}
                    >
                        <Upload
                            beforeUpload={file => {
                                attachmentRef.current.push(file);
                                console.log('attachmentRef.current', attachmentRef.current);
                                return false;
                            }}
                            onRemove={file => {
                                attachmentRef.current = attachmentRef.current.filter((f: any) => f.uid !== file.uid);
                            }}
                            multiple
                            maxCount={3}
                        >
                            <Button icon={<UploadOutlined />}>上传附件</Button>
                        </Upload>
                    </Item>

                    <Item name="orgOutId" label="转出账户组织id" labelCol={{ style: { textAlign: 'left' } }}>
                        <InputNumber
                            min={0}
                            style={{ width: '200px' }}
                            onBlur={handleOrgIdBlur('转出', setOrgOutDetail)}
                        />
                    </Item>

                    <Item name="orgOutInfo" label="转出组织信息" labelCol={{ style: { textAlign: 'left' } }}>
                        {orgOutDetail}
                    </Item>

                    <Item name="balanceTypeOut" label="转出余额类型" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={BALANCE_TYPE.DISCOUNT_BALANCE}>优惠充值余额</Radio>
                            <Radio value={BALANCE_TYPE.NORMAL_BALANCE}>充值余额</Radio>
                        </Radio.Group>
                    </Item>

                    <Item
                        name="reasonOut"
                        label="转出原因(用户可见)"
                        rules={[{ required: true }]}
                        labelCol={{ style: { textAlign: 'left' } }}
                    >
                        <TextArea maxLength={200} />
                    </Item>

                    <Item name="orgInId" label="转入账户组织id" labelCol={{ style: { textAlign: 'left' } }}>
                        <InputNumber
                            min={0}
                            style={{ width: '200px' }}
                            onBlur={handleOrgIdBlur('转入', setOrgInDetail)}
                        />
                    </Item>

                    <Item name="orgInInfo" label="转入组织信息" labelCol={{ style: { textAlign: 'left' } }}>
                        {orgInDetail}
                    </Item>

                    <Item name="balanceTypeIn" label="转入余额类型" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={BALANCE_TYPE.DISCOUNT_BALANCE}>优惠充值余额</Radio>
                            <Radio value={BALANCE_TYPE.NORMAL_BALANCE}>充值余额</Radio>
                        </Radio.Group>
                    </Item>

                    <Item
                        name="reasonIn"
                        label="转入原因(用户可见)"
                        rules={[{ required: true }]}
                        labelCol={{ style: { textAlign: 'left' } }}
                    >
                        <TextArea maxLength={200} />
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};

export {};
