import { Button, Form, Input, Table, DatePicker, Select, Row, Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ENTITY_TYPE_OPTIONS, queryBizEntity, updateBizEntity } from '@services/api/biz-entity';
import { BizEntityModal } from '@shared/components/biz/biz-entity/modal';

const { Item } = Form;
const { RangePicker } = DatePicker;
export const BizEntity = () => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [row, setRow] = useState<any>({});
    const [visible, setVisible] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);
    const query = (newPage: number, newPageSize?: number) => {
        const params = form.getFieldsValue();
        setPage(newPage);
        setPageSize(newPageSize || pageSize);
        if (params.time?.length) {
            params.startTime = params[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
            params.endTime = params[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
            delete params.time;
        }
        queryBizEntity({
            ...params,
            page: newPage,
            pageSize: newPageSize || pageSize,
        }).then(rep => {
            setData(rep.data.items);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        query(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Form form={form} layout="horizontal">
                <Row gutter={24}>
                    <Col span={8}>
                        <Item label="id" name="id">
                            <Input placeholder="请输入用户id" />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="信用代码/身份证" name="certNo">
                            <Input placeholder="请输入" />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="新增时间" name="time">
                            <RangePicker />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Item label="单位类型" name="entityType">
                            <Select options={ENTITY_TYPE_OPTIONS} />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="单位名称" name="name">
                            <Input placeholder="请输入" />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item>
                            <Button type="primary" onClick={() => query(1, pageSize)}>
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 12 }}
                                onClick={() => {
                                    form.resetFields();
                                    query(1, pageSize);
                                }}
                            >
                                重置
                            </Button>
                        </Item>
                    </Col>
                </Row>
            </Form>
            <Table
                style={{ marginTop: 12 }}
                dataSource={data}
                columns={[
                    {
                        title: '序号',
                        render: (value, row, index) => index + 1,
                    },
                    {
                        title: '单位ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '信用代码/身份证',
                        dataIndex: 'certNo',
                    },
                    {
                        title: '单位名称/姓名',
                        dataIndex: 'name',
                    },
                    {
                        title: '单位类型',
                        dataIndex: 'entityType',
                        render: value => ENTITY_TYPE_OPTIONS.find(item => item.value === value)?.label ?? '--',
                    },
                    {
                        title: '银行开户名',
                        dataIndex: 'paymentAccount',
                    },
                    {
                        title: '新增时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作',
                        dataIndex: 'id',
                        render: (value, row) => (
                            <>
                                <Button
                                    style={{ marginRight: 4 }}
                                    type="default"
                                    onClick={() => {
                                        setVisible(true);
                                        setRow(row);
                                        setEditable(false);
                                    }}
                                >
                                    详情
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setVisible(true);
                                        setRow(row);
                                        setEditable(true);
                                    }}
                                >
                                    编辑
                                </Button>
                            </>
                        ),
                    },
                ]}
                pagination={{
                    total,
                    pageSize,
                    onChange: (newPage, newPageSize) => {
                        query(newPage, newPageSize);
                    },
                }}
            />
            <BizEntityModal
                visible={visible}
                onSubmit={data => {
                    updateBizEntity(row.id, {
                        ...data,
                        paymentAccount: data.paymentAccount.map((item: any) => {
                            if (item.id === +item.id) return item;
                            delete item.id;
                            return item;
                        }),
                    }).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setRow({});
                            setVisible(false);
                        }
                    });
                }}
                entityId={row.id}
                editable={editable}
                onCancel={() => setVisible(false)}
            />
        </>
    );
};
