import { Button, DatePicker, Form, Input, Modal, Row, Select, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    createBohriumUpdate,
    getBohriumDetail,
    getBohriumUpdatesList,
    updateBohriumDetail,
} from '@/services/api/bohrium';

const { Item } = Form;

enum LEVEL_ENUM {
    Top = 0,
    Normal = 255,
}

enum STATE_ENUM {
    Show = 1,
    Hide = 2,
}

export const ProductUpdate = () => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    const [curId, setCurId] = useState(0);

    const refresh = (newPage: number = page, newPageSize: number = pageSize) => {
        const params = { ...form.getFieldsValue() };
        getBohriumUpdatesList({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data?.items || []);
            setTotal(rep.data?.total || 0);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 12,
                }}
                layout="inline"
            >
                <Row style={{ marginBottom: 8 }}>
                    <Item label="状态" name="state">
                        <Select placeholder="请选择" style={{ width: '176px' }} allowClear>
                            <Select.Option value={STATE_ENUM.Show}>显示</Select.Option>
                            <Select.Option value={STATE_ENUM.Hide}>隐藏</Select.Option>
                        </Select>
                    </Item>
                    <Item label="标题" name="title">
                        <Input placeholder="请输入" allowClear />
                    </Item>

                    <Button
                        style={{ marginLeft: 12 }}
                        type="primary"
                        onClick={() => {
                            refresh(1, pageSize);
                        }}
                    >
                        搜索
                    </Button>
                    <Button style={{ marginLeft: 12 }} onClick={() => form.resetFields()}>
                        重置
                    </Button>
                    <Button
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            addForm.setFieldsValue({ state: STATE_ENUM.Show, level: LEVEL_ENUM.Normal });
                            setCurId(0);
                            setVisible(true);
                        }}
                    >
                        添加
                    </Button>
                </Row>
            </Form>
            <Table
                pagination={{
                    defaultPageSize: pageSize,
                    current: page,
                    showSizeChanger: true,
                    onChange: (newPage: number, newPageSize?: number) => {
                        refresh(newPage, newPageSize);
                    },
                    total,
                }}
                dataSource={data}
                columns={[
                    {
                        title: 'ID',
                        width: 60,
                        dataIndex: 'id',
                    },
                    {
                        title: '标题',
                        width: 200,
                        dataIndex: 'title',
                    },
                    {
                        title: '英文标题',
                        width: 200,
                        dataIndex: 'titleEN',
                    },
                    {
                        title: '置顶',
                        dataIndex: 'level',
                        width: 60,
                        render: level => (level === LEVEL_ENUM.Top ? '是' : '否'),
                    },
                    {
                        title: '状态',
                        dataIndex: 'state',
                        width: 90,
                        render: (value: number) => (value === STATE_ENUM.Show ? '显示' : '隐藏'),
                    },
                    {
                        title: '跳转链接',
                        width: 180,
                        dataIndex: 'url',
                        render: url => <span style={{ wordBreak: 'break-word' }}>{url}</span>,
                    },
                    {
                        title: '发布时间',
                        dataIndex: 'showTime',
                        width: 180,
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '添加时间',
                        dataIndex: 'createTime',
                        width: 180,
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        width: 180,
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作',
                        width: 100,
                        fixed: 'right',
                        render: (_, row) => (
                            <Button
                                style={{ marginRight: 4 }}
                                type="primary"
                                onClick={() => {
                                    if (row.id) {
                                        setCurId(row.id);
                                        getBohriumDetail(row.id).then(res => {
                                            addForm.setFieldsValue({
                                                ...res.data,
                                                showTime: moment(res.data.showTime),
                                            });
                                            setVisible(true);
                                        });
                                    }
                                }}
                            >
                                编辑
                            </Button>
                        ),
                    },
                ]}
                scroll={{ x: true, y: 'calc(100vh - 230px)' }}
            />
            <Modal
                visible={visible}
                title={curId ? '编辑产品动态' : '创建产品动态'}
                onOk={async () => {
                    await addForm.validateFields();
                    const addFormValues = addForm.getFieldsValue();
                    const payload = {
                        ...addFormValues,
                        showTime: moment(addFormValues.showTime).zone('+08:00').format(),
                    };

                    if (curId) {
                        updateBohriumDetail({ ...payload, docId: curId }).then(res => {
                            if (res.code === 0) {
                                message.success('更新成功');
                                addForm.resetFields();
                                setVisible(false);
                                refresh();
                            } else {
                                message.error(res.error?.msg || '服务器内部错误');
                            }
                        });
                    } else {
                        createBohriumUpdate(payload).then(res => {
                            if (res.code === 0) {
                                message.success('添加成功');
                                addForm.resetFields();
                                setVisible(false);
                                refresh();
                            } else {
                                message.error(res.error?.msg || '服务器内部错误');
                            }
                        });
                    }
                }}
                onCancel={() => {
                    addForm.resetFields();
                    setVisible(false);
                }}
            >
                <Form
                    form={addForm}
                    labelCol={{
                        span: 5,
                    }}
                >
                    <Item label="标题" rules={[{ required: true, message: '请输入' }]} name="title">
                        <Input placeholder="请填写，限128字" maxLength={128} />
                    </Item>
                    <Item label="英文标题" rules={[{ required: true, message: '请输入' }]} name="titleEN">
                        <Input placeholder="请填写，限300字符" maxLength={300} />
                    </Item>
                    <Item label="发布时间" rules={[{ required: true, message: '请选择' }]} name="showTime">
                        <DatePicker showTime style={{ width: '100%' }} />
                    </Item>
                    <Item label="是否置顶" name="level">
                        <Select placeholder="请选择，是/否">
                            <Select.Option value={LEVEL_ENUM.Top}>是</Select.Option>
                            <Select.Option value={LEVEL_ENUM.Normal}>否</Select.Option>
                        </Select>
                    </Item>

                    <Item label="状态" name="state" rules={curId ? [{ required: true, message: '请选择' }] : []}>
                        {curId ? (
                            <Select placeholder="请选择显示/隐藏">
                                <Select.Option value={STATE_ENUM.Show}>显示</Select.Option>
                                <Select.Option value={STATE_ENUM.Hide}>隐藏</Select.Option>
                            </Select>
                        ) : (
                            '显示'
                        )}
                    </Item>

                    <Item label="链接" name="url">
                        <Input placeholder="请填写链接" />
                    </Item>

                    <Item label="是否发消息" name="isSend" rules={curId ? [] : [{ required: true, message: '请选择' }]}>
                        {!curId ? (
                            <Select placeholder="请选择 是/否">
                                <Select.Option value={1}>是</Select.Option>
                                <Select.Option value={0}>否</Select.Option>
                            </Select>
                        ) : addForm.getFieldValue('isSend') === 1 ? (
                            '是'
                        ) : (
                            '否'
                        )}
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};
