import React, { useCallback, useState, useEffect } from 'react';
import { Form, message, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import Cookies from 'js-cookie';
import { getDomain, joinClassName } from '@/shared/utils/util';
import { signIn } from '@/services/api/auth';
import { getToken, setToken } from '@/shared/utils/token';
import hrmAxios from '@/shared/utils/axios';
import { WrapperInput, WrapperPassword } from '../wrapper/input';
import AdminLoginErrorInfo from './login-error/index';
import styles from './index.module.css';

const { Item } = Form;

export interface LoginProps {
    layout?: FormProps['layout'];
    onClose?: () => void;
    initialValues?: object;
}

const Login: React.FC<LoginProps> = React.memo(({ layout = 'vertical', initialValues, onClose = () => {} }) => {
    const [form] = Form.useForm();
    const [signInErrorState, setSignInErrorState] = useState<boolean>(false);

    const onFormFinish = useCallback(() => {
        const formValues = form.getFieldsValue();

        signIn(formValues)
            .then((res: any) => {
                message.success({
                    className: 'hrm-message-success',
                    content: 'Login successful!',
                    duration: 3,
                });

                hrmAxios.defaults.headers.common.Authorization = `jwt ${res.token}`;

                setToken(res.token || '');
                Cookies.set('adminToken', res.token, {
                    domain: getDomain(),
                    expires: 30,
                });
                onClose();

                // eslint-disable-next-line no-restricted-globals
                location.href = '/home';
            })
            .catch((err: any) => {
                if (err) {
                    setSignInErrorState(true);
                    if (err.code === 600060) {
                        message.error(err.msg);
                    }
                } else {
                    message.error({
                        className: 'hrm-message-error',
                        content: 'Network exception,Log in failed',
                        duration: 3,
                    });
                }
            });
    }, [form, onClose]);

    const onLogin = useCallback(
        (evt: KeyboardEvent) => {
            if (evt.key === 'Enter') {
                form.validateFields();
            }
        },
        [form]
    );

    useEffect(() => {
        if (getToken()) {
            // eslint-disable-next-line no-restricted-globals
            location.href = '/home';
        }
    });

    useEffect(() => {
        window.addEventListener('keyup', onLogin);
        return () => {
            window.removeEventListener('keyup', onLogin);
        };
    }, [onLogin]);

    return (
        <div className={joinClassName('login-wrapper', styles['login-form-container'])}>
            {/* <HrmLoginHeaderTitle
                title={'Login'}
                tipTitle={'Don’t have an account?'}
                interactiveTitle={' Create New Account'}
            /> */}
            <div className={styles['form-content']}>
                <Form
                    layout={layout}
                    initialValues={initialValues}
                    form={form}
                    onFinish={onFormFinish}
                    requiredMark={false}
                >
                    <Item
                        name="email"
                        label="Email"
                        className={styles['form-item']}
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email',
                            },
                        ]}
                    >
                        <WrapperInput allowClear placeholder="example@mail.com" />
                    </Item>
                    <Item>
                        <Item
                            name="password"
                            label="Password"
                            className={styles['form-item']}
                            validateFirst
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (value.length > 5 && value.length < 19) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Password must be 6-18 characters long'));
                                    },
                                }),
                            ]}
                        >
                            <WrapperPassword allowClear placeholder="6-18 characters long and is case sensitive" />
                        </Item>
                    </Item>

                    <Item>
                        <Button htmlType="submit" className={joinClassName(styles['login-btn'], styles['dp-btn'])}>
                            Log in
                        </Button>
                        <AdminLoginErrorInfo
                            prefix={'circleError'}
                            prefixStyle={{ color: '#f31e2c' }}
                            visible={signInErrorState}
                        >
                            <span className={styles['error-tips']}>The email or the password is incorrect</span>
                        </AdminLoginErrorInfo>
                    </Item>
                </Form>
            </div>
        </div>
    );
});

export default Login;
