import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

export enum LicenseOrderStatus {
    Deleted = -1,
    UnKnown,
    unPaid,
    unActive,
    active,
    expired,
}

export enum LicenseStatus {
    Deleted = -1,
    UnKnown,
    UnActive,
    Normal,
    Offline,
    Inner,
}

export enum LicensePriceCycle {
    Day = 1,
    Month,
    Quarter,
    Year,
}

export const LicenseOrderStatusMsg = {
    [LicenseOrderStatus.Deleted]: '已删除',
    [LicenseOrderStatus.UnKnown]: '--',
    [LicenseOrderStatus.unPaid]: '未支付',
    [LicenseOrderStatus.unActive]: '未生效',
    [LicenseOrderStatus.active]: '已生效',
    [LicenseOrderStatus.expired]: '已过期',
} as { [key: number]: string };

export const LicensePriceCycleMsg = {
    [LicensePriceCycle.Day]: '天',
    [LicensePriceCycle.Month]: '月',
    [LicensePriceCycle.Quarter]: '季度',
    [LicensePriceCycle.Year]: '年',
} as { [key: number]: string };

export const LicenseStatusMsg = {
    [LicenseStatus.Deleted]: '已删除',
    [LicenseStatus.UnKnown]: '未知',
    [LicenseStatus.UnActive]: '未生效',
    [LicenseStatus.Normal]: '正常',
    [LicenseStatus.Offline]: '已下线',
    [LicenseStatus.Inner]: '仅内部可见',
} as { [key: number]: string };

export function fetchLicenseOrders(params: {
    status?: number;
    ordId?: number;
    orgName?: string;
    licenseId?: number;
    offset: number;
    pageSize: number;
}) {
    return axios.get(`/v1/licenses/orders/search`, { params }) as Promise<Response>;
}

export function fetchLicenses(params: {
    name?: string;
    id?: number;
    status?: number;
    offset: number;
    pageSize: number;
}) {
    return axios.get(`/v1/licenses`, { params }) as Promise<Response>;
}

export function queryLicenseSku(params: {
    licenseName?: string;
    page: number;
    pageSize: number;
    licenseId?: number;
    skuId?: number;
}) {
    return axios.get(`/v1/licenses/sku`, { params }) as Promise<Response>;
}

export function createLicenseOrder(data: { orgId: number; licenseId: number; isFree: boolean; buyUsedAmount: number }) {
    return axios.post('/v1/licenses/orders', data) as Promise<Response>;
}

export function getOrgProductLine(orgId: number) {
    return axios.get(`/v1/licenses/businesses/${orgId}`) as Promise<Response>;
}

export function getOrgLicenses(orgId: number) {
    return axios.get(`/v1/licenses/orders/${orgId}`) as Promise<Response>;
}

export function getQuota() {
    return axios.get('/v1/licenses/quota_items/list') as Promise<Response>;
}

export function enableLicense(id: number, startTime: string) {
    return axios.post('/v1/licenses/orders/enable', { id, startTime }) as Promise<Response>;
}
export function enableLicenses(data: { id: number; startTime: string }[]) {
    return axios.post('/v1/licenses/orders/batch_enable', data) as Promise<Response>;
}

export function getLicensesByOrderId(orderId: string | number) {
    return axios.get(`/v1/licenses/ordered/${orderId}`) as Promise<Response>;
}

export function createLicense(data: {
    businessId: number;
    name: string;
    quota: { [key: string]: string };
    resourceIds: number[];
}) {
    return axios.post(`/v1/licenses/create`, data) as Promise<Response>;
}

export function updateLicense(
    id: number,
    data: {
        businessId: number;
        name: string;
        quota: { [key: string]: string };
        resourceIds: number[];
    }
) {
    return axios.put(`/v1/licenses/${id}`, data) as Promise<Response>;
}

export function getLicense(id: number | string) {
    return axios.get(`/v1/licenses/${id}`) as Promise<Response>;
}
