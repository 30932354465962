import React from 'react';

export interface StatusProps {
    status: number;
}

function getStatusInfo(status: number): [string, string] {
    switch (status) {
        case 2:
            return ['#de141b', '失败'];
        case 1:
            return ['#5eba2a', '成功'];
        case 0:
        case 3:
        case 4:
            return ['#009bdb', '运行'];
        case 5:
            return ['#de141b', '取消'];
        case 6:
            return ['#e1c62f', '等待'];
        default:
            return ['#e1c62f', '等待'];
    }
}

const Status: React.FC<StatusProps> = function Status({ status }) {
    const [color, statusText] = getStatusInfo(status);
    return <span style={{ color: `${color}` }}>{statusText}</span>;
};

export default Status;
