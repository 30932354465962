import React from 'react';
import { Layout } from 'antd';
import AdminUerInfoDropdown from '@shared/components/user-dropdown';
import styles from './index.module.css';

const { Header } = Layout;

const CommonHeader: React.FC<{}> = function CommonHeader() {
    return (
        <Header className={styles['header-container']}>
            <div className={styles['header-text']}>DP 管理后台</div>
            <AdminUerInfoDropdown />
        </Header>
    );
};

export default CommonHeader;
