import { DatePicker, message, Modal, ModalProps, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { enableLicenses, getLicensesByOrderId, LicensePriceCycle, LicensePriceCycleMsg } from '@services/api/license';

export const LicenseEnableModal = (props: ModalProps & { onSubmit: (data: any) => void; orderId: number }) => {
    const { visible, orderId, onCancel, onSubmit } = props;
    const [items, setItems] = useState<any[]>([]);
    useEffect(() => {
        if (visible && orderId) {
            getLicensesByOrderId(orderId).then(rep => {
                setItems(rep.data);
            });
        }
    }, [orderId, visible]);
    return (
        <Modal
            width="60%"
            title="License启用"
            visible={visible}
            onOk={e => {
                const enabled = items.filter(item => item.startTime);

                const updatedEnabled = enabled.map(item => {
                    // 将时间转换为 moment 对象
                    const time = moment(item.startTime.toDate());

                    // 设置时间为当天午夜零点
                    time.startOf('day');

                    return { id: item.id, startTime: time.toISOString() };
                });

                enableLicenses(updatedEnabled).then(rep => {
                    if (!rep.code) {
                        message.success('操作成功');
                        onSubmit(e);
                    } else {
                        message.error(rep?.error?.msg || rep?.msg || '下单失败');
                    }
                });
            }}
            onCancel={onCancel}
        >
            <Table
                style={{ marginTop: 12 }}
                rowKey="id"
                dataSource={items}
                columns={[
                    {
                        title: 'sku ID',
                        dataIndex: 'id',
                    },
                    {
                        title: 'license id',
                        dataIndex: 'licenseId',
                    },
                    {
                        title: 'license name',
                        dataIndex: 'licenseName',
                    },
                    {
                        title: '定价周期',
                        dataIndex: 'priceCycle',
                        render: value => LicensePriceCycleMsg[value],
                        // dataIndex: 'buyUsedAmount',
                    },
                    {
                        title: '购买时长',
                        dataIndex: 'buyUsedAmount',
                    },
                    {
                        title: '开始时间',
                        dataIndex: 'startTime',
                        render: (value, row, index) => (
                            <DatePicker
                                disabledDate={date => date.diff(moment().startOf('day')) < 0}
                                value={value}
                                onChange={value => {
                                    items[index].startTime = value;
                                    setItems([...items]);
                                }}
                            />
                        ),
                    },
                    {
                        title: '结束时间',
                        render: (value, row) => {
                            if (!row.startTime) return '--';
                            if (row.priceCycle === LicensePriceCycle.Day)
                                return moment(row.startTime).add(row.buyUsedAmount, 'days').format('YYYY-MM-DD');
                            if (row.priceCycle === LicensePriceCycle.Month)
                                return moment(row.startTime)
                                    .add(row.buyUsedAmount * 30, 'days')
                                    .format('YYYY-MM-DD');
                            if (row.priceCycle === LicensePriceCycle.Quarter)
                                return moment(row.startTime)
                                    .add(row.buyUsedAmount * 90, 'days')
                                    .format('YYYY-MM-DD');
                            if (row.priceCycle === LicensePriceCycle.Year)
                                return moment(row.startTime)
                                    .add(row.buyUsedAmount * 365, 'days')
                                    .format('YYYY-MM-DD');
                        },
                    },
                ]}
            />
        </Modal>
    );
};
