import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, ModalProps, Select, Table } from 'antd';
import { fetchClass, fetchGoods } from '@services/api/goods';

const { Item } = Form;

export const SkuModal = (props: ModalProps & { onSubmit: (data: any) => void }) => {
    const { visible, onCancel = () => {}, onSubmit } = props;
    const [selection, setSelection] = useState<any>([]);
    const [skuForm] = Form.useForm();
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const [total, setTotal] = useState(10);
    const [goodClass, setGoodClass] = useState<any>([]);
    const [skuData, setSkuData] = useState<any>([]);
    const query = (newPage: number) => {
        setPage(newPage);
        fetchGoods({
            page: 1,
            pageSize,
        }).then(rep => {
            setSkuData(rep.data.items);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        if (visible) {
            query(1);
            fetchClass().then(rep => {
                setGoodClass(
                    rep.data.items.map((item: any) => ({
                        label: item.className,
                        value: item.classId,
                    }))
                );
            });
        }
    }, [visible]);
    return (
        <Modal
            width="60%"
            title="请选择商品"
            visible={visible}
            onOk={e => {
                // const existed = new Set(detail.items.map((item: any) => item.skuId));
                // const valid = selection.filter((item: any) => !existed.has(item.skuId));
                // if (valid.length !== selection.length) {
                //     message.warning('商品已添加过');
                // }
                onSubmit(selection);
                setSelection([]);
                onCancel(e);
            }}
            onCancel={e => {
                setSelection([]);
                onCancel(e);
            }}
        >
            <Form layout="inline" form={skuForm}>
                <Item label="商品分类" name="classId">
                    <Select style={{ width: 200 }} options={goodClass} />
                </Item>
                <Item label="SKU名称" name="skuName">
                    <Input placeholder="请输入" />
                </Item>
                <Item>
                    <Button onClick={() => skuForm.resetFields()}>重置</Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        type="primary"
                        onClick={() => {
                            fetchGoods({
                                ...skuForm.getFieldsValue(),
                                page: 1,
                                pageSize,
                            }).then(rep => {
                                setPage(1);
                                setSkuData(rep.data.items);
                                setTotal(rep.data.total);
                            });
                        }}
                    >
                        搜索
                    </Button>
                </Item>
            </Form>
            <Table
                style={{ marginTop: 10 }}
                rowKey="skuId"
                rowSelection={{
                    selectedRowKeys: selection.map((row: any) => row.skuId),
                    preserveSelectedRowKeys: true,
                    onChange: (selection, selectedRows) => {
                        setSelection(selectedRows);
                    },
                }}
                pagination={{
                    current: page,
                    pageSize,
                    total,
                    onChange: page => {
                        fetchGoods({
                            ...skuForm.getFieldsValue(),
                            page,
                            pageSize,
                        }).then(rep => {
                            setPage(page);
                            setSkuData(rep.data.items);
                            setTotal(rep.data.total);
                        });
                    },
                }}
                dataSource={skuData}
                columns={[
                    {
                        title: '序号',
                        render: (value, row, index) => index + 1,
                    },
                    {
                        title: 'SKU ID',
                        dataIndex: 'skuId',
                    },
                    {
                        title: 'SKU Name',
                        dataIndex: 'skuName',
                    },
                    {
                        title: '属性信息',
                        dataIndex: 'attributes',
                        render: value =>
                            value.map((item: any) => (
                                <div key={item.attribute}>
                                    {item.attributeName}: {item.attributeValueName}
                                </div>
                            )),
                    },
                    {
                        title: '消费类型',
                        dataIndex: 'consumeType',
                    },
                    {
                        title: '数量单位',
                        render: () => '个',
                    },
                    // {
                    //     title: '计费因子单位',
                    //     dataIndex: 'pricingElementUnit',
                    // },
                    {
                        title: '时长单位',
                        dataIndex: 'timeUnit',
                    },
                    {
                        title: '标准单价',
                        dataIndex: 'price',
                    },
                ]}
            />
        </Modal>
    );
};
