import React, { useState } from 'react';
import { Button, Input, Space, Checkbox, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FilterDropdownProps } from 'antd/es/table/interface';
// import { FilterDatePickerDropdownProps } from '@shared/model/type/utils/table-selector';
import { LabeledValue } from 'antd/lib/tree-select';
import styles from './index.module.css';

const ACTIVE_COLOR = '#1890ff';

const { RangePicker } = DatePicker;

export const getColumnSearchProps = (columnKey: string, handleSearch: (key: string, keyword: React.Key) => void) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
        <div className={styles['filter-container']}>
            <Input
                className={styles['margin-b-10']}
                value={selectedKeys[0]}
                allowClear
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => {
                        handleSearch(columnKey, selectedKeys[0]);
                        confirm();
                    }}
                    size="small"
                >
                    搜索
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        if (clearFilters) {
                            clearFilters();
                        }
                        handleSearch(columnKey, '');
                        confirm({ closeDropdown: false });
                    }}
                    size="small"
                >
                    重置
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? ACTIVE_COLOR : undefined }} />,
});

export const getColumnCheckProps = (options: Array<LabeledValue>, handleSearch: (key: string[]) => void) => {
    const allValues = options.map(({ value }) => value);
    const len = allValues.length;

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => {
            const selectedLen = selectedKeys.length;
            const indeterminate = !!selectedLen && selectedLen < len;
            const checkAll = selectedLen === len;

            return (
                <div className={styles['check-container']}>
                    <Checkbox
                        indeterminate={indeterminate}
                        checked={checkAll}
                        onChange={e => {
                            const { checked } = e.target;
                            setSelectedKeys(checked ? (allValues as React.Key[]) : []);
                            handleSearch([]);
                            confirm();
                        }}
                    >
                        全部
                    </Checkbox>
                    <Checkbox.Group
                        className={styles['check-item']}
                        value={selectedKeys}
                        onChange={value => {
                            setSelectedKeys(value as React.Key[]);
                            handleSearch(value as string[]);
                            confirm();
                        }}
                        options={options}
                    />
                </div>
            );
        },
        filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? ACTIVE_COLOR : undefined }} />,
    };
};

export const getColumnRangePickerProps = (
    columnKey: string,
    handleChange: (key: string, time: Array<Moment>, enableTime: boolean) => void,
    filterStatus: boolean,
    enableTime = true,
    initialTime = [moment().subtract(7, 'day').startOf('day'), moment().endOf('day')]
) => {
    return {
        filterDropdown: function FilterDropdown({
            // setSelectedKeys,
            // selectedKeys = [moment().subtract(7, 'day').startOf('day'), moment().endOf('day')],
            confirm,
            clearFilters,
        }: FilterDropdownProps) {
            const [checkedState, setCheckedState] = useState<boolean>(enableTime);
            const [rangeDateState, setRangeDateState] = useState<Array<Moment>>(initialTime);
            return (
                <div className={styles['filter-container']}>
                    <div className={styles['margin-b-10']}>
                        <Space>
                            <Checkbox
                                checked={checkedState}
                                onChange={(value: CheckboxChangeEvent) => {
                                    setCheckedState(value.target.checked);
                                }}
                            />
                            <RangePicker
                                disabled={!checkedState}
                                value={rangeDateState as any}
                                onChange={(values: any) => {
                                    setRangeDateState(values);
                                }}
                            />
                        </Space>
                    </div>
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleChange(columnKey, rangeDateState, checkedState);
                                    if (!checkedState && clearFilters) {
                                        clearFilters();
                                    }
                                    confirm();
                                }}
                                size="small"
                            >
                                搜索
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setCheckedState(true);
                                    handleChange(
                                        columnKey,
                                        [moment().subtract(7, 'day').startOf('day'), moment().endOf('day')],
                                        true
                                    );
                                    setRangeDateState([
                                        moment().subtract(7, 'day').startOf('day'),
                                        moment().endOf('day'),
                                    ]);
                                    confirm({ closeDropdown: false });
                                }}
                                size="small"
                            >
                                重置
                            </Button>
                        </Space>
                    </div>
                </div>
            );
        },
        filterIcon: <CalendarOutlined style={{ color: filterStatus ? ACTIVE_COLOR : undefined }} />, // <CalendarOutlined style={{ color: filtered ? ACTIVE_COLOR : undefined }} />,
    };
};
