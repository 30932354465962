import { Button, Form, Input, Modal, ModalProps, Row, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { BusinessSelector } from '@shared/components/biz/selector';
import { BusinessMsg, fetchResources } from '@services/api/resource';

const { Item } = Form;
export const ResourceModal = (props: ModalProps & { onSubmit: (data: any) => void }) => {
    const { onSubmit, ...restProps } = props;
    const [form] = Form.useForm();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [data, setData] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(1);
    const [selections, setSelections] = useState<any[]>([]);
    const resources = useRef<any>({});
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        return fetchResources({ ...params, page: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items);
            setTotal(rep.data.total);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
            rep.data.items.forEach((item: any) => {
                resources.current[item.id] = item;
            });
        });
    };
    useEffect(() => {
        if (props.visible) {
            setSelections([]);
            refresh(1, pageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);
    return (
        <Modal
            width={1000}
            title="请选择资源权限"
            {...restProps}
            onOk={() => {
                onSubmit(selections.map(key => resources.current[key]));
            }}
        >
            <Form layout="inline" form={form}>
                <Item name="id" label="ID">
                    <Input placeholder="请输入" />
                </Item>
                <Item name="name" label="名称">
                    <Input placeholder="请输入" />
                </Item>
                <Item name="identifier" label="标识符">
                    <Input placeholder="请输入" />
                </Item>
                <Item name="businessId" label="产品线">
                    <BusinessSelector useId />
                </Item>
            </Form>
            <Row justify="space-between" style={{ marginBottom: 10, marginTop: 10 }}>
                <Row>
                    <Button
                        type="primary"
                        onClick={() => {
                            refresh(1, pageSize);
                        }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            refresh(1, pageSize);
                        }}
                        type="default"
                        style={{ marginLeft: 10 }}
                    >
                        重置
                    </Button>
                </Row>
            </Row>
            <Table
                rowKey="id"
                scroll={{ x: true }}
                dataSource={data}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '资源标志符',
                        dataIndex: 'identifier',
                    },
                    {
                        title: 'action',
                        dataIndex: 'action',
                    },
                    {
                        width: 80,
                        title: '产品线',
                        dataIndex: 'identifier',
                        render: value => BusinessMsg[value],
                    },
                    {
                        width: 100,
                        title: '功能组ID',
                        dataIndex: 'groupId',
                    },
                    {
                        title: '功能组名称',
                        dataIndex: 'groupName',
                    },
                ]}
                pagination={{
                    current: page,
                    pageSize,
                    onChange: (page, pageSize) => {
                        refresh(page, pageSize);
                    },
                    total,
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: selections,
                    type: 'checkbox',
                    onChange: keys => {
                        setSelections(keys);
                    },
                }}
            />
        </Modal>
    );
};
