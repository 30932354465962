export enum ProductLine {
    Hermite = 'Hermite',
    Bohrium = 'Bohrium',
    Piloteye = 'Piloteye',
    Unifinder = 'Unifinder',
}

export enum OrderStatus {
    Cancel,
    Unpaid,
    Paid,
    Closed,
}

export const OrderStatusMsg = {
    [OrderStatus.Cancel]: '已取消',
    [OrderStatus.Unpaid]: '待支付',
    [OrderStatus.Paid]: '已支付',
    [OrderStatus.Closed]: '已关闭',
} as { [key: number]: string };
