import { useState, useEffect } from 'react';

const DEFAULT_HEIGHT = 200; // header, 内外边距等一些高度

function useListScrollHeight(
    initialHeight: number,
    extraHeight = 0
): [number, React.Dispatch<React.SetStateAction<number>>] {
    const [scrollHeightState, setScrollHeightState] = useState<number>(initialHeight);

    const resizeListHeight = () => {
        const height = document.body.offsetHeight - DEFAULT_HEIGHT - extraHeight;
        setScrollHeightState(height);
    };

    useEffect(() => {
        resizeListHeight();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeListHeight);
        return () => {
            document.removeEventListener('resize', resizeListHeight);
        };
    });

    return [scrollHeightState, setScrollHeightState];
}

export default useListScrollHeight;
