import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Space, Table } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdminLicense, fetchOrgList, fetchOrgTypes } from '@/services/api/org';
import { LICENSE_VOUCHER_RESOURCE, OrgListData, OrgListParams, OrgType } from '@/shared/model/type/org/org.type';
import useListScrollHeight from '@/shared/utils/hooks/useListScrollHeight';
import { useParamsStashFunc } from '@/shared/utils/hooks/useParamsStashFunc';
import { useLocationSearchParams } from '@/shared/utils/hooks/useLocationSearchParams';
import { NewOrgModal } from '@biz/org/org-list/components/new-org-modal';
import { ComboHistoryModal } from '@shared/components/biz/combo/history';
import OrgListForm from './components/form';
import OrgRechargeModal, { useRechargeHanlder } from './components/recharge';
import { VoucherModal } from './components/voucher-modal';

const OrgList: React.FC = React.memo(function OrgList() {
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [dataSourceState, setDataSourceState] = useState<OrgListData[]>([]);
    const [orgTypeState, setOrgTypeState] = useState<OrgType[]>([]);
    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 30, total: 0 });
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [scrollHeightState] = useListScrollHeight(550, 160);
    const [visible, setVisible] = useState<boolean>(false);
    const [voucherVisible, setVoucherVisible] = useState<boolean>(false);
    const [voucherLicense, setVoucherLicense] = useState<boolean>(false);
    const [comboVisible, setComboVisible] = useState<boolean>(false);
    const [orgId, setOrgId] = useState<number>();
    const orgTypeMap = useMemo(() => {
        const map = new Map<string, OrgType>();
        orgTypeState.forEach(type => {
            map.set(String(type.id), type);
        });
        return map;
    }, [orgTypeState]);
    const { initParams, setLocationParams } = useLocationSearchParams();
    const loadOrgList = useParamsStashFunc(async function initOrgList(params: OrgListParams) {
        setLoadingState(true);
        setLocationParams(params);
        try {
            const { data } = await fetchOrgList(params);
            if (!data || !data.items) {
                return setLoadingState(false);
            }
            setPaginationState({
                current: data.page,
                pageSize: data.perPage,
                total: data.total,
            });
            setDataSourceState(data?.items || []);
            setLoadingState(false);
        } catch (e) {
            setLoadingState(false);
        }
    });
    const { openRechargeModal, rechargeProps } = useRechargeHanlder(() => loadOrgList({}));

    const columns = useMemo(
        () => [
            {
                title: '组织ID',
                key: 'orgID',
                dataIndex: 'orgID',
                render: (text: string, record: OrgListData) => record?.id || '',
            },
            {
                title: '管理员邮箱',
                key: 'email',
                dataIndex: 'email',
                render: (text: string, record: OrgListData) => record?.user?.email || '',
            },
            {
                title: '管理员手机号',
                key: 'phone',
                dataIndex: 'phone',
                render: (text: string, record: OrgListData) => record?.user?.phone || '',
            },
            {
                title: '组织名称',
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: '组织类型',
                key: 'orgType',
                dataIndex: 'orgType',
                render: (text: string) => orgTypeMap.get(String(text))?.name || '',
            },
            {
                title: '账户余额',
                key: 'balance',
                dataIndex: 'balance',
                render: (text: number, record: OrgListData) => record?.wallet?.balance,
            },
            {
                title: '优惠余额',
                key: 'couponBalance',
                dataIndex: 'couponBalance',
                render: (text: number, record: OrgListData) => record?.wallet?.couponBalance,
            },
            // {
            //     title: '余额',
            //     key: 'balance',
            //     dataIndex: 'balance',
            // },
            {
                title: 'Pairs余额',
                key: 'pairs',
                dataIndex: 'pairs',
            },
            {
                title: '最后使用时间',
                key: 'updateTime',
                dataIndex: 'updateTime',
                render: (time: string) => (time ? moment(time).format('YYYY-MM-DD hh:mm:ss') : '-'),
            },
            {
                title: '操作',
                key: 'operation',
                dataIndex: 'operation',
                render: (text: string, record: OrgListData) => (
                    <Space>
                        <Button
                            onClick={() => {
                                navigate(`/orgList/detail/${record.id}`, {
                                    state: {
                                        from: location,
                                    },
                                });
                            }}
                            size="small"
                            type="primary"
                        >
                            详情
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(`/orgList/detail/${record.id}`, {
                                    state: {
                                        from: location,
                                        edit: true,
                                    },
                                });
                            }}
                            size="small"
                            type="primary"
                        >
                            编辑
                        </Button>
                        <Button
                            onClick={() => {
                                openRechargeModal(record?.user?.email || '', record?.id, record?.user?.id);
                            }}
                            size="small"
                            type="primary"
                        >
                            充值
                        </Button>
                        <Button
                            onClick={() => {
                                setOrgId(record.id);
                                setComboVisible(true);
                            }}
                            size="small"
                            type="primary"
                        >
                            套餐
                        </Button>
                    </Space>
                ),
            },
        ],
        [navigate, openRechargeModal, orgTypeMap, location]
    );

    useEffect(() => {
        const { current, pageSize } = paginationState;
        const { orgType, startTime, endTime, tagIds, ...stringParams } = initParams.current;

        form.setFieldsValue({
            ...stringParams,
            orgType: orgType === undefined ? orgType : Number(orgType),
            time: startTime && endTime ? [moment(startTime), moment(endTime)] : [],
            tagIds: tagIds ? tagIds?.split(',').map(Number) : undefined,
        });
        loadOrgList({
            ...initParams.current,
            tagIds: tagIds ? tagIds?.split(',').map(Number) : undefined,
            page: current,
            perPage: pageSize,
        });
        fetchOrgTypes().then(({ data }) => {
            setOrgTypeState(data);
        });

        fetchAdminLicense(LICENSE_VOUCHER_RESOURCE).then(({ data }) => {
            setVoucherLicense(!!data?.permission);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Button
                style={{ marginBottom: 12 }}
                type="primary"
                onClick={() => {
                    setVisible(true);
                }}
            >
                创建用户
            </Button>
            {voucherLicense && (
                <Button
                    style={{ marginBottom: 12, marginLeft: 12 }}
                    type="primary"
                    onClick={() => {
                        setVoucherVisible(true);
                    }}
                >
                    批量发放体验卡
                </Button>
            )}
            <OrgListForm
                form={form}
                orgTypeList={orgTypeState}
                onSubmit={values => {
                    loadOrgList({ ...values, page: 1 });
                }}
            />
            <Table
                columns={columns}
                rowKey={item => item.id}
                dataSource={dataSourceState}
                scroll={{ y: scrollHeightState }}
                loading={loadingState}
                pagination={{
                    ...paginationState,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                    onChange: (current, pageSize) => {
                        loadOrgList({ page: current, perPage: pageSize });
                    },
                }}
            />
            <OrgRechargeModal {...rechargeProps} />
            <NewOrgModal
                visible={visible}
                onCancel={() => setVisible(false)}
                onRefresh={() => {
                    const { tagIds } = initParams.current;
                    const { current, pageSize } = paginationState;
                    loadOrgList({
                        ...initParams.current,
                        tagIds: tagIds ? tagIds?.split(',').map(Number) : undefined,
                        page: current,
                        perPage: pageSize,
                    });
                }}
            />
            <ComboHistoryModal
                orgId={orgId!}
                visible={comboVisible}
                onOk={() => {
                    setComboVisible(false);
                }}
                onCancel={() => {
                    setComboVisible(false);
                }}
            />
            <VoucherModal
                visible={voucherVisible}
                onCancel={() => {
                    setVoucherVisible(false);
                }}
            />
        </div>
    );
});

export default OrgList;
