import { Button, Form, Input, message, Row, Table, Tree } from 'antd';
import { useEffect, useState } from 'react';
import { CategorySelector } from '@shared/components/biz/sku/category-selector';
import { GoodsStatusSelector, ChargeTypeSelector } from '@shared/components/biz/selector';
import { createGoodCategory, fetchGoodCategories, fetchGoodCategory, updateGoodCategory } from '@services/api/goods';
import { AttributeModal } from '@shared/components/biz/sku/attribute-selector';
import { guid } from '@utils/util';

const { Item } = Form;
const setTreeValue = (treeNode: any, cb: (node: any) => void) => {
    cb(treeNode);
    if (treeNode.children?.length) {
        treeNode.children.forEach((node: any) => {
            setTreeValue(node, cb);
        });
    }
};
export const TechGoodsCategory = () => {
    const [form] = Form.useForm();
    const [treeData, setTreeData] = useState<any[]>([]);
    const [detail, setDetail] = useState<any>({
        attributes: [],
    });

    const [visible, setVisible] = useState<boolean>(false);
    const refresh = () => {
        setTreeData([]);
        fetchGoodCategories().then(rep => {
            setTreeData(
                rep.data.map((node: any) => {
                    setTreeValue(node, node => Object.assign(node, { key: guid() }));
                    return node;
                })
            );
        });
    };
    useEffect(() => {
        refresh();
    }, []);
    return (
        <>
            <Row>
                <Button
                    type="primary"
                    onClick={() => {
                        form.resetFields();
                        setDetail({
                            attributes: [],
                        });
                    }}
                >
                    添加
                </Button>
            </Row>
            <Row justify="space-between" style={{ marginTop: 10 }}>
                <div style={{ flex: 1, background: '#FFFFFF', padding: 10 }}>
                    {treeData.length ? (
                        <Tree
                            defaultExpandParent
                            defaultExpandAll
                            blockNode
                            treeData={treeData}
                            titleRender={(node: any) => (
                                <Row
                                    onClick={() => {
                                        fetchGoodCategory(node.id).then(rep => {
                                            setDetail({
                                                ...rep.data,
                                            });
                                            form.setFieldsValue({
                                                parentId: rep.data.parentId,
                                                name: rep.data.name,
                                                status: rep.data.status,
                                                chargingType: rep.data.chargingType,
                                            });
                                        });
                                    }}
                                >
                                    {node.name}
                                </Row>
                            )}
                        />
                    ) : null}
                </div>

                <div style={{ flex: 1, marginLeft: 10, background: '#FFFFFF', padding: 10 }}>
                    <Form labelCol={{ span: 4 }} form={form}>
                        <Item name="parentId" label="上级目录">
                            <CategorySelector />
                        </Item>
                        <Item name="name" label="名称">
                            <Input />
                        </Item>
                        <Item name="status" label="状态">
                            <GoodsStatusSelector />
                        </Item>
                        <Item name="chargingType" label="付费方式">
                            <ChargeTypeSelector />
                        </Item>
                    </Form>
                    {!detail?.id ? (
                        <Row style={{ marginBottom: 10 }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                添加属性
                            </Button>
                        </Row>
                    ) : null}
                    <Table
                        rowKey="id"
                        size="small"
                        dataSource={detail?.attributes ?? []}
                        pagination={false}
                        columns={[
                            {
                                title: '属性ID',
                                dataIndex: 'id',
                                align: 'center',
                            },
                            {
                                title: '属性名称',
                                dataIndex: 'name',
                                align: 'center',
                            },
                            {
                                align: 'center',
                                title: '操作',
                                render: (value, row, index) =>
                                    !detail?.id ? (
                                        <Button
                                            danger
                                            type="primary"
                                            onClick={() => {
                                                detail.attributes.splice(index, 1);
                                                setDetail({
                                                    ...detail,
                                                    attributes: [...detail.attributes],
                                                });
                                            }}
                                        >
                                            删除
                                        </Button>
                                    ) : null,
                            },
                        ]}
                    />
                    <Row justify="center" align="middle" style={{ marginTop: 10 }}>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                const data = form.getFieldsValue();
                                if (
                                    Object.keys(data).some(key => !data[key] && data[key] !== 0 && key !== 'parentId')
                                ) {
                                    return message.error('请填写完整表单');
                                }
                                const action = detail?.id
                                    ? updateGoodCategory(detail.id, {
                                          ...data,
                                      })
                                    : createGoodCategory({
                                          ...data,
                                          attributes: detail.attributes.map((a: any) => a.id),
                                      });
                                action.then(rep => {
                                    if (!rep.code) {
                                        message.success('操作成功');
                                        setDetail({
                                            attributes: [],
                                        });
                                        form.resetFields();
                                        refresh();
                                    } else {
                                        message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                                    }
                                });
                            }}
                            type="primary"
                        >
                            确认
                        </Button>
                    </Row>
                </div>
                <AttributeModal
                    visible={visible}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    onSubmit={values => {
                        const existed = new Set(detail.attributes.map((a: any) => a.id));
                        const valid = values.filter((item: any) => !existed.has(item.id));
                        setVisible(false);
                        setDetail({
                            ...detail,
                            attributes: [...detail.attributes, ...valid],
                        });
                    }}
                />
            </Row>
        </>
    );
};
