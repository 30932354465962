import { RouteAuthConfig } from '@/shared/model/type/router.type';

export type Path = string;

export type ParentMenuKey = Array<string>;

const parentMenuKeyMap = new Map<Path, ParentMenuKey>();

export function transformRouteConfig(config: Array<RouteAuthConfig>, parentKeys: Array<string> = []) {
    config.forEach(item => {
        parentMenuKeyMap.set(item.key, parentKeys);
        if (item.children) {
            transformRouteConfig(item.children, [item.key, ...parentKeys]);
        }
    });

    return parentMenuKeyMap;
}

export function getPathParentMenu(key: string) {
    if (parentMenuKeyMap.has(key)) {
        return parentMenuKeyMap.get(key) || [];
    }
    return [];
}
