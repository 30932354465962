import { GlobalContext } from '@/App';
import { ComboStatus, ComboStatusText, getCombo } from '@services/api/combo';
import { LicensePriceCycleMsg } from '@services/api/license';
import { Form, Modal, ModalProps, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';

const { Item } = Form;
enum Type {
    Sku = 1,
    License,
}
export const TradeComboDetailModal = (props: ModalProps & { id: number | string }) => {
    const globalData = useContext(GlobalContext);
    const isAWS = globalData?.env === 'aws';
    const { visible, id } = props;
    const [combo, setCombo] = useState<any>({});
    useEffect(() => {
        if (visible) {
            getCombo(id).then(rep => {
                setCombo(rep.data);
            });
        }
    }, [id, visible]);
    return (
        <Modal width={800} title="查看套餐内容" {...props}>
            <Form>
                <Item required label="套餐名称">
                    {combo.name}
                </Item>
                <Item required label="是否有效">
                    {ComboStatusText[combo.status as ComboStatus]}
                </Item>
                <Item required label="优惠余额充值" name="couponBalance">
                    {combo.couponBalance}
                </Item>
                {/* <Item required label="客户充值金额" name="balance"> */}
                {/*    {combo.balance} */}
                {/* </Item> */}
            </Form>
            <Row style={{ marginBottom: 10 }}>
                {isAWS ? (
                    <span>套餐原价: $ {combo.originalPrice}</span>
                ) : (
                    <span>套餐原价: {combo.originalPrice}元</span>
                )}
                {isAWS ? (
                    <span style={{ marginLeft: 10 }}>套餐购买价: $ 0</span>
                ) : (
                    <span style={{ marginLeft: 10 }}>套餐购买价：0元</span>
                )}
            </Row>
            <Table
                dataSource={combo.items}
                columns={[
                    {
                        title: '商品名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '类别',
                        dataIndex: 'skuType',
                        render: value => (value === Type.License ? 'license' : '资源包'),
                    },
                    {
                        title: '体验时长',
                        dataIndex: 'buyUsedAmount',
                        render: (value, row) =>
                            row.skuType === Type.License
                                ? `${value} (${LicensePriceCycleMsg[row.priceCycle] ?? '--'})`
                                : '--',
                    },
                    {
                        title: '数量',
                        dataIndex: 'num',
                        render: (value, row) => (row.skuType === Type.Sku ? value : '--'),
                    },
                    {
                        title: '套餐价',
                        dataIndex: 'price',
                    },
                ]}
            />
        </Modal>
    );
};
