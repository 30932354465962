import { Button, DatePicker, Form, Input, message, Modal, Row, InputNumber } from 'antd';
import { useState } from 'react';
import { fulfillAgreement, reBohriumBill, reTrade, setDiscount } from '@services/api/support';
import { excludeChangeInfo, fifoOrgRerun } from '@services/api/finance';
import fileSelector from '@utils/fileSelector';
import { reverseSettlementWithRecharge } from '@/services/api/bank';

const { Item } = Form;
export const TechFix = () => {
    const [agreementVisible, setAgreementVisible] = useState<boolean>(false);
    const [orderVisible, setOrderVisible] = useState<boolean>(false);
    const [bohriumVisible, setBohriumVisible] = useState<boolean>(false);
    const [discountVisible, setDiscountVisible] = useState<boolean>(false);
    const [reverseSettlementVisible, setReverseSettlementVisible] = useState<boolean>(false);
    const [financeFifoVisible, setFinanceFifoVisible] = useState<boolean>(false);
    const [fifoOrgRerunVisible, setFifoOrgRerunVisible] = useState<boolean>(false);

    const [form] = Form.useForm();

    return (
        <div>
            <Row>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setAgreementVisible(true);
                    }}
                >
                    履约
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setOrderVisible(true);
                    }}
                >
                    重新生成交易账单
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setBohriumVisible(true);
                    }}
                >
                    重新生成Bohrium账单
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setDiscountVisible(true);
                    }}
                >
                    折扣设置
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setReverseSettlementVisible(true);
                    }}
                >
                    撤销充值结算
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setFinanceFifoVisible(true);
                    }}
                >
                    手动忽略财务先入先出流水
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setFifoOrgRerunVisible(true);
                    }}
                >
                    先入先出重跑org
                </Button>
            </Row>
            <Modal
                onOk={() => {
                    const data = form.getFieldsValue();
                    fulfillAgreement({
                        orderItemId: +data.orderItemId,
                        usedAmount: +data.usedAmount,
                        startTime: data.startTime ? `${data.startTime.format('YYYY-MM-DDTHH:mm:ss')}Z` : undefined,
                        endTime: data.endTime ? `${data.endTime.format('YYYY-MM-DDTHH:mm:ss')}Z` : undefined,
                        jobId: +data.jobId,
                        subUniqueKey: data.subUniqueKey,
                        fulfillmentStatus: +data.fulfillmentStatus,
                    }).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setAgreementVisible(false);
                        } else {
                            message.error(rep?.error?.msg || '操作失败');
                        }
                    });
                }}
                onCancel={() => setAgreementVisible(false)}
                destroyOnClose
                visible={agreementVisible}
                title="履约"
            >
                <Form form={form}>
                    <Item name="orderItemId" label="orderItem Id">
                        <Input type="number" placeholder="请输入正整数" />
                    </Item>
                    <Item name="usedAmount" label="usedAmount">
                        <Input type="number" placeholder="请输入正整数" />
                    </Item>
                    <Item name="startTime" label="startTime">
                        <DatePicker showTime />
                    </Item>
                    <Item name="endTime" label="endTime">
                        <DatePicker showTime />
                    </Item>
                    <Item name="jobId" label="job Id">
                        <Input type="number" placeholder="请输入正整数" />
                    </Item>
                    <Item name="subUniqueKey" label="subUniqueKey">
                        <Input placeholder="请输入字符串" />
                    </Item>
                    <Item name="fulfillmentStatus" label="fulfillmentStatus">
                        <Input placeholder="请输入整数，支持0或负数" />
                    </Item>
                </Form>
            </Modal>
            <Modal
                onCancel={() => setOrderVisible(false)}
                destroyOnClose
                onOk={() => {
                    reTrade(form.getFieldValue('date').format('YYYY-MM-DD')).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setOrderVisible(false);
                        } else {
                            message.error(rep?.error?.msg || '操作失败');
                        }
                    });
                }}
                visible={orderVisible}
                title="重新生成交易账单"
            >
                <Form form={form}>
                    <Item name="date" label="日期">
                        <DatePicker />
                    </Item>
                </Form>
            </Modal>
            <Modal
                onCancel={() => setBohriumVisible(false)}
                destroyOnClose
                onOk={() => {
                    reBohriumBill(form.getFieldValue('date').format('YYYY-MM-DD')).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setBohriumVisible(false);
                        } else {
                            message.error(rep?.error?.msg || '操作失败');
                        }
                    });
                }}
                visible={bohriumVisible}
                title="重新生成Bohrium账单"
            >
                <Form form={form}>
                    <Item name="date" label="日期">
                        <DatePicker />
                    </Item>
                </Form>
            </Modal>
            <Modal
                okText="上传文件"
                onCancel={() => setDiscountVisible(false)}
                destroyOnClose
                onOk={() => {
                    fileSelector.setAttrs({ multiple: false });
                    fileSelector.selectFile().then(files => {
                        setDiscount(files[0]).then(rep => {
                            if (!rep.code) {
                                message.success(
                                    `折扣配置成功，新增${rep.data.inserts}条折扣，更新${rep.data.updates}条折扣`
                                );
                                setDiscountVisible(false);
                            } else {
                                message.error(rep?.error?.msg || '操作失败');
                            }
                        });
                    });
                }}
                visible={discountVisible}
                title="折扣设置"
            />
            <Modal
                onCancel={() => setReverseSettlementVisible(false)}
                destroyOnClose
                onOk={() => {
                    const rechargeId = form.getFieldValue('rechargeId');
                    reverseSettlementWithRecharge(rechargeId).then(rep => {
                        console.log(rep, '----');
                        if (!rep.code) {
                            message.success('操作成功');
                        } else {
                            message.error(rep?.error?.msg || '操作失败');
                        }
                        setReverseSettlementVisible(false);
                    });
                }}
                visible={reverseSettlementVisible}
                title="撤销充值结算"
            >
                <Form form={form}>
                    <Item name="rechargeId" label="请填写充值id" rules={[{ required: true, message: '请填写充值id' }]}>
                        <InputNumber min={0} style={{ width: '200px' }} />
                    </Item>
                </Form>
            </Modal>
            <Modal
                title="手动忽略财务先入先出流水"
                visible={financeFifoVisible}
                onOk={() => {
                    const changeInfoIdsString = form.getFieldValue('changeInfoIds');
                    if (!changeInfoIdsString) {
                        message.error('请填写流水id,逗号分割');
                        return;
                    }
                    try {
                        const changeInfoIdsArray = changeInfoIdsString.split(',').map(Number);
                        excludeChangeInfo(changeInfoIdsArray).then(rep => {
                            if (!rep.code) {
                                message.success('操作成功');
                                setFinanceFifoVisible(false);
                            } else {
                                message.error(rep?.error?.msg || '操作失败');
                            }
                        });
                    } catch (e) {
                        message.error('请填写流水id,逗号分割');
                    }
                }}
                onCancel={() => setFinanceFifoVisible(false)}
            >
                <Form form={form}>
                    <Form.Item
                        name="changeInfoIds"
                        label="changeInfo流水id"
                        rules={[{ required: true, message: '请填写流水id,逗号分割' }]}
                    >
                        <Input.TextArea
                            style={{ width: '300px', height: '200px' }}
                            placeholder="请填写流水id逗号分割"
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="先入先出重跑org"
                visible={fifoOrgRerunVisible}
                onOk={() => {
                    const orgId = parseInt(form.getFieldValue('orgId'), 10);
                    const dateRange = form.getFieldValue('dateRange');
                    if (!orgId) {
                        message.error('orgId不能为空');
                        return;
                    }
                    if (!dateRange || dateRange.length !== 2) {
                        message.error('请选择正确的日期范围');
                        return;
                    }
                    const datas = {
                        orgId,
                        beginDate: dateRange[0].format('YYYY-MM-DD'),
                        endDate: dateRange[1].format('YYYY-MM-DD'),
                    };
                    fifoOrgRerun(datas).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setFifoOrgRerunVisible(false);
                        } else {
                            message.error(rep?.error?.msg || '操作失败');
                        }
                    });
                }}
                onCancel={() => setFifoOrgRerunVisible(false)}
            >
                <Form form={form}>
                    <Form.Item rules={[{ required: true, message: '请输入orgId' }]}>
                        <Item name="orgId" label="orgId">
                            <Input type="number" placeholder="请输入orgId" />
                        </Item>
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '请选择日期范围' }]}>
                        <Item name="dateRange" label="重跑日期范围">
                            <DatePicker.RangePicker />
                        </Item>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
