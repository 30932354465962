import { useRef, useCallback } from 'react';

export function useParamsStashFunc(func: (params: any) => any, initParams?: Parameters<typeof func>[0]) {
    type Params = Parameters<typeof func>[0];
    const paramsRef = useRef<Params | undefined>(initParams);
    return useCallback(
        async (params?: Partial<Params>) => {
            paramsRef.current = { ...paramsRef.current, ...params };
            await func(paramsRef.current);
        },
        [func]
    );
}
