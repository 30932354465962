import { ACCOUNT_STATUS } from '../../enum/org/sub-account';

export const ACCOUNT_STATUS_OPTIONS = [
    {
        label: '正常',
        value: ACCOUNT_STATUS.NORMAL,
    },
    {
        label: '冻结',
        value: ACCOUNT_STATUS.FREEZE,
    },
];
