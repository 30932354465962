import pageDefaultLoading from '@shared/components/loading/page-loading';
import RichRoutes from '@shared/utils/router/rich-routes';
import React, { createContext, useEffect, useState } from 'react';
import { RouteConfig } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import { fetchLangConfig } from './services/api/auth';

type GlobalType = {
    env: string;
};

export const GlobalContext = createContext<GlobalType | null>(null);

const App: React.FC<{ route: RouteConfig | RouteConfig[] }> = ({ route }) => {
    const [globalEnv, setGlobalEnv] = useState<string>('aws');

    // 假设你有一个获取全局数据的异步函数fetchGlobalData
    useEffect(() => {
        fetchLangConfig().then(data => setGlobalEnv(data.data.env));
    }, []);

    return (
        <React.Suspense fallback={pageDefaultLoading}>
            <BrowserRouter>
                {/* 使用Context.Provider提供context */}
                <GlobalContext.Provider
                    value={{
                        env: globalEnv,
                    }}
                >
                    <RichRoutes route={route} />
                </GlobalContext.Provider>
            </BrowserRouter>
        </React.Suspense>
    );
};

export default App;
