export const VIP_OPTIONS = [
    {
        label: 'VIP用户',
        value: 1,
    },
    {
        label: '普通用户',
        value: 2,
    },
];

export const CHARGE_TYPE_OPTIONS = [
    {
        label: '客户充值',
        value: 1,
    },
    {
        label: '客户补偿',
        value: 2,
    },
    {
        label: '优惠活动',
        value: 3,
    },
    {
        label: '客户测试',
        value: 4,
    },
    {
        label: '内部测试',
        value: 5,
    },
    {
        label: '产研充值',
        value: 6,
    },
];

export const MONEY_ARRIVAL_STATUS = [
    {
        label: '已到款',
        value: 1,
    },
    {
        label: '未到款',
        value: -1,
    },
];

export const INTERNAL_CHARGE_TYPE_OPTIONS = [
    {
        label: '内部测试',
        value: 5,
    },
    {
        label: '产研充值',
        value: 6,
    },
];

// 之前这样使用，暂且不动了，但是bohrium和hermite value取反了
export const ORG_SOURCE_OPTIONS = [
    {
        label: 'Bohrium',
        value: 1,
    },
    {
        label: 'Hermite',
        value: 2,
    },
    {
        label: 'Piloteye',
        value: 3,
    },
    {
        label: 'Unifinder',
        value: 4,
    },
];

export const BIZLINE_OPTIONS = [
    {
        label: 'Hermite',
        value: 1,
    },
    {
        label: 'Bohrium',
        value: 2,
    },
];
