import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { ACCOUNT_STATUS_OPTIONS } from '@/shared/model/constant/org/sub-account';
import { SubAccountParams } from '@/shared/model/type/org/sub-account.type';

const { RangePicker } = DatePicker;
interface SubAccountFormProps {
    onSubmit: (values: Partial<SubAccountParams>) => void;
}

const SubAccountForm: React.FC<SubAccountFormProps> = React.memo(function SubAccountForm({ onSubmit }) {
    const [form] = Form.useForm();
    const colProps = useMemo(
        () => ({
            span: 8,
        }),
        []
    );
    return (
        <Form
            form={form}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{ span: 17 }}
            onFinish={values => {
                console.log(values);
                const { time, ...params } = values;
                const [startTime, endTime] = (time || []).map((m: Moment) => m.format('YYYY-MM-DD'));
                onSubmit({
                    ...params,
                    startTime,
                    endTime,
                });
            }}
        >
            <Row>
                <Col {...colProps}>
                    <Form.Item label="账号ID" name={'userId'}>
                        <Input
                            type={'number'}
                            allowClear
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="请输入账号id"
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="账号登录名" name={'email'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入账号登录名" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="组织名称" name={'orgName'}>
                        <Input disabled allowClear spellCheck={false} autoComplete="off" placeholder="请输入组织名称" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="账号状态" name={'status'}>
                        <Select options={ACCOUNT_STATUS_OPTIONS} allowClear placeholder="请选择账号状态" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="注册时间" name={'time'}>
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="手机号" name={'phone'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入手机号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="主页ID" name={'extId'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入主页ID" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item
                        wrapperCol={{
                            offset: 7,
                            span: 17,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>{' '}
                        <Button
                            htmlType="button"
                            onClick={() => {
                                form.resetFields();
                                form.submit();
                            }}
                        >
                            重置
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default SubAccountForm;
