import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

export function fulfillAgreement(data: {
    orderItemId: number;
    usedAmount: number;
    startTime?: string;
    endTime?: string;
    jobId: number;
    subUniqueKey: string;
    fulfillmentStatus: number;
}) {
    return axios.post('/v1/support/fulfill', [data]) as Promise<Response>;
}

export function reTrade(date: string) {
    return axios.post('/v1/support/trade_bill', { date }) as Promise<Response>;
}

export function reBohriumBill(date: string) {
    return axios.post('/v1/support/bohrium_bill', { date }) as Promise<Response>;
}

export function setDiscount(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('/v1/support/discount', formData) as Promise<Response>;
}
