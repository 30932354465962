import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { Button, message, Table, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchJobs } from '@services/api/jobs';
import { JobListParams, JobData } from '@shared/model/type/jobs/job.type';
import { JOB_STATUS } from '@shared/model/enum';
import useList from '@shared/utils/hooks/useList';
import useListScrollHeight from '@shared/utils/hooks/useListScrollHeight';
import {
    getColumnSearchProps,
    getColumnCheckProps,
    getColumnRangePickerProps,
} from '@/shared/utils/table-filter/table-filter-selector';
import { TIME_LAYOUT } from '@shared/model/constant/time';
import { STATUS_OPTIONS, JOB_FUNCTION_NAME, JOB_TYPE, JOB_TYPE_OPTIONS } from './config';
import StatusComponent from './components/status';

const JobList: React.FC<{}> = function JobList() {
    const [jobListState, setJobListState, totalState, setTotalState, loadingState, setLoadingState] = useList<JobData>(
        []
    );
    const [scrollHeightState] = useListScrollHeight(550);
    const [submitTimeFilterState, setSubmitTimeFilterState] = useState<boolean>(true);
    const [finishTimeFilterState, setFinishTimeFilterState] = useState<boolean>(false);
    // const [form] = Form.useForm();
    const paramsRef = useRef<JobListParams>({
        offset: 1,
        pageSize: 30,
        email: '',
        jobType: '',
        jobId: 0,
        status: '',
        toSubmitTime: moment().endOf('day').unix(),
        fromSubmitTime: moment().startOf('day').subtract(7, 'day').unix(),
        toFinishTime: 0,
        fromFinishTime: 0,
        projectId: 0,
    });

    const loadJobs = () => {
        setLoadingState(true);
        fetchJobs({
            ...paramsRef.current,
        })
            .then(res => {
                setJobListState(res.list);
                setTotalState(res.total);
            })
            .catch(err => {
                message.error(err.msg || '服务器错误');
            })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const resetPage = () => {
        paramsRef.current.offset = 1;
    };

    const handle = useCallback((callback: (...values: any) => void) => {
        resetPage();
        return function search(...params: any) {
            callback.apply(null, [...params]);
            loadJobs();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const JOB_COLUMNS = useMemo(
        () => [
            {
                title: '任务 ID',
                key: 'id',
                dataIndex: 'id',
                ...getColumnSearchProps(
                    'id',
                    handle((_, value) => {
                        paramsRef.current.jobId = value;
                    })
                ),
            },
            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render: (status: number) => {
                    return <StatusComponent status={status} />;
                },
                ...getColumnCheckProps(
                    STATUS_OPTIONS,
                    handle(keys => {
                        paramsRef.current.status = keys.join(',');
                    })
                ),
            },
            {
                title: '累计花费',
                key: 'cost',
                dataIndex: 'cost',
                render: (cost: number) => {
                    return cost.toFixed(3);
                },
            },
            {
                title: '用户',
                key: 'email',
                dataIndex: 'email',
                ...getColumnSearchProps(
                    'email',
                    handle((_, value) => {
                        paramsRef.current.email = value;
                    })
                ),
            },
            {
                title: '任务类型',
                key: 'jobType',
                dataIndex: 'jobType',
                render: (jobType: JOB_TYPE) => JOB_FUNCTION_NAME[jobType],
                ...getColumnCheckProps(
                    JOB_TYPE_OPTIONS,
                    handle(keys => {
                        paramsRef.current.jobType = keys.join(',');
                    })
                ),
            },
            {
                title: '项目 ID',
                key: 'projectId',
                dataIndex: 'projectId',
                ...getColumnSearchProps(
                    'projectId',
                    handle((_, value) => {
                        paramsRef.current.projectId = value;
                    })
                ),
            },
            {
                title: '提交时间',
                key: 'submitTime',
                dataIndex: 'submitTime',
                render: (time: number) => {
                    if (!time) {
                        return '-';
                    }
                    return moment(time).format(TIME_LAYOUT);
                },
                ...getColumnRangePickerProps(
                    'submitTime',
                    handle((_, value, enableTime) => {
                        paramsRef.current.offset = 1;
                        setSubmitTimeFilterState(enableTime);
                        if (enableTime && value?.length) {
                            paramsRef.current.fromSubmitTime = value[0].unix();
                            paramsRef.current.toSubmitTime = value[1].endOf('day').unix();
                            return;
                        }
                        paramsRef.current.fromSubmitTime = 0;
                        paramsRef.current.toSubmitTime = 0;
                    }),
                    submitTimeFilterState
                ),

                // filterIcon: <CalendarOutlined style={{ color: testState ? '#1890ff' : undefined }} />,
            },
            {
                title: '完成时间',
                key: 'finishTime',
                dataIndex: 'finishTime',
                render: (time: number) => {
                    if (!time) {
                        return '-';
                    }
                    return moment(time).format(TIME_LAYOUT);
                },
                ...getColumnRangePickerProps(
                    'fimishTime',
                    handle((_, value, enableTime) => {
                        paramsRef.current.offset = 1;
                        setFinishTimeFilterState(enableTime);
                        if (enableTime && value?.length) {
                            paramsRef.current.fromFinishTime = value[0].unix();
                            paramsRef.current.toFinishTime = value[1].endOf('day').unix();
                            return;
                        }
                        paramsRef.current.fromFinishTime = 0;
                        paramsRef.current.toFinishTime = 0;
                    }),
                    finishTimeFilterState,
                    false
                ),
            },
            {
                title: '操作',
                key: 'operation',
                dataIndex: 'operation',
                render: (_: string, item: JobData) => {
                    return (
                        <Space>
                            <Button
                                size="small"
                                onClick={() => {
                                    window.open(item.resultsOssPath);
                                }}
                                disabled={item.status !== JOB_STATUS.FAIL && item.status !== JOB_STATUS.SUCCESS}
                            >
                                <DownloadOutlined />
                                下载
                            </Button>
                        </Space>
                    );
                },
            },
        ],
        [handle, submitTimeFilterState, finishTimeFilterState]
    );
    // const submit = () => {
    //     const formValues: FormParams = form.getFieldsValue();

    //     Object.keys(formValues).forEach(key => {
    //         if (key === 'submitTime') {
    //             const time = formValues[key];
    //             params.current.fromSubmitTime = time[0].unix();
    //             params.current.toSubmitTime = time[1].unix();
    //             return;
    //         }
    //         (params.current[key as keyJobsParams] as string | number) = formValues[key as keyJobsParams];
    //     });
    // };

    // const resetForm = () => {
    //     const initialParams = {
    //         offset: 1,
    //         pageSize: 30,
    //         email: '',
    //         jobType: '',
    //         jobId: 0,
    //         status: '',
    //         toSubmitTime: moment().startOf('day').unix(),
    //         fromSubmitTime: moment().startOf('day').subtract(7, 'day').unix(),
    //     };
    //     params.current = initialParams;
    //     form.setFieldsValue({ ...params.current });
    // };

    useEffect(() => {
        loadJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section>
            {/* <Form
                form={form}
                onFinish={submit}
                initialValues={{
                    email: '',
                    jobType: '',
                    jobId: 0,
                    status: '',
                    submitTime: [moment().startOf('day'), moment().subtract(7, 'day').startOf('day')],
                    finishTime: [moment().startOf('day'), moment().subtract(7, 'day').startOf('day')],
                }}
            >
                <Row>
                    <Item name="jobId" label="任务ID">
                        <Input />
                    </Item>
                    <Item name="projectId" label="项目ID">
                        <Input />
                    </Item>
                    <Item name="submitTime" label="提交时间">
                        <RangePicker />
                    </Item>
                </Row>
                <Row>
                    <Item name="status" label="状态">
                        <Select options={STATUS_OPTIONS} />
                    </Item>
                    <Item name="email" label="用户账号">
                        <Input placeholder="请输入Email" />
                    </Item>
                    <Item name="finishTime" label="结束时间">
                        <RangePicker />
                    </Item>
                </Row>
                <Item>
                    <Button htmlType="submit">搜索</Button>
                    <Button onClick={resetForm}>重置</Button>
                </Item>
            </Form> */}
            <Table
                loading={loadingState}
                columns={JOB_COLUMNS}
                dataSource={jobListState}
                scroll={{ y: scrollHeightState }}
                rowKey={row => row.id}
                pagination={{
                    total: totalState,
                    showSizeChanger: true,
                    current: paramsRef.current.offset,
                    onShowSizeChange: (current: number, size: number) => {
                        paramsRef.current.pageSize = size;
                        loadJobs();
                    },
                    pageSize: paramsRef.current.pageSize,
                    onChange: (page: number) => {
                        if (paramsRef.current.offset === page) {
                            return;
                        }
                        paramsRef.current.offset = page;
                        loadJobs();
                    },
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                }}
            />
        </section>
    );
};

export default JobList;
