import Cookies from 'js-cookie';
import { decode } from 'js-base64';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Radio, Select, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { openNewWindow } from '@/shared/utils/util';
import { fetchGoods } from '@services/api/goods';
import { charge, fetchWalletInfo } from '@/services/api/finance';
import {
    BALANCE_TYPE,
    // INTERNAL_CHARGE_TYPE_OPTIONS,
    ORG_SOURCE_OPTIONS,
    // PairsInfo,
    WalletInfo,
} from '@/biz/user/model';

const { Item } = Form;
const { TextArea } = Input;

interface UserInfo {
    orgId: number;
    email: string;
    userId: number;
}

const InternalRecharge = React.memo(function InternalRecharge() {
    const [chargeSubmitingState, setChargeSubmitingState] = useState<boolean>(false);
    const attachmentRef = useRef<any>([]);
    const [form] = Form.useForm();
    const [walletInfo, setWalletInfo] = useState<WalletInfo>();
    // const [pairsInfo, setPairsInfo] = useState<PairsInfo>();
    const [isHermite, setIsHermite] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        orgId: 0,
        email: '',
        userId: 0,
    });
    const [sku, setSku] = useState<{ skuName: string; skuId: number; count: number }[]>([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const platform = params.get('platform') || '';

    const chargeSubmit = () => {
        form.validateFields().then(() => {
            setChargeSubmitingState(true);
            const values = form.getFieldsValue();

            const data = new FormData();
            const { email, orgId, userId } = userInfo;
            data.append('emails', email);
            data.append('chargeSponsor', 'inside');
            data.append('orgId', `${orgId}`);
            data.append('userId', `${userId}`);
            data.append('resourceInfo', JSON.stringify(sku.map(item => ({ id: item.skuId, count: item.count }))));
            Object.keys(values).forEach(key => {
                if (values[key]) {
                    data.append(key, values[key]);
                }
            });
            const filesArr = attachmentRef.current.slice(0, 3);
            filesArr?.forEach((file: any) => {
                data.append('files', file, file.name);
            });
            charge(data).finally(() => {
                setChargeSubmitingState(false);
            });
        });
    };

    const getWalletInfo = (orgId: number) => {
        return fetchWalletInfo(orgId, 'inside').then(({ data }) => {
            setWalletInfo(data);
            return data;
        });
    };
    // const getPairsInfo = (orgId: number) => {
    //     fetchPairsInfo(orgId, 'inside').then(({ data }) => {
    //         setPairsInfo(data);
    //     });
    // };

    useEffect(() => {
        try {
            const isNewTest = window.location.href.indexOf('-new.test') !== -1;
            const isTest = window.location.href.indexOf('test') !== -1;
            const isUat = window.location.href.indexOf('uat') !== -1;
            const bohrTokenName = (() => {
                if (isNewTest) return 'test-new-brmToken';
                if (isTest) return 'test-brmToken';
                if (isUat) return 'uat-brmToken';
                return 'brmToken';
            })();
            const tokenName = platform === 'hermite' ? 'hrmToken' : bohrTokenName;
            setIsHermite(platform === 'hermite');
            const token = Cookies.get(tokenName)?.split('.')[1] || '';
            if (!token) {
                let hermiteUrl = 'https://hermite.dp.tech/';
                let bohriumUrl = 'https://bohrium.dp.tech/';
                if (isTest) {
                    hermiteUrl = 'https://hermite-beta.test.dp.tech/';
                    bohriumUrl = 'https://bohrium.test.dp.tech/';
                }
                if (isUat) {
                    hermiteUrl = 'https://hermite-beta.uat.dp.tech/';
                    bohriumUrl = 'https://bohrium.uat.dp.tech/';
                }
                message.info(`未登录${platform}, 请登录后回到本页面刷新后充值`);
                setTimeout(() => openNewWindow(platform === 'hermite' ? hermiteUrl : bohriumUrl), 2000);
            } else {
                const parseToken = JSON.parse(decode(token));
                const { orgId, user_id, userId } = parseToken.identity;
                // fetchOrgDetail(+orgId).then(rep => {
                //     setUserInfo({
                //         ...parseToken.identity,
                //         email: rep.data.user.email,
                //     });
                // });
                getWalletInfo(orgId).then(data => {
                    setUserInfo({
                        orgId,
                        userId: userId ?? user_id,
                        email: data.email,
                    });
                });

                if (platform === 'hermite') {
                    fetchGoods({
                        page: 1,
                        pageSize: 999,
                    }).then(rep => {
                        setSku(
                            rep.data.items.map((item: any) => ({
                                skuName: item.skuName,
                                skuId: item.skuId,
                                count: 0,
                            }))
                        );
                    });
                }
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            initialValues={{
                balanceType: BALANCE_TYPE.DISCOUNT_BALANCE,
                // chargeType: INTERNAL_CHARGE_TYPE_OPTIONS[0].value,
                remark: '',
                contractNum: '',
                contractName: '',
                amount: 0,
                orgSource: platform === 'hermite' ? 2 : 1,
                // pairs: 0,
            }}
            form={form}
            preserve={false}
            labelCol={{ span: 6 }}
            onFinish={chargeSubmit}
            style={{ width: '650px' }}
        >
            <Item wrapperCol={{ offset: 4, span: 8 }}>
                <h2>内部用户充值申请</h2>
            </Item>
            <Item label="Email">{userInfo.email}</Item>
            <Item wrapperCol={{ offset: 3, span: 18 }}>
                温馨提示：您当前账户余额：
                {(Number((walletInfo?.balance || 0) * 100) + Number((walletInfo?.couponBalance || 0) * 100)) / 100}
                元， 本月累计充值
                {Number(walletInfo?.currentMonthRecharge || 0) + Number(walletInfo?.currentMonthCouponRecharge || 0)}
                元， 累计消费
                {walletInfo?.currentMonthCost || 0}元。
                {/* 您当前pairs数余额{pairsInfo?.fepBalance || 0}个，本月累计充值 */}
                {/* {pairsInfo?.currentMonthPairsRecharge || 0}个，累计消费{pairsInfo?.currentMonthPairsCost || 0}个。 */}
            </Item>
            <Item name="balanceType" label="余额类型">
                <Radio.Group>
                    <Radio value={BALANCE_TYPE.DISCOUNT_BALANCE}>优惠充值余额</Radio>
                </Radio.Group>
            </Item>
            {/* <Item label="Email">{emailsRef.current.emails}</Item> */}
            <Item name="orgSource" label="产品线" rules={[{ required: true }]}>
                <Select options={ORG_SOURCE_OPTIONS} placeholder="请选择产品线" disabled />
            </Item>
            {/* <Item name="chargeType" label="充值类型">
                <Select options={INTERNAL_CHARGE_TYPE_OPTIONS} />
            </Item> */}
            <Item
                name="amount"
                label="充值金额"
                rules={[
                    { required: true, message: '请输入充值金额' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0 || getFieldValue('pairs')) {
                                return Promise.resolve();
                            }
                            if (value === 0 && getFieldValue('pairs') === 0) {
                                return Promise.reject(new Error('pair数和充值金额不能同时为0'));
                            }
                            return Promise.reject(new Error('充值金额请输入大于等于0的数值'));
                        },
                    }),
                ]}
            >
                <InputNumber min={0} style={{ width: '200px' }} />
            </Item>
            {/* {isHermite && ( */}
            {/*    <Item */}
            {/*        name="pairs" */}
            {/*        label="pairs数" */}
            {/*        rules={[ */}
            {/*            ({ getFieldValue }) => ({ */}
            {/*                validator(_, value) { */}
            {/*                    if (value > 0 || getFieldValue('amount')) { */}
            {/*                        return Promise.resolve(); */}
            {/*                    } */}
            {/*                    if (value === 0 && getFieldValue('amount') === 0) { */}
            {/*                        return Promise.reject(new Error('pair数和充值金额不能同时为0')); */}
            {/*                    } */}
            {/*                    return Promise.reject(new Error('充值pair数请输入大于等于0的数值')); */}
            {/*                }, */}
            {/*            }), */}
            {/*        ]} */}
            {/*    > */}
            {/*        <InputNumber min={0} style={{ width: '200px' }} precision={0} /> */}
            {/*        /!* <InputNumber min={0} style={{ width: '200px' }} disabled={!hasFepState} /> *!/ */}
            {/*    </Item> */}
            {/* )} */}
            {isHermite && sku.length
                ? sku.map((item, itemIndex) => (
                      <Item key={item.skuId} label={item.skuName}>
                          <InputNumber
                              min={0}
                              style={{ width: '200px' }}
                              precision={0}
                              value={item.count}
                              onChange={value => {
                                  const newSku = [...sku];
                                  newSku[itemIndex].count = value;
                                  setSku(newSku);
                              }}
                          />
                      </Item>
                  ))
                : null}
            <Item name="remark" label="备注">
                <TextArea maxLength={200} />
            </Item>
            <Item label="附件">
                <Upload
                    beforeUpload={file => {
                        attachmentRef.current.push(file);
                        return false;
                    }}
                    onRemove={file => {
                        attachmentRef.current = attachmentRef.current.filter((f: any) => f.uid !== file.uid);
                    }}
                    multiple
                    maxCount={3}
                >
                    <Button icon={<UploadOutlined />}>上传附件</Button>
                </Upload>
            </Item>
            <Item wrapperCol={{ offset: 4, span: 4 }}>
                <Button type="primary" htmlType="submit" loading={chargeSubmitingState}>
                    提交
                </Button>
            </Item>
        </Form>
    );
});

export default InternalRecharge;
