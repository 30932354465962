export const FINANCE_ADJUST_TYPE_OPTIONS = [
    {
        label: '误充值操作',
        value: 1,
    },
    {
        label: '业务变动',
        value: 2,
    },
    {
        label: '账户转移',
        value: 3,
    },
];

export const USER_SEE_OR_NOT = [
    {
        label: '是',
        value: 1,
    },
    {
        label: '否',
        value: 2,
    },
];
