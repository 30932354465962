import React, { useCallback, useMemo, useRef } from 'react';
import { Dropdown, Avatar, Menu, MenuProps, Modal } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
// import { joinClassName } from '@utils/util';
import { USER_DROPDOWN_MENU } from '@shared/model/enum/shared';
import { setToken, decodeToken } from '@utils/token';

const { confirm } = Modal;

type MenuInfo = Parameters<Required<MenuProps>['onClick']>[0];

export interface AdminUserInfoDropdownProps {
    children?: React.ReactChildren;
    // containerRef?: React.MutableRefObject<null>;
    itemClick?: (key: MenuInfo['key']) => void;
}

const AdminUerInfoDropdown: React.FC<AdminUserInfoDropdownProps> = React.memo(function AdminUerInfoDropdown({
    children,
    // containerRef,
}) {
    const userInfo = useMemo(() => decodeToken(), []);
    const containerRef = useRef(null);

    const signOut = () => {
        setToken('');
        window.location.href = '/login';
    };

    const handleDropdownMenu = useCallback(({ key }: MenuInfo) => {
        if (+key === USER_DROPDOWN_MENU.LOG_OUT) {
            confirm({
                content: '确认退出么？',
                onOk() {
                    signOut();
                },
                okText: '确认',
                cancelText: '取消',
                centered: true,
                okButtonProps: {
                    type: 'primary',
                    danger: true,
                },
            });
        }
    }, []);

    const USER_DROPDOWN = useMemo(
        () => (
            <Menu onClick={handleDropdownMenu}>
                <Menu.Item>{userInfo?.email}</Menu.Item>
                {children}
                <Menu.Item key={USER_DROPDOWN_MENU.LOG_OUT}>Log Out</Menu.Item>
            </Menu>
        ),
        [handleDropdownMenu, userInfo?.email, children]
    );
    return (
        <div ref={containerRef}>
            <Dropdown overlay={USER_DROPDOWN} getPopupContainer={() => containerRef?.current!} arrow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar icon={<UserOutlined />} />
                    <DownOutlined style={{ color: '#fff', marginLeft: '16.5px' }} />
                </div>
            </Dropdown>
        </div>
    );
});

export default AdminUerInfoDropdown;
