import { Form, Modal, ModalProps, Radio, Tag, message, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { getQuota } from '@services/api/license';
import { LicenseModal } from '@shared/components/biz/license/modal';

const { Item } = Form;

export const AuthModal = (props: ModalProps & { org: any; onSuccess: (data: any) => void }) => {
    const { org, onSuccess = () => {}, ...restProps } = props;
    // const [form] = Form.useForm();
    const [licenseVisible, setLicenseVisible] = useState(false);

    const [license, setLicense] = useState<any>();
    const [isFree, setIsFree] = useState<number | undefined>();
    const [buyUsedAmount, setBuyUsedAmount] = useState<number | undefined>();

    const reset = () => {
        setIsFree(undefined);
        setLicense(undefined);
        setBuyUsedAmount(undefined);
    };
    const [quota, setQuota] = useState<{ [key: string]: string }>({});
    useEffect(() => {
        getQuota().then(rep => {
            const q = {} as any;
            rep.data.forEach((item: any) => {
                q[item.keyName] = item.name;
            });
            setQuota(q);
        });
    }, []);

    return (
        <div>
            <Modal
                title="给组织授权"
                {...restProps}
                onCancel={e => {
                    reset();
                    if (restProps.onCancel) {
                        restProps.onCancel(e);
                    }
                }}
                okButtonProps={{
                    disabled: !license || isFree === undefined || !buyUsedAmount,
                }}
                onOk={() => {
                    if (!license) return message.error('请选择license');
                    if (isFree === undefined || !buyUsedAmount) return message.error('请填写模块内容');
                    onSuccess({
                        license,
                        buyUsedAmount,
                        isFree: !!isFree,
                    });
                    reset();
                }}
            >
                <div>你正在给{org.name}新增权限模块</div>
                <Form>
                    <Item required label="license">
                        {license ? (
                            <Tag closable onClose={() => setLicense(undefined)}>
                                {license.name}
                            </Tag>
                        ) : null}
                        <Tag className="site-tag-plus" onClick={() => setLicenseVisible(true)}>
                            选择 License
                        </Tag>
                    </Item>
                    <Item label="授权周期">
                        <InputNumber
                            value={buyUsedAmount}
                            onChange={value => setBuyUsedAmount(value)}
                            min={1}
                            step={1}
                        />
                        月
                    </Item>
                    <Item label="是否免费">
                        <Radio.Group value={isFree} onChange={e => setIsFree(e.target.value)}>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </Item>
                    {license
                        ? Object.keys(license.quota).map(key => (
                              <Item key={key} label={quota[key]}>
                                  {license.quota[key]}
                              </Item>
                          ))
                        : null}
                </Form>
            </Modal>
            <LicenseModal
                visible={licenseVisible}
                onCancel={() => setLicenseVisible(false)}
                onSubmit={data => {
                    setLicense(data);
                    setLicenseVisible(false);
                }}
            />
        </div>
    );
};
