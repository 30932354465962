export enum MarketingType {
    Normal, // 参加活动
    Free, // 赠品
    Combo = 3, // 套餐
}

export const MarketingTypeText = {
    [MarketingType.Normal]: '无',
    [MarketingType.Free]: '赠品',
    [MarketingType.Combo]: '套餐',
};
