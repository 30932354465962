export const OPERATION_OPTIONS = [
    {
        label: '机时费',
        value: '1',
    },
    {
        label: 'Pairs',
        value: '2',
    },
    {
        label: '创建用户',
        value: '4',
    },
    {
        label: '变更FEP权限',
        value: '3',
    },
    {
        label: '更新用户',
        value: '5',
    },
    {
        label: '操作记录',
        value: '6',
    },
];
