import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { fetchContractList } from '@services/api/contract';

// eslint-disable-next-line react/require-default-props
export const ContractSelector = (props: { value?: number; onChange?: (value: number, option: any) => void }) => {
    const { value = 0, onChange = () => {} } = props;
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        fetchContractList({ page: 1, pageSize: 999, contractStatus: [0, 2, 3, 4, 5, 6, 7, 8, 9] }).then(rep => {
            setOptions(
                rep.data.items.map((item: any) => ({
                    label: `${item.contract_number}(${item.contract_entity})(${item.contract_amount}元)`,
                    value: item.contract_number,
                }))
            );
        });
    }, []);
    return (
        <Select
            showSearch
            // @ts-ignore
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '')?.includes(input.toLowerCase())}
            options={options}
            value={value}
            onChange={value => {
                onChange(
                    value,
                    options.find(option => option.value === value)
                );
            }}
        />
    );
};
