import React from 'react';
import { RouteConfig } from 'react-router-config';
import { Navigate } from 'react-router-dom';
import Home from '@home/index';
import Users from '@user/index';
import Jobs from '@biz/jobs';
import Login from '@biz/login';
import OrgList from '@biz/org/org-list';
import OrgDetail from '@biz/org/org-detail';
import SubAccount from '@biz/org/sub-account';
import { UserGroup } from '@/biz';
import Logs from '@biz/log';
import InternalRecharge from '@biz/internal-recharge';
import { LicenseOrder } from '@biz/license/order';
import { TradeOrder } from '@biz/trade/order';
import { TradeComboList } from '@biz/trade/combo';
import FinanceIncome from '@biz/finance/income';
import FinanceRecharge from '@biz/finance/recharge';
import { BizEntity } from '@biz/biz-entity';
import { TechFix } from '@biz/tech/fix';
import { TechResourceGroup } from '@biz/tech/resource/group';
import { LicenseManagement } from '@biz/license/management';
import { TechGoodsCategory } from '@biz/tech/goods/category';
import { TechGoodsAttribute } from '@biz/tech/goods/attribute';
import { TechGoodsValue } from '@biz/tech/goods/value';
import { TradeSKULeFE } from '@biz/trade/sku';
import { FinanceAdjustLeFE } from '@biz/finance/finance-adjust';
import { RateManagement } from '@biz/tech/rate';
import { TechResourceAuth } from '@biz/tech/resource/auth';
import { ProductUpdate } from '@biz/bohrium';
import { BohriumProject } from '@biz/bohrium/project';
import { TechGoodsScene } from '@biz/tech/goods/scene';
import { ContractManagerLeFE } from '@biz/finance/contract/contract';
import { IncomeNewManagerLeFE } from '@biz/finance/income-new/income-new';
import { InvoiceList } from '@biz/finance/invoice';
import { SmsManagerLeFE } from '@biz/tech/sms';
import { LbgJobLeFE } from '@biz/lbg';
import { LbgWaterlineLeFE } from '@biz/lbg/stock';
import { LbgStrategyLeFE } from '@biz/lbg/strategy';
import { LbgRuleLeFE } from '@biz/lbg/rule';

const getDefaultRedirect = () => ({
    path: '*',
    component: () => <Navigate to="/home" />,
});

const baseRoutes = [
    { path: '/login', menuTitle: 'login', component: Login },
    { path: '/home', menuTitle: 'home', component: Home, layout: 'main' },
    { path: '/users', menuTitle: 'users', component: Users, layout: 'main' },
    { path: '/jobs', menuTitle: 'jobs', component: Jobs, layout: 'main' },
    { path: '/userGroup', menuTitle: 'userGroup', component: UserGroup, layout: 'main' },
    { path: '/orgList', menuTitle: 'orgList', component: OrgList, layout: 'main' },
    { path: '/orgList/detail/:id', menuTitle: 'orgDetail', component: OrgDetail, layout: 'main' },
    { path: '/subAccount', menuTitle: 'subAccount', component: SubAccount, layout: 'main' },
    { path: '/logs', menuTitle: 'logs', component: Logs, layout: 'main', auth: true },
    { path: '/internal-recharge', menuTitle: 'internal-recharge', component: InternalRecharge },
    { path: '/license/orders', menuTitle: 'licenseOrders', layout: 'main', component: LicenseOrder },
    { path: '/license/management', menuTitle: 'licenseManagement', layout: 'main', component: LicenseManagement },
    { path: '/trade/order', menuTitle: 'trade-order', layout: 'main', component: TradeOrder },
    { path: '/trade/combo', menuTitle: 'trade-combo', layout: 'main', component: TradeComboList },
    { path: '/trade/sku', menuTitle: 'trade-sku', layout: 'main', component: TradeSKULeFE },
    { path: '/finance/income', menuTitle: 'finance-income', layout: 'main', component: FinanceIncome },
    { path: '/finance/income-new', menuTitle: 'finance-income-new', layout: 'main', component: IncomeNewManagerLeFE },
    { path: '/finance/recharge', menuTitle: 'finance-recharge', layout: 'main', component: FinanceRecharge },
    { path: '/finance/adjust', menuTitle: 'finance-adjust', layout: 'main', component: FinanceAdjustLeFE },
    { path: '/bizEntity', menuTitle: 'biz-entity', layout: 'main', component: BizEntity },
    { path: '/tech/fix', menuTitle: 'tech-fix', layout: 'main', component: TechFix },
    { path: '/tech/sms', menuTitle: 'tech-sms', layout: 'main', component: SmsManagerLeFE },
    { path: '/tech/resource/auth', menuTitle: 'tech-resource-auth', layout: 'main', component: TechResourceAuth },
    { path: '/tech/resource/group', menuTitle: 'tech-resource-group', layout: 'main', component: TechResourceGroup },
    { path: '/tech/goods/category', menuTitle: 'tech-goods-category', layout: 'main', component: TechGoodsCategory },
    { path: '/tech/goods/attribute', menuTitle: 'tech-goods-attribute', layout: 'main', component: TechGoodsAttribute },
    { path: '/tech/goods/value', menuTitle: 'tech-goods-value', layout: 'main', component: TechGoodsValue },
    { path: '/tech/goods/scene', menuTitle: 'tech-goods-scene', layout: 'main', component: TechGoodsScene },
    { path: '/tech/rate', menuTitle: 'tech-rate', layout: 'main', component: RateManagement },
    { path: '/bohrium/product', menuTitle: 'bohrium-product', layout: 'main', component: ProductUpdate },
    { path: '/bohrium/project', menuTitle: 'bohrium-project', layout: 'main', component: BohriumProject },
    { path: '/finance/contract', menuTitle: 'finance-contract', layout: 'main', component: ContractManagerLeFE },
    { path: '/finance/invoice', menuTitle: 'finance-invoice', layout: 'main', component: InvoiceList },
    { path: '/lbg/job_query', menuTitle: 'lbg-job', layout: 'main', component: LbgJobLeFE },
    { path: '/lbg/waterline', menuTitle: 'lbg-job', layout: 'main', component: LbgWaterlineLeFE },
    { path: '/lbg/rule', menuTitle: 'lbg-job', layout: 'main', component: LbgRuleLeFE },
    { path: '/lbg/strategy', menuTitle: 'lbg-job', layout: 'main', component: LbgStrategyLeFE },
] as RouteConfig[];

export default [...baseRoutes, getDefaultRedirect()];
