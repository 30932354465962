const ROUTER_AUTH = [
    {
        key: 'user',
        title: '用户管理',
        children: [
            {
                key: 'orgList',
                title: '组织列表',
                url: '/orgList',
                children: [],
            },
            {
                key: 'subAccount',
                title: '账号列表',
                url: '/subAccount',
                children: [],
            },
            // {
            //     key: 'bizEntity',
            //     title: '客户单位主体列表',
            //     url: '/bizEntity',
            //     children: [],
            // },
        ],
    },
    {
        key: 'trade',
        title: '交易管理',
        children: [
            {
                key: 'order',
                title: '订单列表',
                url: '/trade/order',
                children: [],
            },
            {
                key: 'combo',
                title: '套餐管理',
                url: '/trade/combo',
                children: [],
            },
            {
                key: 'sku',
                title: 'SKU管理',
                url: '/trade/sku',
                children: [],
            },
        ],
    },
    {
        key: 'finance',
        title: '财务管理',
        children: [
            {
                key: 'income',
                title: '来款管理',
                url: '/finance/income',
                children: [],
            },
            {
                key: 'income-new',
                title: '来款管理(新)',
                url: '/finance/income-new',
                children: [],
            },
            {
                key: 'recharge',
                title: '充值记录',
                url: '/finance/recharge',
                children: [],
            },
            {
                key: 'adjust',
                title: '转调账记录',
                url: '/finance/adjust',
                children: [],
            },
            {
                key: 'contract',
                title: '合同管理',
                url: '/finance/contract',
                children: [],
            },
            {
                key: 'invoice',
                title: '发票管理',
                url: '/finance/invoice',
                children: [],
            },
        ],
    },
    // {
    //     key: 'job',
    //     title: '任务管理',
    //     children: [
    //         {
    //             key: 'jobs',
    //             title: '任务列表',
    //             url: '/jobs',
    //             children: [],
    //         },
    //     ],
    // },
    {
        key: 'license',
        title: '权限管理',
        children: [
            {
                key: 'management',
                title: 'license管理',
                url: '/license/management',
                children: [],
            },
            {
                key: 'orders',
                title: '授权管理',
                url: '/license/orders',
                children: [],
            },
        ],
    },
    {
        key: 'bohrium',
        title: 'Bohrium',
        children: [
            {
                key: 'productUpdates',
                title: '产品动态',
                url: '/bohrium/product',
                children: [],
            },
            {
                key: 'project',
                title: '项目列表',
                url: '/bohrium/project',
                children: [],
            },
        ],
    },
    {
        key: 'log',
        title: '日志',
        children: [
            {
                key: 'logs',
                title: '日志列表',
                url: '/logs',
                children: [],
            },
        ],
    },
    {
        key: 'tech',
        title: '系统管理',
        children: [
            {
                key: 'resource',
                title: '资源管理',
                children: [
                    {
                        key: 'auth',
                        title: '权限管理',
                        url: '/tech/resource/auth',
                        children: [],
                    },
                    {
                        key: 'group',
                        title: '权限组管理',
                        url: '/tech/resource/group',
                        children: [],
                    },
                ],
            },
            {
                key: 'goods',
                title: '商品管理',
                children: [
                    {
                        key: 'category',
                        title: '类目管理',
                        url: '/tech/goods/category',
                        children: [],
                    },
                    {
                        key: 'attribute',
                        title: '属性管理',
                        url: '/tech/goods/attribute',
                        children: [],
                    },
                    {
                        key: 'value',
                        title: '属性值管理',
                        url: '/tech/goods/value',
                        children: [],
                    },
                    {
                        key: 'scene',
                        title: '场景管理',
                        url: '/tech/goods/scene',
                        children: [],
                    },
                ],
            },
            {
                key: 'rate',
                title: '汇率配置',
                url: '/tech/rate',
                children: [],
            },
            {
                key: 'fix',
                title: '技术运维',
                url: '/tech/fix',
                children: [],
            },
            {
                key: 'sms',
                title: '邮件发送',
                url: '/tech/sms',
                children: [],
            },
        ],
    },
    // LBG运营管理
    {
        key: 'lbg',
        title: 'LBG运营管理',
        children: [
            {
                key: 'job',
                title: 'job查询',
                url: '/lbg/job_query',
                children: [],
            },
            {
                key: 'rule',
                title: '规则管理',
                url: '/lbg/rule',
                children: [],
            },
            {
                key: 'strategy',
                title: '策略管理',
                url: '/lbg/strategy',
                children: [],
            },
            {
                key: 'waterline',
                title: '水位线管理',
                url: '/lbg/waterline',
                children: [],
            },
        ],
    },
];

export default ROUTER_AUTH;
