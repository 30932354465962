import { Form, Modal, ModalProps, Table, Input, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { associateIncome, fetchIncomeList, fetchLastRechargeAccount } from '@/services/api/bank';
import { IncomeRecordData, IncomeRecordParams } from '@/shared/model/type/finance/income-record.type';
import { RechargeListData } from '@/shared/model/type/finance/recharge-list.type';
import IncomeRecordForm from './components/form';

interface incomeRecordModalProps extends ModalProps {
    multiSelect: boolean;
    currData: Partial<RechargeListData> | undefined;
    onCancel: () => void;
    onSubmit: (data?: any) => void;
}

interface IncomeRecordPlus extends IncomeRecordData {
    relevanceAmount: string;
}

export const IncomeRecordModal = (props: incomeRecordModalProps) => {
    const { visible, title, onCancel, multiSelect, currData, onSubmit } = props;
    const [dataSourceState, setDataSourceState] = useState<IncomeRecordData[]>([]);
    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 30, total: 0 });
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [selectedRowKeysState, setSelectedRowKeysState] = useState<any[]>([]);
    const [selectedDataState, setSelectedDataState] = useState<IncomeRecordPlus[]>([]);
    const [userNameState, setUserNameState] = useState<string>('');
    const [innerVisibleState, setInnerVisibleState] = useState<boolean>(false);

    const obtainData = async (params: IncomeRecordParams) => {
        try {
            setLoadingState(true);
            const rep = await fetchIncomeList({ ...params });
            if (!rep || rep.code) {
                message.error(rep?.error?.msg ?? '服务器错误');
                return setLoadingState(false);
            }
            setPaginationState({
                current: rep.data.page || paginationState.current,
                pageSize: params.pageSize || paginationState.pageSize,
                total: rep.data.total,
            });
            setDataSourceState(rep.data.items);
            setLoadingState(false);
            setSelectedRowKeysState([]);
        } catch (e) {
            setLoadingState(false);
            setDataSourceState([]);
            setSelectedRowKeysState([]);
        }
    };

    const handleInnerOk = () => {
        if (
            (currData?.unsettledAmount ?? 0) <
            selectedDataState.reduce((total, item) => {
                total += +item.relevanceAmount;
                return total;
            }, 0)
        ) {
            return message.error('认领金额不能大于未回款金额');
        }
        associateIncome(
            +currData?.id!,
            selectedDataState.map(row => ({
                bankBillId: row.id,
                relevanceAmount: +row.relevanceAmount,
            }))
        ).then(rep => {
            if (!rep.code) {
                message.success('操作成功');
                setInnerVisibleState(false);
                onSubmit();
                setSelectedDataState([]);
                setSelectedRowKeysState([]);
            } else {
                message.error(rep?.error?.msg ?? '服务器错误');
            }
        });
    };

    useEffect(() => {
        if (visible) {
            fetchLastRechargeAccount(+currData?.orgId!).then(rep => {
                setUserNameState(rep?.data?.paymentAccountName);
                obtainData({
                    paymentAccountName: rep?.data?.paymentAccountName,
                    page: paginationState.current,
                    pageSize: paginationState.pageSize,
                });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    return (
        <div>
            <Modal
                visible={visible}
                title={title}
                onOk={() => {
                    if (multiSelect) {
                        setInnerVisibleState(true);
                    } else {
                        onSubmit(selectedDataState);
                    }
                }}
                onCancel={() => {
                    onCancel();
                    setSelectedDataState([]);
                    setSelectedRowKeysState([]);
                }}
                width={1200}
                destroyOnClose
            >
                <IncomeRecordForm
                    onSubmit={(values: any) => {
                        obtainData({ ...values, page: 1, pageSize: paginationState.pageSize });
                    }}
                    userName={userNameState}
                />
                <Table
                    columns={[
                        // {
                        //     title: '序号',
                        //     key: '',
                        //     dataIndex: '',
                        //     render: (value: any, row: any, index: number) => index + 1,
                        // },
                        {
                            title: '来款ID',
                            dataIndex: 'id',
                            width: 80,
                        },
                        {
                            title: '对方户名',
                            dataIndex: 'paymentAccountName',
                        },
                        {
                            title: '对方账号',
                            dataIndex: 'paymentAccountNo',
                        },
                        {
                            title: '来款金额',
                            dataIndex: 'amount',
                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                        },
                        {
                            title: '流水号',
                            dataIndex: 'bankNo',
                            width: 120,
                        },
                        {
                            title: '已认领金额',
                            dataIndex: 'claimedAmount',
                            width: 120,
                        },
                        {
                            title: '未认领金额',
                            dataIndex: 'unrechargeAmount',
                            width: 120,
                        },
                    ]}
                    dataSource={dataSourceState}
                    rowKey={item => item.id}
                    scroll={{ x: true }}
                    loading={loadingState}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeysState,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeysState(selectedRowKeys);
                            setSelectedDataState(
                                selectedRows.map(row => ({
                                    ...row,
                                    relevanceAmount: Math.min(
                                        +currData?.unsettledAmount!,
                                        +row?.unrechargeAmount!
                                    ).toString(),
                                }))
                            );
                        },
                        type: multiSelect ? 'checkbox' : 'radio',
                    }}
                    bordered
                    pagination={{
                        ...paginationState,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: total => `共 ${total} 条结果`,
                        onChange: (current, pageSize) => {
                            obtainData({ ...paginationState, page: current, pageSize });
                        },
                    }}
                />
            </Modal>
            <Modal
                visible={innerVisibleState}
                title={'充值来款结算'}
                onOk={handleInnerOk}
                onCancel={() => {
                    setInnerVisibleState(false);
                }}
                width={1100}
                destroyOnClose
            >
                <Form>
                    <Form.Item hidden={!multiSelect} label="充值ID">
                        {currData?.id}
                    </Form.Item>
                    <Form.Item hidden={!multiSelect} label="充值金额">
                        {currData?.rechargeAmount}
                    </Form.Item>
                    <Form.Item hidden={!multiSelect} label="未回款金额">
                        {currData?.unsettledAmount}
                    </Form.Item>
                </Form>
                <Table
                    columns={[
                        {
                            title: '来款ID',
                            dataIndex: 'id',
                            width: 80,
                        },
                        {
                            title: '对方户名',
                            dataIndex: 'paymentAccountName',
                        },
                        {
                            title: '对方账号',
                            dataIndex: 'paymentAccountNo',
                        },
                        {
                            title: '来款金额',
                            dataIndex: 'amount',
                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                        },
                        {
                            title: '流水号',
                            dataIndex: 'bankNo',
                            width: 120,
                        },
                        {
                            title: '已认领金额',
                            dataIndex: 'rechargeAmount',
                            width: 120,
                        },
                        {
                            title: '未认领金额',
                            dataIndex: 'unrechargeAmount',
                            width: 120,
                        },
                        {
                            title: '本次认领金额',
                            width: 120,
                            fixed: 'right',
                            render: (text: string, record: IncomeRecordPlus, index) => {
                                return (
                                    <Input
                                        type="number"
                                        onChange={e => {
                                            if (e.target.value.split('.')?.[1]?.length > 2) {
                                                return message.error('认领金额最小单位为分');
                                            }
                                            if (+e.target.value < 0) {
                                                return message.error('认领金额需要大于0');
                                            }
                                            selectedDataState[index].relevanceAmount = e.target.value;
                                            setSelectedDataState([...selectedDataState]);
                                        }}
                                        value={record.relevanceAmount}
                                    />
                                );
                            },
                        },
                    ]}
                    dataSource={selectedDataState}
                    rowKey={item => item.id}
                    scroll={{ x: true }}
                    bordered
                />
            </Modal>
        </div>
    );
};
