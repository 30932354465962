import { useState } from 'react';

function useList<T>(
    initialList: Array<T>
): [
    Array<T>,
    React.Dispatch<React.SetStateAction<Array<T>>>,
    number,
    React.Dispatch<React.SetStateAction<number>>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
] {
    const [listState, setListState] = useState<Array<T>>(initialList);
    const [totalState, setTotalState] = useState<number>(0);
    const [loadingState, setLoadingState] = useState<boolean>(false);

    return [listState, setListState, totalState, setTotalState, loadingState, setLoadingState];
}

export default useList;
