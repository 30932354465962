export function joinClassName(...classNames: string[]): string {
    if (classNames.length === 1) {
        return classNames[0] ?? '';
    }
    return classNames
        .filter(className => !!className)
        .reduce((total: Array<string>, cur = '') => {
            return total.concat([cur]);
        }, [])
        .join(' ');
}

export function addPrefixZero(text: number) {
    return text < 10 ? `0${text}` : `${text}`;
}

// export function dateFormat(time: number, format = 'YYYY-MM-DD hh:mm:ss') {
//     const date = new Date(time * 1000);
//     const year = date.getFullYear();
//     const month = addPrefixZero(date.getMonth() + 1);
//     const day = addPrefixZero(date.getDate());
//     const hour = addPrefixZero(date.getHours());
//     const minutes = addPrefixZero(date.getMinutes());
//     const seconds = addPrefixZero(date.getSeconds());

//     return format
//         .replace(/YYYY/i, `${year}`)
//         .replace(/MM/i, `${month}`)
//         .replace(/DD/i, `${day}`)
//         .replace(/HH/i, `${hour}`)
//         .replace(/mm/i, `${minutes}`)
//         .replace(/ss/i, `${seconds}`);
// }

// export function parseBucket(oss_url: string) {
//     const bucketInfo = oss_url.split('.');
//     const bucket = bucketInfo[0].replace(/http(s)?:\/\//, '');
//     const path = bucketInfo[3].replace('com/', '');
//     return {
//         region: bucketInfo[1],
//         bucket,
//         path,
//     };
// }

// export const formatProgress = (n: number | undefined) => {
//     if (!n) return 0;
//     return (n * 100).toFixed(2).toString();
// };

export function downloadFileFromBlob(data: Blob | string, type: string, fileName?: string) {
    const fileBlob = new Blob([data], {
        type: type || 'text/plain',
    });
    const fileURL = window.URL.createObjectURL(fileBlob);
    const eleLink = document.createElement('a');
    eleLink.download = fileName || 'file';
    eleLink.style.display = 'none';
    eleLink.href = fileURL;
    document.body.appendChild(eleLink);
    eleLink.click();
    window.URL.revokeObjectURL(fileURL);
    document.body.removeChild(eleLink);
}

export function openNewWindow(url: string) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
}

export const guid = () => {
    function S4() {
        // eslint-disable-next-line no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const getDomain = () => {
    if (window.location.origin.includes('test')) return '.test.atombeat.com';
    if (window.location.origin.includes('uat')) return '.uat.atombeat.com';
    if (window.location.origin.includes('local')) return '.local.atombeat.com';
    return '.atombeat.com';
};

export const getLeFEOrigin = () => {
    if (window.location.origin.includes('test')) return 'https://lefe.test.atombeat.com';
    if (window.location.origin.includes('uat')) return 'https://lefe.uat.atombeat.com';
    if (window.location.origin.includes('local')) return 'http://local.atombeat.com:9002';
    return 'https://lefe.atombeat.com';
};
