import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { fetchResourceGroups, ResourceStatus } from '@services/api/resource';

export const ResourceGroupSelector = (props: { value: number; onChange: (value: number, option: any) => void }) => {
    const { value, onChange } = props;
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        fetchResourceGroups({ page: 1, pageSize: 999, status: ResourceStatus.Normal }).then(rep => {
            setOptions(
                rep.data.items.map((item: any) => ({
                    label: `${item.name}(id: ${item.id})`,
                    value: item.id,
                }))
            );
        });
    }, []);
    return (
        <Select
            showSearch
            // @ts-ignore
            filterOption={(input, option) => (option?.label.toLocaleString() ?? '')?.includes(input.toLowerCase())}
            options={options}
            value={value}
            onChange={value => {
                onChange(
                    value,
                    options.find(option => option.value === value)
                );
            }}
        />
    );
};
