import { Button, DatePicker, Form, Input, message, Modal, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    enableLicense,
    fetchLicenseOrders,
    getQuota,
    LicenseOrderStatus,
    LicenseOrderStatusMsg,
    LicensePriceCycleMsg,
} from '@services/api/license';

const { Item } = Form;

export const LicenseOrder = () => {
    const [data, setData] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [form] = Form.useForm();
    const [quota, setQuota] = useState<{ [key: string]: string }>({});
    const [startTime, setStartTime] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>();
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        return fetchLicenseOrders({ ...params, offset: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items || []);
            setTotal(rep.data.total);
            setPage(newPage ?? page);
            setPageSize(newPageSize ?? pageSize);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        getQuota().then(rep => {
            const q = {} as any;
            rep.data.forEach((item: any) => {
                q[item.keyName] = item.name;
            });
            setQuota(q);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Form form={form} layout="inline">
                <Item label="当前有效" name="status">
                    <Select
                        allowClear
                        options={[
                            {
                                label: '未生效',
                                value: LicenseOrderStatus.unActive,
                            },
                            {
                                label: '有效',
                                value: LicenseOrderStatus.active,
                            },
                            {
                                label: '已过期',
                                value: LicenseOrderStatus.expired,
                            },
                        ]}
                        style={{ width: 200 }}
                    />
                </Item>
                <Item label="组织ID" name="orgId">
                    <Input placeholder="请输入组织ID" />
                </Item>
                <Item label="组织名称" name="orgName">
                    <Input placeholder="请输入组织名称" />
                </Item>
                <Item>
                    <Button onClick={() => {}}>重置</Button>
                    <Button
                        type="primary"
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            refresh(1, pageSize);
                        }}
                    >
                        查询
                    </Button>
                </Item>
            </Form>
            <Table
                style={{ marginTop: 12 }}
                dataSource={data}
                pagination={{
                    defaultPageSize: pageSize,
                    current: page,
                    showSizeChanger: true,
                    onChange: (newPage: number, newPageSize?: number) => {
                        refresh(newPage, newPageSize);
                    },
                    total,
                }}
                columns={[
                    {
                        title: '序号',
                        dataIndex: 'id',
                    },
                    {
                        title: '订单ID',
                        dataIndex: 'orderId',
                    },
                    {
                        title: '组织ID',
                        dataIndex: 'orgId',
                    },
                    {
                        title: '组织名称/全程',
                        dataIndex: 'orgName',
                    },
                    {
                        title: 'license名称',
                        dataIndex: 'licenseName',
                    },
                    {
                        title: '授权时长',
                        dataIndex: 'buyUsedAmount',
                        render: (value, row) => (
                            <span>
                                {value}({LicensePriceCycleMsg[row.priceCycle] ?? '--'})
                            </span>
                        ),
                    },
                    {
                        title: '启用时间',
                        dataIndex: 'startTime',
                        render: value => (value ? <span>{moment(value).format('YYYY-MM-DD')}</span> : '--'),
                    },
                    {
                        title: '是否免费',
                        dataIndex: 'isFree',
                        render: value => (value ? '是' : '否'),
                    },
                    // {
                    //     title: '截止日期',
                    // },
                    // {
                    //     title: '失效日期',
                    // },
                    // {
                    //     title: '失效方式',
                    // },
                    {
                        title: '配额信息',
                        dataIndex: 'quota',
                        render: value => (
                            <>
                                {value
                                    ? Object.keys(value).map(key => (
                                          <Row key={key}>
                                              {quota[key]}:{value[key]}
                                          </Row>
                                      ))
                                    : null}
                            </>
                        ),
                    },
                    {
                        title: '有效期至',
                        dataIndex: 'expireTime',
                        render: value => (value ? <span>{moment(value).format('YYYY-MM-DD')}</span> : '--'),
                    },
                    {
                        title: '当前有效',
                        dataIndex: 'status',
                        render: value => <span>{LicenseOrderStatusMsg[value]}</span>,
                    },
                    {
                        title: '操作',
                        dataIndex: 'status',
                        render: (value, row) => (
                            <>
                                {value === LicenseOrderStatus.unActive ? (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setDetail(row);
                                            setVisible(true);
                                        }}
                                    >
                                        启用
                                    </Button>
                                ) : null}
                                {/* <Button type="primary" danger> */}
                                {/*    失效 */}
                                {/* </Button> */}
                            </>
                        ),
                    },
                ]}
            />
            <Modal
                visible={visible}
                title="请选择生效时间"
                onOk={() => {
                    enableLicense(detail.id, startTime.startOf('day').toDate()).then(rep => {
                        if (!rep.code) {
                            message.success('启动成功');
                            setVisible(false);
                            refresh(page, pageSize);
                        } else {
                            message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                        }
                    });
                }}
                okButtonProps={{
                    disabled: !startTime,
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DatePicker
                    disabledDate={date => date.diff(moment().startOf('day')) < 0}
                    value={startTime}
                    onChange={value => {
                        setStartTime(value);
                    }}
                />
            </Modal>
        </div>
    );
};
