import React from 'react';
import { Layout } from 'antd';
import CommonHeader from '@shared/components/header';
import Sider from '@shared/components/sider';
// import BreadcrumbWrapper from '@shared/components/breadcrumb';
// import pageDefaultLoading from '@shared/components/loading/page-loading';
import routeConfig from '@shared/config/routerAuth';

const { Content } = Layout;

export interface MainLayoutProps {
    children: React.ReactElement;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <>
            <CommonHeader />
            <Layout className="gordon-layout">
                <Sider routeAuthConfig={routeConfig} />
                <Content className="gordon-main-container" style={{ overflow: 'scroll' }}>
                    {/* <BreadcrumbWrapper /> */}
                    {children}
                </Content>
            </Layout>
        </>
    );
};

export default MainLayout;
