import { message } from 'antd';
import React, { useEffect } from 'react';
// import { Route } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';
import AdminLoginComponent from '@shared/components/login';
// import AdRegisterComponent from '@shared/components/register-forget';
// import LOGIN_COMPONENT_CODE from '@shared/model/enum/login-modal-content.enum';
import styles from './index.module.css';

const AdminLogin: React.FC<{}> = function AdminLogin() {
    // const [modalContentState, setModalContentState] = useState<LOGIN_COMPONENT_CODE>(LOGIN_COMPONENT_CODE.LOGIN);
    // const switchModalContent = (type = LOGIN_COMPONENT_CODE.REGISTER) => {
    //     setModalContentState(type);
    // };
    // const renderModalContent = React.useCallback(() => {
    //     if (modalContentState === LOGIN_COMPONENT_CODE.LOGIN) {
    //         return <AdminLoginComponent onSwitchModalContent={switchModalContent} />;
    //     }
    // if (modalContentState === LOGIN_COMPONENT_CODE.REGISTER || modalContentState === LOGIN_COMPONENT_CODE.FORGET) {
    //     return (
    //         <HrmRegisterComponent
    //             isRegister={modalContentState === LOGIN_COMPONENT_CODE.REGISTER}
    //             onSwitchModalContent={switchModalContent}
    //         />
    //     );
    // }
    // }, [modalContentState]);

    useEffect(() => {
        message.config({
            top: 8,
        });
    }, []);

    return (
        <div className={styles['login-page']}>
            {/* <div className={styles['login-container']}> */}
            <div className={styles['left-container']}>
                <div className={styles['text-container']}>
                    {/* <div className={styles['logo-container']}>
                        <img
                            src="https://cdn.dp.tech/hermite/img/logo.png"
                            className={styles['logo-img']}
                            alt="DP Technology"
                        />
                    </div> */}
                    <h1 className={styles.slogan}>分子模拟未来</h1>
                    {/* <h3 className={styles.title}>管理平台</h3> */}
                    <p className={styles.desc}>
                        深势科技运用人工智能和分子模拟算法，结合先进计算手段求解重要科学问题，为人类文明最基础的生物医药、能源、材料和信息科学与工程研究打造新一代微尺度工业设计和仿真平台。
                    </p>
                    <div className={styles.rectangle} />
                </div>
                <img
                    src="https://cdn.dp.tech/hermite/img/login-bgx2_v2.png"
                    className={styles['background-img']}
                    alt="Hermite"
                />
            </div>
            <div className={styles['right-container']}>
                <AdminLoginComponent />
            </div>
            {/* </div> */}
            <div className={styles.footer}>
                <span className={styles['footer-item']}>Copyright © 2021 DPTechnology</span>
                <span className={styles['footer-item']}>京ICP备20010051号-7</span>
            </div>
        </div>
    );
};

export default AdminLogin;
