import { Button, Form, Input, message, Modal, ModalProps, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { ENTITY_TYPE_OPTIONS, getBizEntityDetail } from '@services/api/biz-entity';
import { guid } from '@utils/util';

const { Item } = Form;
export const BizEntityModal = (
    props: ModalProps & {
        // eslint-disable-next-line react/require-default-props
        entityId?: number;
        // eslint-disable-next-line react/require-default-props
        org?: { id: number; name: string; email: string };
        editable: boolean;
        // eslint-disable-next-line react/require-default-props
        onSubmit?: (data: any) => void;
    }
) => {
    const [form] = Form.useForm();
    const { entityId, org, visible, editable, onSubmit = () => {}, ...restProps } = props;
    // const [entity, setEntity] = useState<any>({});
    const [paymentAccount, setPaymentAccount] = useState<any[]>([]);
    useEffect(() => {
        if (visible && entityId) {
            getBizEntityDetail({ id: entityId }).then(rep => {
                // setEntity(rep.data);
                form.setFieldsValue(rep.data);
                setPaymentAccount(rep.data.paymentAccount ?? []);
            });
        }
    }, [visible, entityId, form]);
    return (
        <Modal
            width={800}
            visible={visible}
            title="组织实名认证信息"
            onOk={() => {
                if (editable) {
                    const data = form.getFieldsValue();
                    if (!data.certNo || !data.entityType || !data.name) {
                        return message.error('请填写完整内容');
                    }
                    onSubmit({
                        ...form.getFieldsValue(),
                        paymentAccount,
                    });
                } else {
                    onSubmit({});
                }
            }}
            {...restProps}
        >
            {org ? (
                <Form labelCol={{ span: 6 }}>
                    <Item label="组织ID">{org.id}</Item>
                    <Item label="组织全称">{org.name}</Item>
                    <Item label="管理员邮箱">{org.email}</Item>
                </Form>
            ) : null}
            <Form form={form} labelCol={{ span: 6 }}>
                <Item label="信用代码/税号/身份证号" name="certNo" required>
                    <Input disabled={!editable} />
                </Item>
                <Item label="类型" name="entityType" required>
                    <Select disabled={!editable} options={ENTITY_TYPE_OPTIONS} />
                </Item>
                <Item label="单位名称/姓名" name="name" required>
                    <Input disabled={!editable} />
                </Item>
                <Item label="法人姓名代表" name="legalRepresentative">
                    <Input disabled={!editable} />
                </Item>
                <Item label="地址" name="address">
                    <Input disabled={!editable} />
                </Item>
                <Item label="电话" name="contactPhone">
                    <Input disabled={!editable} />
                </Item>
            </Form>
            <Row justify="space-between" align="middle">
                <span>银行账户信息</span>
                {editable ? (
                    <Button
                        type="primary"
                        onClick={() => {
                            setPaymentAccount(prev => prev.concat({ id: guid() }));
                        }}
                    >
                        添加银行账户
                    </Button>
                ) : null}
            </Row>
            <Table
                rowKey="id"
                dataSource={paymentAccount}
                columns={[
                    {
                        title: '户名',
                        dataIndex: 'accountName',
                        render: (value, row, index) =>
                            editable ? (
                                <Input
                                    value={value}
                                    onChange={e => {
                                        paymentAccount[index].accountName = e.target.value;
                                        setPaymentAccount([...paymentAccount]);
                                    }}
                                />
                            ) : (
                                value
                            ),
                    },
                    {
                        title: '银行',
                        dataIndex: 'bankName',
                        render: (value, row, index) =>
                            editable ? (
                                <Input
                                    value={value}
                                    onChange={e => {
                                        paymentAccount[index].bankName = e.target.value;
                                        setPaymentAccount([...paymentAccount]);
                                    }}
                                />
                            ) : (
                                value
                            ),
                    },
                    {
                        title: '开户行',
                        dataIndex: 'openingBank',
                        render: (value, row, index) =>
                            editable ? (
                                <Input
                                    value={value}
                                    onChange={e => {
                                        paymentAccount[index].openingBank = e.target.value;
                                        setPaymentAccount([...paymentAccount]);
                                    }}
                                />
                            ) : (
                                value
                            ),
                    },
                    {
                        title: '账号',
                        dataIndex: 'accountNo',
                        render: (value, row, index) =>
                            editable ? (
                                <Input
                                    value={value}
                                    onChange={e => {
                                        paymentAccount[index].accountNo = e.target.value;
                                        setPaymentAccount([...paymentAccount]);
                                    }}
                                />
                            ) : (
                                value
                            ),
                    },
                    {
                        title: '操作',
                        render: (value, row, index) =>
                            editable ? (
                                <Button
                                    onClick={() => {
                                        paymentAccount.splice(index, 1);
                                        setPaymentAccount([...paymentAccount]);
                                    }}
                                >
                                    删除
                                </Button>
                            ) : null,
                    },
                ]}
            />
        </Modal>
    );
};
