import { Button, Form, Input, Modal, ModalProps, Select, SelectProps, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { fetchAttributeValueList, fetchGoodAttributeList, GOODS_STATUS } from '@services/api/goods';

const { Item } = Form;
export const AttributeSelector = (props: SelectProps<any>) => {
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        fetchGoodAttributeList().then(rep => {
            setOptions(
                rep.data.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }))
            );
        });
    }, []);
    return (
        <Select
            showSearch
            // @ts-ignore
            filterOption={(input, option) => (option?.label.toLocaleString() ?? '')?.includes(input.toLowerCase())}
            options={options}
            {...props}
        />
    );
};

// eslint-disable-next-line react/require-default-props
export const AttributeValueSelector = (props: SelectProps<any> & { attrId: number; allowEmpty?: boolean }) => {
    const { attrId, allowEmpty = false, ...restProps } = props;
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        fetchAttributeValueList({
            attributeId: attrId,
            page: 1,
            pageSize: 999,
        }).then(rep => {
            setOptions(
                (allowEmpty ? [{ label: '空', value: 0 }] : []).concat(
                    rep.data.items
                        .filter((item: any) => item.status === GOODS_STATUS.Normal)
                        .map((item: any) => ({
                            label: `${item.name} (id: ${item.id})`,
                            value: item.id,
                        }))
                )
            );
        });
    }, [allowEmpty, attrId]);
    return (
        <Select
            showSearch
            // @ts-ignore
            filterOption={(input, option) => (option?.label.toLocaleString() ?? '')?.includes(input.toLowerCase())}
            options={options}
            {...restProps}
        />
    );
};

export const AttributeModal = (props: ModalProps & { onSubmit: (data: any) => void }) => {
    const { onSubmit, visible, ...restProps } = props;
    const [form] = Form.useForm();
    // const [page, setPage] = useState<number>(1);
    // const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [selections, setSelections] = useState<any[]>([]);
    const attributes = useRef<any>({});

    const refresh = () => {
        fetchGoodAttributeList().then(rep => {
            setData(rep.data);
            setTableData(rep.data);
            // setPage(newPage ?? page);
            // setTotal(rep.data.length);
            rep.data.forEach((item: any) => {
                attributes.current[item.id] = item;
            });
        });
    };
    useEffect(() => {
        if (visible) {
            setSelections([]);
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    return (
        <Modal
            visible={visible}
            width={800}
            title="选择属性"
            {...restProps}
            onOk={() => {
                onSubmit(selections.map(key => attributes.current[key]));
            }}
        >
            <Form layout="inline" form={form}>
                <Item label="属性ID" name="id">
                    <Input placeholder="请输入" />
                </Item>
                <Item label="名称" name="name">
                    <Input placeholder="请输入" />
                </Item>
                <Button
                    type="primary"
                    onClick={() => {
                        const filter = form.getFieldsValue();
                        setTableData([
                            ...data.filter(item => {
                                if (filter.id && +filter.id !== +item.id) return false;
                                if (filter.name && !item.name.includes(filter.name)) return false;
                                return true;
                            }),
                        ]);
                        // setFilter({ ...form.getFieldsValue() });
                    }}
                >
                    搜索
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setTableData([...data]);
                    }}
                    type="default"
                    style={{ marginLeft: 10 }}
                >
                    重置
                </Button>
            </Form>
            <Table
                rowKey="id"
                style={{ marginTop: 10 }}
                scroll={{ x: true }}
                dataSource={tableData}
                columns={[
                    {
                        title: '属性ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                ]}
                rowSelection={{
                    selectedRowKeys: selections,
                    onChange: selection => setSelections(selection),
                    preserveSelectedRowKeys: true,
                    type: 'checkbox',
                }}
                pagination={{
                    // current: page,
                    pageSize: 10,
                    showQuickJumper: true,
                    // onChange: page => {
                    //     refresh(page);
                    // },
                    total: tableData.length,
                }}
            />
        </Modal>
    );
};
