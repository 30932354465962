import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { message, Table } from 'antd';
import moment from 'moment';
import useList from '@shared/utils/hooks/useList';
import { fetchLogs } from '@services/api/logs';
import useListScrollHeight from '@shared/utils/hooks/useListScrollHeight';
import {
    getColumnSearchProps,
    getColumnCheckProps,
    getColumnRangePickerProps,
} from '@/shared/utils/table-filter/table-filter-selector';
import { TIME_LAYOUT } from '@shared/model/constant/time';
import { LogListParams, LogData } from '@shared/model/type';
import OperationComponent from './components/operation-type';
import { OPERATION_OPTIONS } from './config/operation-options';

const LogList: React.FC<{}> = function JobList() {
    const [
        logsListState,
        setLogsListState,
        totalState,
        setTotalState,
        loadingState,
        setLoadingState,
    ] = useList<LogData>([]);
    const [scrollHeightState] = useListScrollHeight(550);
    const [timeFilterState, setTimeFilterState] = useState<boolean>(true);

    const paramsRef = useRef<LogListParams>({
        offset: 1,
        pageSize: 30,
        operationType: '',
        email: '',
        toTime: moment().endOf('day').unix(),
        fromTime: moment().startOf('day').subtract(7, 'day').unix(),
    });

    const loadLogs = () => {
        setLoadingState(true);
        fetchLogs({
            ...paramsRef.current,
        })
            .then(res => {
                setLogsListState(res.list);
                setTotalState(res.total);
            })
            .catch(err => {
                message.error(err.msg || '服务器错误');
            })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const resetPage = () => {
        paramsRef.current.offset = 1;
    };

    const handle = useCallback((callback: (...values: any) => void) => {
        resetPage();
        return function search(...params: any) {
            callback.apply(null, [...params]);
            loadLogs();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LOG_COLUMNS = useMemo(
        () => [
            {
                title: 'ID',
                key: 'id',
                dataIndex: 'id',
            },
            {
                title: '类型',
                key: 'operation_type',
                dataIndex: 'operation_type',
                render: (status: number) => {
                    return <OperationComponent status={status} />;
                },
                ...getColumnCheckProps(
                    OPERATION_OPTIONS,
                    handle(keys => {
                        paramsRef.current.operationType = keys.join(',');
                    })
                ),
            },
            {
                title: '操作人',
                key: 'operator',
                dataIndex: 'operator',
                ...getColumnSearchProps(
                    'operator',
                    handle((_, value) => {
                        paramsRef.current.email = value;
                    })
                ),
            },
            {
                title: '操作时间',
                key: 'created_time',
                dataIndex: 'created_time',
                render: (time: number) => {
                    if (!time) {
                        return '-';
                    }
                    return moment(time).format(TIME_LAYOUT);
                },
                ...getColumnRangePickerProps(
                    'created_time',
                    handle((_, value, enableTime) => {
                        paramsRef.current.offset = 1;
                        setTimeFilterState(enableTime);
                        if (enableTime && value?.length) {
                            paramsRef.current.fromTime = value[0].unix();
                            paramsRef.current.toTime = value[1].endOf('day').unix();
                            return;
                        }
                        paramsRef.current.fromTime = 0;
                        paramsRef.current.toTime = 0;
                    }),
                    timeFilterState
                ),

                // filterIcon: <CalendarOutlined style={{ color: testState ? '#1890ff' : undefined }} />,
            },
            {
                title: '操作详情',
                key: 'operation_detail',
                dataIndex: 'operation_detail',
            },
        ],
        [handle, timeFilterState]
    );
    // const submit = () => {
    //     const formValues: FormParams = form.getFieldsValue();

    //     Object.keys(formValues).forEach(key => {
    //         if (key === 'submitTime') {
    //             const time = formValues[key];
    //             params.current.fromSubmitTime = time[0].unix();
    //             params.current.toSubmitTime = time[1].unix();
    //             return;
    //         }
    //         (params.current[key as keyJobsParams] as string | number) = formValues[key as keyJobsParams];
    //     });
    // };

    // const resetForm = () => {
    //     const initialParams = {
    //         offset: 1,
    //         pageSize: 30,
    //         email: '',
    //         jobType: '',
    //         jobId: 0,
    //         status: '',
    //         toSubmitTime: moment().startOf('day').unix(),
    //         fromSubmitTime: moment().startOf('day').subtract(7, 'day').unix(),
    //     };
    //     params.current = initialParams;
    //     form.setFieldsValue({ ...params.current });
    // };

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section>
            {/* <Form
                form={form}
                onFinish={submit}
                initialValues={{
                    email: '',
                    jobType: '',
                    jobId: 0,
                    status: '',
                    submitTime: [moment().startOf('day'), moment().subtract(7, 'day').startOf('day')],
                    finishTime: [moment().startOf('day'), moment().subtract(7, 'day').startOf('day')],
                }}
            >
                <Row>
                    <Item name="jobId" label="任务ID">
                        <Input />
                    </Item>
                    <Item name="projectId" label="项目ID">
                        <Input />
                    </Item>
                    <Item name="submitTime" label="提交时间">
                        <RangePicker />
                    </Item>
                </Row>
                <Row>
                    <Item name="status" label="状态">
                        <Select options={STATUS_OPTIONS} />
                    </Item>
                    <Item name="email" label="用户账号">
                        <Input placeholder="请输入Email" />
                    </Item>
                    <Item name="finishTime" label="结束时间">
                        <RangePicker />
                    </Item>
                </Row>
                <Item>
                    <Button htmlType="submit">搜索</Button>
                    <Button onClick={resetForm}>重置</Button>
                </Item>
            </Form> */}
            <Table
                loading={loadingState}
                columns={LOG_COLUMNS}
                dataSource={logsListState}
                scroll={{ y: scrollHeightState }}
                rowKey={row => row.id}
                pagination={{
                    total: totalState,
                    showSizeChanger: true,
                    current: paramsRef.current.offset,
                    onShowSizeChange: (current: number, size: number) => {
                        paramsRef.current.pageSize = size;
                        loadLogs();
                    },
                    pageSize: paramsRef.current.pageSize,
                    onChange: (page: number) => {
                        if (paramsRef.current.offset === page) {
                            return;
                        }
                        paramsRef.current.offset = page;
                        loadLogs();
                    },
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                }}
            />
        </section>
    );
};

export default LogList;
