import { ACCOUNT_FILLING, PAYMENT } from '../../enum/finance/recharge.enum';

export const ACCOUNT_FILLING_OPTIONS = [
    {
        label: '已结算',
        value: ACCOUNT_FILLING.FINISH,
    },
    {
        label: '未结算',
        value: ACCOUNT_FILLING.UNFINISH,
    },
    {
        label: '部分结算',
        value: ACCOUNT_FILLING.PARTIAL,
    },
    {
        label: '不可结算',
        value: ACCOUNT_FILLING.NOT,
    },
];

export const PAYMENT_OPTIONS = [
    {
        label: '线下',
        value: PAYMENT.OFFLINE,
    },
    {
        label: '微信',
        value: PAYMENT.WECHAT,
    },
    {
        label: '支付宝',
        value: PAYMENT.ALIPAY,
    },
    {
        label: 'PayPal',
        value: PAYMENT.PAYPAL,
    },
    {
        label: '微信小程序',
        value: PAYMENT.WECHATMINIPROGRAM,
    },
];
