import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import UserGroupForm from './UserGroupForm';
import UserGroupTable from './UserGroupTable';

export const UserGroup: React.FC<{}> = function UserGroup() {
    const [isFormVisible, setFormVisible] = useState(false);

    const showModal = () => {
        setFormVisible(true);
    };
    const handleOk = () => {
        setFormVisible(false);
    };

    const handleCancel = () => {
        setFormVisible(false);
    };

    return (
        <div>
            <Modal title="新增用户组" visible={isFormVisible} onOk={handleOk} onCancel={handleCancel}>
                <UserGroupForm />
            </Modal>
            <h1>用户组管理</h1>
            <Button type="primary" onClick={showModal}>
                新增用户
            </Button>
            <UserGroupTable />
        </div>
    );
};

export default UserGroup;
