import { AxiosInstance, AxiosResponse } from 'axios';
import { HTTP_CODE } from '@shared/model/enum/http-code.enum';

export default function loginAxios(callback: Function) {
    return (axios: AxiosInstance) => {
        const handle = (res: AxiosResponse) => {
            if (res.status === HTTP_CODE.UNAUTHENTICATED) {
                callback(res);
            }
            return res;
        };

        axios.interceptors.response.use(handle, handle);
    };
}
