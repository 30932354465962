import React from 'react';
import { WarningOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { joinClassName } from '@shared/utils/util';
import { CircleErrorIcon } from '@shared/static/icon/login';
import styles from './index.module.css';

const HrmLoginInfoError: React.FC<any> = React.memo(({ prefix, prefixStyle, className, style, children, visible }) => {
    const renderPrefixElement = () => {
        if (typeof prefix === 'string') {
            switch (prefix) {
                case 'error':
                    return <CloseOutlined style={prefixStyle} />;
                case 'success':
                    return <CheckOutlined style={prefixStyle} />;
                case 'circleError':
                    return <CircleErrorIcon />;
                case 'warning':
                    return <WarningOutlined style={prefixStyle} />;
                default:
                    return null;
            }
        } else {
            return prefix;
        }
    };

    return (
        <span
            className={joinClassName(className, styles['error-info-container'])}
            style={Object.assign(style || {}, { display: visible ? 'flex' : 'none' })}
        >
            {renderPrefixElement()}
            {children}
        </span>
    );
});

export default HrmLoginInfoError;
