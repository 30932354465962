import { Button, Form, FormInstance, Input, Modal, ModalProps, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { getQuota, LicensePriceCycleMsg, queryLicenseSku } from '@services/api/license';

const { Item } = Form;

export const LicenseModal = (props: ModalProps & { onSubmit: (data: any) => void }) => {
    const { visible, onCancel, onSubmit } = props;
    const [licenseForm] = Form.useForm();
    const [licenseSelection, setLicenseSelection] = useState<any[]>([]);
    const [licenseList, setLicenseList] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const search = (params: { page: number; pageSize: number; form: FormInstance }) => {
        const { page, pageSize, form } = params;
        const data = form.getFieldsValue();
        queryLicenseSku({
            page,
            pageSize,
            licenseName: data.licenseName,
            licenseId: data.licenseId ? +data.licenseId : undefined,
            skuId: data.skuId ? +data.skuId : undefined,
        }).then(rep => {
            setLicenseList(
                rep.data.items.map((item: any) => ({
                    ...item,
                    quota: item.quotaConf ? JSON.parse(item.quotaConf) : {},
                }))
            );
            setTotal(rep.data.total);
        });
    };
    const [quota, setQuota] = useState<{ [key: string]: string }>({});
    useEffect(() => {
        if (visible) {
            search({ page: 1, pageSize: 999, form: licenseForm });
            getQuota().then(rep => {
                const q = {} as any;
                rep.data.forEach((item: any) => {
                    q[item.keyName] = item.name;
                });
                setQuota(q);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    return (
        <Modal
            width="800"
            title="请选择License"
            visible={visible}
            onOk={() => {
                onSubmit(licenseList.filter(l => licenseSelection.includes(l.skuId)));
                setLicenseSelection([]);
            }}
            onCancel={e => {
                setLicenseSelection([]);
                if (onCancel) {
                    onCancel(e);
                }
            }}
        >
            <Form layout="inline" form={licenseForm}>
                <Item label="license name" name="licenseName">
                    <Input placeholder="请填写License name" />
                </Item>
                <Item label="license id" name="licenseId">
                    <Input type="number" placeholder="请填写License id" />
                </Item>
                <Item label="sku id" name="skuId">
                    <Input type="number" placeholder="请填写License id" />
                </Item>
                <Item>
                    <Button
                        onClick={() => {
                            licenseForm.resetFields();
                            search({ page: 1, pageSize: 999, form: licenseForm });
                        }}
                    >
                        重置
                    </Button>
                    <Button
                        style={{ marginLeft: 12 }}
                        type="primary"
                        onClick={() => {
                            search({ page: 1, pageSize: 999, form: licenseForm });
                        }}
                    >
                        搜索
                    </Button>
                </Item>
            </Form>
            <Table
                style={{ marginTop: 12 }}
                rowKey="skuId"
                dataSource={licenseList}
                rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    preserveSelectedRowKeys: true,
                    selections: licenseSelection,
                    onChange: keys => {
                        setLicenseSelection(keys);
                    },
                }}
                columns={[
                    {
                        title: 'sku ID',
                        dataIndex: 'skuId',
                    },
                    {
                        title: 'license ID',
                        dataIndex: 'licenseId',
                    },
                    {
                        title: 'license Name',
                        dataIndex: 'licenseName',
                    },
                    {
                        title: '定价周期',
                        dataIndex: 'priceCycle',
                        render: value => LicensePriceCycleMsg[value],
                    },
                    {
                        title: '单价（元）',
                        dataIndex: 'unitPrice',
                    },
                    {
                        title: '配额信息',
                        dataIndex: 'quota',
                        render: value => (
                            <>
                                {value
                                    ? Object.keys(value).map(key => (
                                          <Row key={key}>
                                              {quota[key]}:{value[key]}
                                          </Row>
                                      ))
                                    : null}
                            </>
                        ),
                    },
                ]}
                pagination={{
                    defaultPageSize: 100,
                    total,
                    onChange: page => {
                        search({ page, pageSize: 999, form: licenseForm });
                    },
                }}
            />
        </Modal>
    );
};
