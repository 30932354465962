import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Table, Button, message } from 'antd';
import { fetchIncomeList, claimOffline, bulkDelete, importFinanceRecord } from '@/services/api/bank';
import useListScrollHeight from '@/shared/utils/hooks/useListScrollHeight';
import { IncomeListData, IncomeListParams } from '@/shared/model/type/finance/income-list.type';
import fileSelector from '@utils/fileSelector';
import IncomeListForm from './components/form';

const { TextArea } = Input;

const FinanceIncome: React.FC = React.memo(function FinanceIncome() {
    const [row, setRow] = useState<any>();
    const [dataSourceState, setDataSourceState] = useState<IncomeListData[]>([]);
    const [selectedDataState, setSelectedDataState] = useState<IncomeListData[]>([]);
    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 30, total: 0 });
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [offlineBusiness, setOfflineBusiness] = useState<{
        id: number;
        offlineAmount?: number;
        offlineRemark: string;
    }>({ id: 0, offlineAmount: undefined, offlineRemark: '' });
    const [visible, setVisible] = useState<boolean>(false);
    const [scrollHeightState] = useListScrollHeight(550, 185);
    const [searchingState, setSearchingState] = useState<IncomeListParams>({ page: 1, pageSize: 30 });
    const [selectedRowKeysState, setSelectedRowKeysState] = useState<any[]>([]);

    const obtainData = async (params: IncomeListParams) => {
        try {
            setLoadingState(true);
            const rep = await fetchIncomeList({
                ...params,
                claimStatus: params?.claimStatus ? [params.claimStatus as number] : undefined,
            });
            if (!rep || rep.code) {
                message.error(rep?.error?.msg ?? '服务器错误');
                return setLoadingState(false);
            }
            setPaginationState({
                current: rep.data.page || paginationState.current,
                pageSize: params.pageSize || paginationState.pageSize,
                total: rep.data.total,
            });
            setDataSourceState(rep.data.items);
            setLoadingState(false);
            setSelectedRowKeysState([]);
        } catch (e) {
            setLoadingState(false);
            setSelectedRowKeysState([]);
        }
    };

    useEffect(() => {
        const { current, pageSize } = paginationState;
        obtainData({
            page: current,
            pageSize,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOfflineClaim = async () => {
        if (!row) return;
        claimOffline(row.id, {
            offlineAmount: offlineBusiness.offlineAmount!,
            offlineRemark: offlineBusiness.offlineRemark,
        }).then((rep: any) => {
            if (!rep.code) {
                message.success('操作成功');
                obtainData({ page: paginationState.current, pageSize: paginationState.pageSize });
            } else {
                message.error(rep?.error?.msg ?? '服务器错误');
            }
        });
    };

    // 注意，目前那几个button都不好用，需要加入功能才能用
    return (
        <div>
            <IncomeListForm
                onSubmit={(values: any) => {
                    setSearchingState({ ...values });
                    obtainData({ ...values, page: 1, pageSize: paginationState.pageSize });
                }}
                onDelete={async (data: number[]) => {
                    bulkDelete(data).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            obtainData(searchingState);
                        } else {
                            message.error(rep?.error?.msg ?? '服务器错误');
                        }
                    });
                }}
                currSelected={selectedDataState.map(data => {
                    return data.id;
                })}
                onImport={() => {
                    fileSelector.selectFile().then(files => {
                        importFinanceRecord(files[0]).then(rep => {
                            if (!rep.code) {
                                message.success('导入成功');
                                obtainData({ ...searchingState, page: 1, pageSize: paginationState.pageSize });
                            } else {
                                message.error(rep?.error?.msg ?? '服务器错误');
                            }
                        });
                    });
                }}
            />
            <Table
                columns={[
                    // {
                    //     title: '序号',
                    //     key: 'index',
                    //     dataIndex: 'index',
                    //     render: (text: any, record: IncomeListData, index: number) => {
                    //         return index + 1;
                    //     },
                    // },
                    {
                        title: '来款id',
                        dataIndex: 'id',
                        width: 80,
                    },
                    {
                        title: '对方户名',
                        dataIndex: 'paymentAccountName',
                        width: 200,
                    },
                    {
                        title: '对方账号',
                        dataIndex: 'paymentAccountNo',
                        width: 100,
                    },
                    {
                        title: '来款日期',
                        dataIndex: 'bankDate',
                        width: 100,
                    },
                    {
                        title: '备注',
                        width: 100,
                        dataIndex: 'remark',
                    },
                    {
                        title: '流水号',
                        width: 100,
                        dataIndex: 'bankNo',
                    },
                    {
                        title: '来款金额',
                        width: 100,
                        dataIndex: 'amount',
                    },
                    {
                        title: '线上业务金额',
                        width: 120,
                        dataIndex: 'onlineBusinessAmount',
                    },
                    {
                        title: '已充值金额',
                        width: 120,
                        dataIndex: 'rechargeAmount',
                    },
                    {
                        title: '未充值金额',
                        width: 120,
                        dataIndex: 'unrechargeAmount',
                    },
                    {
                        title: '公司主体',
                        width: 200,
                        dataIndex: 'receiveAccountName',
                    },
                    {
                        title: '我方账号',
                        width: 100,
                        dataIndex: 'receiveAccountNo',
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        dataIndex: 'operation',
                        fixed: 'right',
                        render: (text: any, record: IncomeListData) => {
                            return (
                                <Button
                                    style={{ marginLeft: 12 }}
                                    onClick={() => {
                                        setRow(record);
                                        setVisible(true);
                                    }}
                                    size="small"
                                    type="primary"
                                >
                                    线下业务认领
                                </Button>
                            );
                        },
                    },
                ]}
                dataSource={dataSourceState}
                rowSelection={{
                    selectedRowKeys: selectedRowKeysState,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeysState(selectedRowKeys);
                        setSelectedDataState(selectedRows);
                    },
                }}
                rowKey={item => item.id}
                scroll={{ x: true, y: scrollHeightState }}
                loading={loadingState}
                bordered
                pagination={{
                    ...paginationState,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                    onChange: (current, pageSize) => {
                        obtainData({
                            ...searchingState,
                            page: current,
                            pageSize: pageSize || paginationState.pageSize,
                        });
                    },
                }}
            />
            <Modal
                visible={visible}
                title="线下业务认领"
                onOk={() => {
                    handleOfflineClaim();
                    setVisible(false);
                    setOfflineBusiness({ id: 0, offlineAmount: undefined, offlineRemark: '' });
                }}
                onCancel={() => {
                    setVisible(false);
                    setOfflineBusiness({ id: 0, offlineAmount: undefined, offlineRemark: '' });
                }}
                destroyOnClose
            >
                <Form>
                    <Form.Item label="来款id">{row?.id}</Form.Item>
                    <Form.Item label="资金流水号">{row?.bankNo}</Form.Item>
                    <Form.Item label="可认领金额">{row?.unrechargeAmount}</Form.Item>
                    <Form.Item label="线下业务金额">
                        <Input
                            value={offlineBusiness.offlineAmount}
                            allowClear
                            type="number"
                            placeholder="金额"
                            onChange={e => {
                                if (e.target.value.split('.')?.[1]?.length > 2) {
                                    return message.error('线下业务金额最小单位为分');
                                }
                                offlineBusiness.offlineAmount = +e.target.value;
                                setOfflineBusiness({ ...offlineBusiness });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="线下业务说明">
                        <TextArea
                            allowClear
                            style={{ height: 120 }}
                            rows={6}
                            maxLength={150}
                            onChange={e => {
                                offlineBusiness.offlineRemark = e.target.value;
                                setOfflineBusiness({ ...offlineBusiness });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
});

export default FinanceIncome;
