import axios from '@utils/axios';
import { Response } from '@shared/model/type/response.type';

export enum Business {
    Hermite = 'Hermite',
    Bohrium = 'Bohrium',
    Unifinder = 'Unifinder',
}

export const BusinessMsg = {
    [Business.Hermite]: 'Hermite',
    [Business.Bohrium]: 'Bohrium',
    [Business.Unifinder]: 'Unifinder',
    '1': 'Hermite',
    '2': 'Bohrium',
    '4': 'Unifinder',
} as { [key: string]: string };

export enum ResourceStatus {
    Deleted = -1,
    Normal = 1,
    Paused = 2,
}

export const ResourceStatusMsg = {
    [ResourceStatus.Deleted]: '删除',
    [ResourceStatus.Normal]: '正常',
    [ResourceStatus.Paused]: '停用',
} as { [key: string]: string };

export function fetchResources(data: {
    businessId?: number;
    groupId?: number;
    groupName?: string;
    id?: number;
    identifier?: string;
    name?: string;
    page: number;
    pageSize: number;
    ids?: number[];
}) {
    return axios.post('/v1/resource/list', {
        ...data,
        id: data.id ? +data.id : undefined,
    }) as Promise<Response>;
}

export function fetchResourcesByIds(ids: number[]) {
    return axios.post('/v1/resource/list_by_ids', {
        ids,
    }) as Promise<Response>;
}

export function createResource(data: {
    action: string;
    businessId: number;
    groupId?: number;
    identifier: string;
    name: string;
    status: number;
}) {
    return axios.post('/v1/resource/create', data) as Promise<Response>;
}

export function updateResource(
    id: string,
    data: {
        action?: string;
        businessId?: number;
        groupId?: number;
        identifier?: string;
        name?: string;
        status?: number;
    }
) {
    return axios.put(`/v1/resource/${id}`, data) as Promise<Response>;
}

export function fetchResourceGroups(data: {
    businessId?: number;
    id?: number;
    name?: string;
    page: number;
    pageSize: number;
    status?: number;
}) {
    return axios.post('/v1/resource_group/list', {
        ...data,
        id: data.id ? +data.id : undefined,
    }) as Promise<Response>;
}

export function createResourceGroup(data: { businessId: number; description: string; name: string; status: number }) {
    return axios.post('/v1/resource_group/create', data) as Promise<Response>;
}

export function updateResourceGroup(
    id: string,
    data: {
        businessId?: number;
        description?: string;
        name?: string;
        status?: number;
    }
) {
    return axios.put(`/v1/resource_group/${id}`, data) as Promise<Response>;
}
