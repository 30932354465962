import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import { Moment } from 'moment';
import React, { useMemo, useEffect, useState } from 'react';

const { RangePicker } = DatePicker;

const IncomeRecordForm: React.FC<any> = React.memo(function IncomeRecordForm(props) {
    const { onSubmit, userName } = props;
    const [currUserName, setCurrUserName] = useState<string>('');
    const [form] = Form.useForm();
    const colProps = useMemo(
        () => ({
            span: 8,
        }),
        []
    );
    useEffect(() => {
        setCurrUserName(userName);
    }, [userName]);
    return (
        <Form
            form={form}
            fields={[{ name: ['paymentAccountName'], value: currUserName }]}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{ span: 17 }}
            onFinish={values => {
                setCurrUserName(values.paymentAccountName);
                const { time, ...params } = values;
                const [startTime, endTime] = (time || []).map((m: Moment) => m.format('YYYY-MM-DD'));
                onSubmit({
                    ...params,
                    startTime: startTime ? `${startTime} 00:00:00` : undefined,
                    endTime: endTime ? `${endTime} 23:59:59` : undefined,
                });
            }}
        >
            <Row>
                <Col {...colProps}>
                    <Form.Item label="对方户名" name={'paymentAccountName'}>
                        <Input
                            // defaultValue={userName}
                            allowClear
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="请输入对方户名"
                        />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="对方账号" name={'paymentAccountNo'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入对方账号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="来款金额">
                        <Row>
                            <Form.Item style={{ width: '40%' }} name="minAmount">
                                <InputNumber style={{ width: '100%' }} placeholder="金额" precision={2} />
                            </Form.Item>
                            <span style={{ padding: 5 }}>-</span>
                            <Form.Item style={{ width: '40%' }} name="maxAmount">
                                <InputNumber style={{ width: '100%' }} placeholder="金额" precision={2} />
                            </Form.Item>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col {...colProps}>
                    <Form.Item label="交易流水号" name={'bankNo'}>
                        <Input allowClear spellCheck={false} autoComplete="off" placeholder="请输入交易流水号" />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Form.Item label="来款日期" name={'time'}>
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col {...colProps}>
                    <Button style={{ marginRight: 10 }} type="primary" htmlType="submit">
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            form.submit();
                        }}
                    >
                        重置
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});

export default IncomeRecordForm;
