import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';
import { notFoundElement } from '@shared/components/elements/index';
import { RichRoutesProps, RouteComponentRenderProps } from '@shared/model/type/router.type';
import MainLayout from '@shared/layout';
import { getToken } from '@utils/token';

export const RouteContext = React.createContext<RouteConfig | null>(null);

const RouteComponentRender = ({ childRoute, extraProps }: RouteComponentRenderProps) => {
    if (childRoute.render) {
        return (
            <RouteContext.Provider value={childRoute}>
                {childRoute.render({
                    // ...props,
                    ...extraProps,
                    route: childRoute,
                })}
            </RouteContext.Provider>
        );
    }
    if (childRoute.component) {
        return (
            <RouteContext.Provider value={childRoute}>
                {childRoute.layout ? (
                    <MainLayout>
                        <childRoute.component {...extraProps} route={childRoute} />
                    </MainLayout>
                ) : (
                    <childRoute.component {...extraProps} route={childRoute} />
                )}
            </RouteContext.Provider>
        );
    }
    return null;
};

const RichRoutes = React.memo<RichRoutesProps>(function ExtendRoutes({
    RouteComponent = Route,
    route,
    routeComponentProps = {},
    extraProps = {},
    switchProps = {},
}) {
    if (Array.isArray(route)) {
        // eslint-disable-next-line no-param-reassign
        route = {
            path: '',
            routes: route,
        } as RouteConfig;
    }
    return route?.routes ? (
        <Routes {...switchProps}>
            {(route as RouteConfig)
                .routes!.concat({
                    render: () => notFoundElement,
                    path: '*/*',
                })
                .map((childRoute, i) => {
                    const { path } = childRoute;
                    const routeChildComponent = (
                        <RouteComponent
                            {...routeComponentProps}
                            // route={childRoute}
                            key={childRoute.key || i}
                            path={path}
                            element={<RouteComponentRender childRoute={childRoute} extraProps={extraProps} />}
                        />
                    );
                    if (!childRoute.auth) {
                        return routeChildComponent;
                    }

                    if (childRoute.auth) {
                        const token = getToken();
                        if (!token) {
                            return <Route path="*" key={childRoute.key || i} element={<Navigate to="/login" />} />;
                        }
                    }

                    return routeChildComponent;
                })
                .filter(Boolean)}
        </Routes>
    ) : null;
});

export default RichRoutes;
