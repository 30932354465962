import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Upload } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { fetchGoods } from '@services/api/goods';
import { IncomeRecordModal } from '@/shared/components/biz/finance/income-record-modal/index';
import {
    BALANCE_TYPE,
    CHARGE_TYPE_OPTIONS,
    MONEY_ARRIVAL_STATUS,
    VIP_OPTIONS,
    ORG_SOURCE_OPTIONS,
    WalletInfo,
    PairsInfo,
} from '@/biz/user/model';
import { charge, fetchPairsInfo, fetchWalletInfo } from '@/services/api/finance';
import { ContractSelector } from '@shared/components/biz/selector/contract';
import { GlobalContext } from '@/App';

const { Item } = Form;
const { TextArea } = Input;

interface OrgRechargeProps {
    email: string;
    visible: boolean;
    orgId: number;
    userId: number;
    onCancel: () => void;
    onSubmit: () => void;
}

const OrgRechargeModal: React.FC<OrgRechargeProps> = React.memo(function OrgRecharge({
    email,
    visible,
    orgId,
    userId,
    onCancel,
    onSubmit,
}) {
    const globalData = useContext(GlobalContext);
    const isAWS = globalData?.env === 'aws';
    const [chargeSubmitingState, setChargeSubmitingState] = useState<boolean>(false);
    const attachmentRef = useRef<any>([]);
    const [form] = Form.useForm();
    const [walletInfo, setWalletInfo] = useState<WalletInfo>();
    const [pairsInfo, setPairsInfo] = useState<PairsInfo>();
    const [sku, setSku] = useState<{ id: number; count: number }[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [bank, setBank] = useState<any>({});
    const chargeSubmit = () => {
        if (form.getFieldValue('payRecved') === 1 && !bank?.id) {
            return message.error('请选择来款流水');
        }
        const balanceType = form.getFieldValue('balanceType');
        const chargeType = form.getFieldValue('chargeType');
        const contractNum = form.getFieldValue('contractNum');
        if (balanceType === 1 && chargeType === 1 && contractNum === '') {
            return message.error('请选择合同编号');
        }
        form.validateFields().then(() => {
            setChargeSubmitingState(true);
            const values = form.getFieldsValue();
            const data = new FormData();
            data.append('emails', email);
            data.append('chargeSponsor', 'admin');
            data.append('orgId', `${orgId}`);
            data.append('userId', `${userId}`);
            Object.keys(values).forEach(key => {
                if (values[key]) {
                    data.append(key, values[key]);
                }
            });
            if (form.getFieldValue('payRecved') === 1) {
                data.append('bankId', `${bank.id}`);
            } else {
                data.delete('payRecved');
            }
            if (values.orgSource === ORG_SOURCE_OPTIONS[1].value) {
                data.append('resourceInfo', JSON.stringify(sku));
            }
            const filesArr = attachmentRef.current.slice(0, 3);
            filesArr?.forEach((file: any) => {
                data.append('files', file, file.name);
            });

            charge(data).then(rep => {
                if (!rep.code) {
                    message.success('充值成功');
                    onSubmit();
                } else {
                    // eslint-disable-next-line no-prototype-builtins,no-lonely-if
                    if (rep.hasOwnProperty('msg')) {
                        message.error(rep.msg);
                    } else {
                        message.error(rep?.error?.msg ?? '服务器错误');
                    }
                }
                setBank({});
                setChargeSubmitingState(false);
                onCancel();
            });
        });
    };

    const getWalletInfo = () => {
        fetchWalletInfo(orgId, 'admin').then(({ data }) => {
            setWalletInfo(data);
        });
    };
    const getPairsInfo = () => {
        fetchPairsInfo(orgId, 'admin').then(({ data }) => {
            setPairsInfo(data);
        });
    };

    useEffect(() => {
        if (visible) {
            getWalletInfo();
            getPairsInfo();
            fetchGoods({
                page: 1,
                pageSize: 999,
            }).then(rep => {
                setSku(
                    rep.data.items.map((item: any) => ({
                        id: item.skuId,
                        count: 0,
                    }))
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    return (
        <div>
            <Modal
                visible={visible}
                onCancel={onCancel}
                maskClosable={false}
                onOk={chargeSubmit}
                title="充值"
                confirmLoading={chargeSubmitingState}
                destroyOnClose
                centered
            >
                <Form
                    initialValues={{
                        balanceType: BALANCE_TYPE.NORMAL_BALANCE,
                        vip: VIP_OPTIONS[1].value,
                        chargeType: CHARGE_TYPE_OPTIONS[0].value,
                        remark: '',
                        contractNum: '',
                        contractName: '',
                        amount: 0,
                        payRecved: -1,
                        // pairs: 0,
                    }}
                    form={form}
                    preserve={false}
                    labelCol={{ span: 5 }}
                >
                    <Item label="Email">{email}</Item>
                    {isAWS ? (
                        <Item label="累计充值：">
                            ${' '}
                            {(Number((walletInfo?.balance || 0) * 100) +
                                Number((walletInfo?.couponBalance || 0) * 100)) /
                                100}
                            ( 本月 ${' '}
                            {Number(walletInfo?.currentMonthRecharge || 0) +
                                Number(walletInfo?.currentMonthCouponRecharge || 0)}
                            )， 累计消费： $ {walletInfo?.totalCost || 0} ( 本月 $ {walletInfo?.currentMonthCost || 0})
                        </Item>
                    ) : (
                        <Item label="累计充值：">
                            {(Number((walletInfo?.balance || 0) * 100) +
                                Number((walletInfo?.couponBalance || 0) * 100)) /
                                100}
                            元 ( 本月{' '}
                            {Number(walletInfo?.currentMonthRecharge || 0) +
                                Number(walletInfo?.currentMonthCouponRecharge || 0)}
                            元)， 累计消费：
                            {walletInfo?.totalCost || 0}元 ( 本月 {walletInfo?.currentMonthCost || 0}元)
                        </Item>
                    )}
                    <Item label="pairs数余额：">
                        {pairsInfo?.fepBalance || 0}，pairs累计充值：
                        {pairsInfo?.totalPairsRecharge || 0}(本月{pairsInfo?.currentMonthPairsRecharge || 0})，累计消费{' '}
                        {pairsInfo?.totalPairsCost || 0}(本月 {pairsInfo?.currentMonthPairsCost || 0})
                    </Item>
                    {/* <Item label="Email">{emailsRef.current.emails}</Item> */}
                    <Item name="orgSource" label="产品线" rules={[{ required: true }]}>
                        <Select options={ORG_SOURCE_OPTIONS} placeholder="请选择产品线" />
                    </Item>
                    <Item name="vip" label="是否是VIP" rules={[{ required: true }]}>
                        <Select options={VIP_OPTIONS} />
                    </Item>
                    <Item name="balanceType" label="金额类型">
                        <Radio.Group>
                            <Radio value={BALANCE_TYPE.NORMAL_BALANCE}>付费充值</Radio>
                            <Radio value={BALANCE_TYPE.DISCOUNT_BALANCE}>优惠充值</Radio>
                        </Radio.Group>
                    </Item>
                    <Item name="chargeType" label="充值类型">
                        <Select options={CHARGE_TYPE_OPTIONS} />
                    </Item>
                    <Item
                        name="amount"
                        label="充值金额"
                        rules={[
                            { required: true, message: '请输入充值金额' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value > 0 || getFieldValue('pairs')) {
                                        return Promise.resolve();
                                    }
                                    if (value === 0 && getFieldValue('pairs') === 0) {
                                        return Promise.reject(new Error('pair数和充值金额不能同时为0'));
                                    }
                                    return Promise.reject(new Error('充值金额请输入大于等于0的数值'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber min={0} style={{ width: '200px' }} />
                    </Item>
                    {/* <Item */}
                    {/*    name="pairs" */}
                    {/*    label="pairs数" */}
                    {/*    rules={[ */}
                    {/*        ({ getFieldValue }) => ({ */}
                    {/*            validator(_, value) { */}
                    {/*                if (value > 0 || getFieldValue('amount')) { */}
                    {/*                    return Promise.resolve(); */}
                    {/*                } */}
                    {/*                if (value === 0 && getFieldValue('amount') === 0) { */}
                    {/*                    return Promise.reject(new Error('pair数和充值金额不能同时为0')); */}
                    {/*                } */}
                    {/*                return Promise.reject(new Error('充值pair数请输入大于等于0的数值')); */}
                    {/*            }, */}
                    {/*        }), */}
                    {/*    ]} */}
                    {/* > */}
                    {/*    <InputNumber min={0} style={{ width: '200px' }} precision={0} /> */}
                    {/*    /!* <InputNumber min={0} style={{ width: '200px' }} disabled={!hasFepState} /> *!/ */}
                    {/* </Item> */}
                    <Item label="是否已到款" shouldUpdate={(prev, next) => prev.balanceType !== next.balanceType}>
                        {form =>
                            form.getFieldValue('balanceType') === BALANCE_TYPE.NORMAL_BALANCE ? (
                                <Item style={{ marginBottom: 0 }} name="payRecved">
                                    <Select options={MONEY_ARRIVAL_STATUS} />
                                </Item>
                            ) : null
                        }
                    </Item>
                    <Item
                        label="来款流水"
                        shouldUpdate={(prev, next) =>
                            prev.balanceType !== next.balanceType || prev.payRecved !== next.payRecved
                        }
                    >
                        {form =>
                            form.getFieldValue('balanceType') === BALANCE_TYPE.NORMAL_BALANCE &&
                            form.getFieldValue('payRecved') === 1 ? (
                                <Row>
                                    {`${bank.paymentAccountName || ''} ${bank.bankNo || ''}  `}
                                    <Button
                                        onClick={() => {
                                            setModalVisible(true);
                                        }}
                                    >
                                        请选择来款流水
                                    </Button>
                                </Row>
                            ) : null
                        }
                    </Item>

                    <Item name="remark" label="备注">
                        <TextArea maxLength={200} />
                    </Item>
                    <Item label="附件">
                        <Upload
                            beforeUpload={file => {
                                attachmentRef.current.push(file);
                                return false;
                            }}
                            onRemove={file => {
                                attachmentRef.current = attachmentRef.current.filter((f: any) => f.uid !== file.uid);
                            }}
                            multiple
                            maxCount={3}
                        >
                            <Button icon={<UploadOutlined />}>上传附件</Button>
                        </Upload>
                    </Item>
                    <Item name="contractNum" label="合同编号">
                        <ContractSelector />
                    </Item>
                    <Item name="contractName" label="合同名称">
                        <TextArea maxLength={100} />
                    </Item>
                </Form>
            </Modal>
            <IncomeRecordModal
                title="请选择来款记录"
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false);
                }}
                onSubmit={data => {
                    setBank(data[0]);
                    setModalVisible(false);
                }}
                multiSelect={false}
                currData={{ orgId: String(orgId), unsettledAmount: Number.POSITIVE_INFINITY }}
            />
        </div>
    );
});

export const useRechargeHanlder = (onSubmit: () => void) => {
    const [visibleState, setVisibleState] = useState(false);
    const [emailState, setEmailState] = useState('');
    const [orgIdState, setOrgIdState] = useState<number>(0);
    const [userIdState, setUserIdState] = useState<number>(0);

    return {
        openRechargeModal(email: string, orgId: number, userId: number) {
            setEmailState(email);
            setOrgIdState(orgId);
            setUserIdState(userId);
            setVisibleState(true);
        },
        rechargeProps: {
            email: emailState,
            visible: visibleState,
            orgId: orgIdState,
            userId: userIdState,
            onCancel: () => setVisibleState(false),
            onSubmit,
        },
    };
};

export default OrgRechargeModal;
