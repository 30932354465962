export enum HTTP_CODE {
    UNAUTHENTICATED = 401,
    // success
    SUCCESS = 200,
    // server error
    SERVER_ERROR = 500,
    // fail
    SUCCESS_MAX = 300,

    SERVER_UNVALIABLE = 503,
}
