import { message, Modal, ModalProps } from 'antd';
import { useState } from 'react';

import { updateRecharge } from '@services/api/bank';

// const { Item } = Form;

interface changeRechargeSettleProps extends ModalProps {
    data: any;
    onSubmit: (data?: any) => void;
}

export const ChangeRechargeSettleModal = (props: changeRechargeSettleProps) => {
    const { data, onCancel = () => {}, onSubmit, ...restProps } = props;
    const [changeSettleVisible, setChangeSettleVisible] = useState<boolean>(false);
    const changeStatus = async (id: number, toStatus: number) => {
        try {
            updateRecharge(id, { settleStatus: toStatus }).then(rep => {
                if (!rep.code) {
                    message.success('操作成功');
                    setChangeSettleVisible(false);
                    onSubmit();
                } else {
                    message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Modal
            visible={changeSettleVisible}
            title="确认操作"
            {...restProps}
            onCancel={onCancel}
            onOk={e => {
                changeStatus(data.rechargeId, data.toStatus);
                onCancel(e);
            }}
        />
    );
};
