import { Button, Form, Input, message, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { BusinessMsg, fetchResourcesByIds } from '@services/api/resource';
import { BusinessSelector, LicenseStatusSelector } from '@shared/components/biz/selector';
import {
    createLicense,
    fetchLicenses,
    getLicense,
    getQuota,
    LicenseStatusMsg,
    updateLicense,
} from '@services/api/license';
import { ResourceModal } from '@shared/components/biz/resource/modal';

const { Item } = Form;
export const LicenseManagement = () => {
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(1);
    const [data, setData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>({});
    const [quota, setQuota] = useState<{ [key: string]: string }>({});
    const [quotaData, setQuotaData] = useState<any[]>([]);
    const [resourceVisible, setResourceVisible] = useState<boolean>(false);
    const refresh = (newPage?: number, newPageSize?: number) => {
        const params = { ...form.getFieldsValue() };
        setPage(newPage ?? page);
        setPageSize(newPageSize ?? pageSize);
        return fetchLicenses({ ...params, offset: newPage, pageSize: newPageSize }).then(rep => {
            setData(rep.data.items);
            setTotal(rep.data.total);
        });
    };
    useEffect(() => {
        refresh(page, pageSize);
        getQuota().then(rep => {
            const q = {} as any;
            rep.data.forEach((item: any) => {
                q[item.keyName] = item.name;
            });
            setQuota(q);
            setQuotaData(rep.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Form layout="inline" form={form}>
                <Item name="id" label="License ID">
                    <Input placeholder="请输入" />
                </Item>
                <Item name="name" label="名称">
                    <Input placeholder="请输入" />
                </Item>
                <Item name="status" label="状态">
                    <LicenseStatusSelector />
                </Item>
                <Button
                    type="primary"
                    onClick={() => {
                        refresh(1, pageSize);
                    }}
                >
                    搜索
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        refresh(1, pageSize);
                    }}
                    type="default"
                    style={{ marginLeft: 10 }}
                >
                    重置
                </Button>
            </Form>
            <Row justify="space-between" style={{ marginBottom: 10, marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        newForm.resetFields();
                        setDetail({
                            quota: quotaData.map((item: any) => ({ ...item, number: 0 })),
                            resources: [],
                        });
                        setVisible(true);
                    }}
                >
                    添加
                </Button>
            </Row>
            <Table
                dataSource={data}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '产品线',
                        dataIndex: 'businessId',
                        render: value => BusinessMsg[value],
                    },
                    {
                        title: '配额',
                        dataIndex: 'quota',
                        render: value => (
                            <>
                                {value
                                    ? Object.keys(value).map(key => (
                                          <Row key={key}>
                                              {quota[key]}:{value[key]}
                                          </Row>
                                      ))
                                    : null}
                            </>
                        ),
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        render: value => LicenseStatusMsg[value],
                    },
                    {
                        title: '添加时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '修改时间',
                        dataIndex: 'updateTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作',
                        render: (value, row) => (
                            <Button
                                style={{ marginLeft: 10 }}
                                type="primary"
                                onClick={() => {
                                    getLicense(row.id).then(rep => {
                                        const q = rep.data.quota;
                                        const license = {
                                            ...rep.data,
                                            quota: Object.keys(q).map((key: string) => ({
                                                keyName: key,
                                                name: quota[key],
                                                number: q[key],
                                            })),
                                        };
                                        newForm.setFieldsValue({
                                            name: license.name,
                                            businessId: license.businessId,
                                            status: license.status,
                                        });
                                        if (!license.resourceIds.length) {
                                            setDetail({
                                                ...license,
                                                resources: [],
                                            });
                                            setVisible(true);
                                            return;
                                        }
                                        fetchResourcesByIds(license.resourceIds.map((id: string) => +id)).then(rep => {
                                            setDetail({
                                                ...license,
                                                resources: rep.data,
                                            });
                                            setVisible(true);
                                        });
                                    });
                                }}
                            >
                                编辑
                            </Button>
                        ),
                    },
                ]}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    current: page,
                    pageSize,
                    onChange: (page, pageSize) => {
                        refresh(page, pageSize);
                    },
                    total,
                }}
            />
            <Modal
                width={800}
                title={detail?.id ? '更新License' : '新增License'}
                visible={visible}
                onCancel={() => {
                    setDetail(undefined);
                    setVisible(false);
                }}
                onOk={() => {
                    const data = newForm.getFieldsValue();
                    if (!detail?.id && Object.keys(data).some(key => !data[key])) {
                        return message.error('请填写完整表单');
                    }
                    const quota = detail.quota.reduce((total: any, item: any) => {
                        total[item.keyName] = item.number === '' ? 0 : parseInt(item.number, 10);
                        return total;
                    }, {});
                    const action = detail?.id
                        ? updateLicense(detail.id, {
                              ...data,
                              quota,
                              resourceIds: detail.resources.map((r: any) => r.id),
                          })
                        : createLicense({
                              ...data,
                              quota,
                              resourceIds: detail.resources.map((r: any) => r.id),
                          });
                    action.then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setVisible(false);
                            setDetail(undefined);
                            refresh(1, pageSize);
                        } else {
                            message.error(rep.error.msg ?? rep.error ?? '服务器错误');
                        }
                    });
                }}
            >
                <Form form={newForm} labelCol={{ span: 4 }}>
                    <Item name="name" label="名称" required>
                        <Input />
                    </Item>
                    <Item name="businessId" label="产品线" required>
                        <BusinessSelector useId />
                    </Item>
                    <Item name="status" label="状态" required>
                        <LicenseStatusSelector />
                    </Item>
                    <Table
                        size="small"
                        dataSource={detail?.quota || []}
                        columns={[
                            {
                                title: '配额名称',
                                dataIndex: 'name',
                            },
                            {
                                title: '数量',
                                dataIndex: 'number',
                                render: (value, row, index) => (
                                    <Input
                                        style={{ width: 120 }}
                                        value={value}
                                        onChange={e => {
                                            detail.quota[index].number = e.target.value;
                                            setDetail({
                                                ...detail,
                                                quota: [...detail.quota],
                                            });
                                        }}
                                    />
                                ),
                            },
                        ]}
                        pagination={false}
                    />
                    <Row justify="space-between" style={{ margin: '10px 0' }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setResourceVisible(true);
                            }}
                        >
                            添加权限
                        </Button>
                        <span>总权限数：{detail?.resources?.length ?? 0}</span>
                    </Row>
                    <Table
                        scroll={{ y: 300 }}
                        columns={[
                            {
                                title: 'id',
                                dataIndex: 'id',
                            },
                            {
                                title: '名称',
                                dataIndex: 'name',
                            },
                            {
                                title: '标识符',
                                dataIndex: 'identifier',
                            },
                            {
                                title: '操作',
                                render: (value, row, index) => (
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            detail.resources.splice(index, 1);
                                            setDetail({
                                                ...detail,
                                                resources: [...detail.resources],
                                            });
                                        }}
                                    >
                                        删除
                                    </Button>
                                ),
                            },
                        ]}
                        dataSource={detail?.resources ?? []}
                        pagination={false}
                    />
                </Form>
            </Modal>
            <ResourceModal
                onCancel={() => setResourceVisible(false)}
                visible={resourceVisible}
                onSubmit={data => {
                    const existed = new Set(detail.resources.map((a: any) => a.id));
                    const valid = data.filter((item: any) => !existed.has(item.id));
                    setDetail({
                        ...detail,
                        resources: [...detail.resources.concat(valid)],
                    });
                    setResourceVisible(false);
                }}
            />
        </>
    );
};
