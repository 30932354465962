import React from 'react';
import { Form, Input, Radio, Button, Tag } from 'antd';

const FormStyle = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

enum UserGroupType {
    SYSTEM = 'system',
    CUSTOM = 'custom',
}

interface UserGroupForm {
    userGroupId: string | number;
    userGroupName: string;
    userGroupType: UserGroupType;
    belongTo: string;
}

export const UserGroupForm: React.FC<UserGroupForm | null> = function UserGroup(props) {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log('finish: ', values);
    };

    const forms = () => {
        return (
            <Form
                labelAlign={'left'}
                {...FormStyle}
                initialValues={{ ...props }}
                name="userGroup"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item name="userGroupId" label="用户组id">
                    <Input placeholder="用户组id不可修改" />
                </Form.Item>
                <Form.Item name="userGroupName" label="用户组名称">
                    <Input placeholder="请输入用户组名称" />
                </Form.Item>
                <Form.Item name="userGroupType" label="是否自定义">
                    <Radio.Group>
                        <Radio value={UserGroupType.SYSTEM}>系统</Radio>
                        <Radio value={UserGroupType.CUSTOM}>自定义</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="belongTo" label="所属客户">
                    <Input placeholder="输入客户的管理员邮箱" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 6 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <Form.Item label="选择模块" name="modules">
                    <Tag>tag1</Tag>
                </Form.Item>
            </Form>
        );
    };

    return (
        <div>
            <h1>用户组增加</h1>
            {forms()}
        </div>
    );
};

export default UserGroupForm;
