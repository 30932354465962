import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { fetchGoodCategories, GOODS_STATUS } from '@services/api/goods';

const getChildren = (item: any) => {
    if (!item.children) return [item];
    return [item].concat(item.children.map((item: any) => getChildren(item))).flat();
};

export const CategorySelector = (props: SelectProps<any>) => {
    const [options, setOptions] = useState<any>([]);
    useEffect(() => {
        fetchGoodCategories().then(rep => {
            const options = rep.data.map((item: any) => ({
                label: item.name,
                options: getChildren(item)
                    .filter(item => item.status === GOODS_STATUS.Normal)
                    .map(item => ({
                        // ...item,
                        label: `${item.name} (${item.level}级目录，id:${item.id})`,
                        value: item.id,
                    })),
            }));
            setOptions([{ label: '根目录', value: 0 }].concat(options));
        });
    }, []);
    return <Select options={options} {...props} />;
};
