import { AxiosInstance, AxiosResponse } from 'axios';
import { HTTP_CODE } from '@shared/model/enum/http-code.enum';

export default function processResponseAxios() {
    return (axios: AxiosInstance) => {
        const handle = (res: AxiosResponse) => {
            if (res.config?.isFile) {
                return res;
            }

            if (res.status >= HTTP_CODE.SUCCESS && res.status < HTTP_CODE.SUCCESS_MAX) {
                return res.data;
            }
            const { response } = res as any;
            if (!response) {
                return Promise.reject(response);
            }
            return Promise.reject(Object.assign(response, response?.data || {}));
        };

        axios.interceptors.response.use(handle, handle);
    };
}
