import { Button, message, Modal, ModalProps, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getComboHistory, sendCombo } from '@services/api/combo';
import { ComboSelectorModal } from '@shared/components/biz/combo/selector';

export const ComboHistoryModal = (props: ModalProps & { orgId: number }) => {
    const { visible, orgId } = props;
    const [history, setHistory] = useState([]);
    const [senderVisible, setSenderVisible] = useState(false);
    useEffect(() => {
        if (visible) {
            setHistory([]);
            getComboHistory(orgId).then(rep => {
                setHistory(rep.data.comboList);
            });
        }
    }, [orgId, visible]);
    return (
        <Modal title="套餐发放" width={600} {...props}>
            <Row style={{ marginBottom: 10 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setSenderVisible(true);
                    }}
                >
                    发放新套餐
                </Button>
            </Row>
            <Table
                dataSource={history}
                columns={[
                    {
                        title: '套餐名称',
                        dataIndex: 'comboName',
                    },
                    {
                        title: '套餐id',
                        dataIndex: 'comboId',
                    },
                    {
                        title: '发送时间',
                        dataIndex: 'createTime',
                        render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '操作人',
                        dataIndex: 'operator',
                    },
                ]}
            />
            <ComboSelectorModal
                visible={senderVisible}
                onCancel={() => {
                    setSenderVisible(false);
                }}
                onSubmit={combo => {
                    sendCombo({
                        comboId: combo.id,
                        orgId,
                    }).then(rep => {
                        if (!rep.code) {
                            message.success('操作成功');
                            setHistory([]);
                            getComboHistory(orgId).then(rep => {
                                setHistory(rep.data.comboList);
                            });
                        } else {
                            message.error(rep?.error?.msg || rep?.msg || '服务器错误');
                        }
                    });
                }}
            />
        </Modal>
    );
};
