import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Switch, Table } from 'antd';
import { fetchSubAccount } from '@/services/api/org';
import { SubAccountTableData, SubAccountParams, SubAccountData } from '@/shared/model/type/org/sub-account.type';
import useListScrollHeight from '@/shared/utils/hooks/useListScrollHeight';
import { useParamsStashFunc } from '@/shared/utils/hooks/useParamsStashFunc';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_NAME } from '@/shared/model/enum/org/sub-account';
import { updateUser, resetUserPwd } from '@services/api/users';
import SubAccountForm from './components/form';

enum PHONE_STATUS {
    verified = 1,
    not,
}
enum OVERSEA {
    inner,
    out,
}
enum STATUS {
    normal = 1,
    frozen,
}

const SubAccount: React.FC = React.memo(function SubAccount() {
    const [dataSourceState, setDataSourceState] = useState<SubAccountTableData[]>([]);
    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 30, total: 0 });
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [editingUser, setEditingUser] = useState<any>({});
    const [visible, setVisible] = useState<boolean>(false);
    const [scrollHeightState] = useListScrollHeight(550, 160);
    const loadSubAccount = useParamsStashFunc(async function initSubAccount(params: SubAccountParams) {
        setLoadingState(true);
        try {
            const { data } = await fetchSubAccount(params);
            if (!data || !data.items) {
                return setLoadingState(false);
            }
            setPaginationState({
                current: data.page,
                pageSize: data.perPage,
                total: data.total,
            });
            const list: Array<SubAccountTableData> = [];
            (data?.items || []).forEach((item: SubAccountData) => {
                if (!item.orgInfo || !item.orgInfo.length) {
                    list.push({
                        ...item,
                        key: String(item.id),
                        rowSpan: 1,
                        orgId: '',
                        orgName: '',
                    });
                } else {
                    item.orgInfo.forEach(({ id, name }, idx) => {
                        const rowSpan = idx ? 0 : item.orgInfo.length;
                        list.push({
                            ...item,
                            key: `${item.id}-${id}`,
                            rowSpan,
                            orgId: id,
                            orgName: name,
                        });
                    });
                }
            });
            setDataSourceState(list);
            setLoadingState(false);
        } catch (e) {
            setLoadingState(false);
        }
    });

    const sharedRender = (
        render?: (text: string, record: SubAccountTableData, index: number) => string | ReactElement
    ) => (text: string, record: SubAccountTableData, index: number) => {
        return {
            children: render ? render(text, record, index) : text,
            props: {
                rowSpan: record.rowSpan,
            },
        };
    };

    const columns = useMemo(
        () => [
            {
                title: '账号ID',
                key: 'id',
                dataIndex: 'id',
                render: sharedRender(),
            },
            {
                title: '登录名',
                key: 'email',
                dataIndex: 'email',
                render: sharedRender(),
            },
            {
                title: '姓名',
                key: 'name',
                dataIndex: 'name',
                render: sharedRender(),
            },
            {
                title: '昵称',
                key: 'nickname',
                dataIndex: 'nickname',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
            },
            {
                title: '组织名称',
                key: 'orgName',
                dataIndex: 'orgName',
            },
            {
                title: '组织ID',
                key: 'orgId',
                dataIndex: 'orgId',
            },
            {
                title: '账号状态',
                key: 'status',
                dataIndex: 'status',
                render: sharedRender(text => ACCOUNT_STATUS_NAME[Number(text) as ACCOUNT_STATUS]),
            },
            {
                title: '主页ID',
                key: 'extId',
                dataIndex: 'extId',
            },
            {
                title: '操作',
                key: 'operation',
                dataIndex: 'operation',
                render: sharedRender((text: string, record: SubAccountTableData) => (
                    <>
                        <Button
                            onClick={() => {
                                console.log(record);
                            }}
                            disabled
                            size="small"
                            type="primary"
                        >
                            关闭
                        </Button>
                        <Button
                            style={{ marginLeft: 12 }}
                            onClick={() => {
                                setEditingUser(record);
                                setVisible(true);
                            }}
                            size="small"
                            type="primary"
                        >
                            修改
                        </Button>
                        <Button
                            style={{ backgroundColor: 'green', color: 'white', border: 'none' }}
                            onClick={() =>
                                Modal.confirm({
                                    title: '重置用户密码',
                                    content: '请提前与用户联系，确认要进行密码重置吗',
                                    okText: '确认',
                                    cancelText: '取消',
                                    centered: true,
                                    okButtonProps: {
                                        type: 'primary',
                                        danger: true,
                                    },
                                    onOk: async () => {
                                        try {
                                            await resetUserPwd(record.id);
                                            message.success('密码重置成功');
                                        } catch (error) {
                                            message.error('密码重置失败');
                                            throw error;
                                        }
                                    },
                                })
                            }
                            size="small"
                            type="primary"
                        >
                            重置密码
                        </Button>
                    </>
                )),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        const { current, pageSize } = paginationState;
        loadSubAccount({
            page: current,
            perPage: pageSize,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <SubAccountForm
                onSubmit={values => {
                    loadSubAccount({ ...values, page: 1 });
                }}
            />
            <Table
                columns={columns}
                rowKey={item => item.key}
                dataSource={dataSourceState}
                scroll={{ y: scrollHeightState }}
                loading={loadingState}
                bordered
                pagination={{
                    ...paginationState,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                    onChange: (current, pageSize) => {
                        loadSubAccount({ page: current, perPage: pageSize });
                    },
                }}
            />
            <Modal
                visible={visible}
                title="修改子账号"
                onOk={() => {
                    // if (!editingUser.phone.match(/1\d{10}/gi)) {
                    //     return message.error('请输入正确的手机号');
                    // }
                    updateUser(editingUser.id, {
                        email: editingUser.email,
                        phone: editingUser.phone,
                        name: editingUser.name,
                        nickname: editingUser.nickname,
                        isVerifyPhone: editingUser.phoneVerify,
                        isOversea: editingUser.oversea,
                        status: editingUser.status,
                        maxRunLimit: +editingUser.maxRunLimit,
                    }).then(rep => {
                        if (!rep.code) {
                            message.success('修改成功');
                            setVisible(false);
                            const { current, pageSize } = paginationState;
                            loadSubAccount({
                                page: current,
                                perPage: pageSize,
                            });
                        } else {
                            message.error(rep.msg ?? rep.msg ?? '服务器错误');
                        }
                    });
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form labelCol={{ span: 6 }}>
                    <Form.Item label="邮箱">
                        <Input
                            value={editingUser.email}
                            onChange={e =>
                                setEditingUser({
                                    ...editingUser,
                                    email: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="用户名">
                        <Input
                            value={editingUser.nickname}
                            onChange={e =>
                                setEditingUser({
                                    ...editingUser,
                                    nickname: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="姓名">
                        <Input
                            value={editingUser.name}
                            onChange={e =>
                                setEditingUser({
                                    ...editingUser,
                                    name: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input
                            value={editingUser.phone}
                            onChange={e =>
                                setEditingUser({
                                    ...editingUser,
                                    phone: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="是否实名">
                        <Switch
                            checked={editingUser.phoneVerify === PHONE_STATUS.verified}
                            onChange={value => {
                                setEditingUser({
                                    ...editingUser,
                                    phoneVerify: value ? PHONE_STATUS.verified : PHONE_STATUS.not,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="是否海外">
                        <Switch
                            checked={editingUser.oversea === OVERSEA.out}
                            onChange={value => {
                                setEditingUser({
                                    ...editingUser,
                                    oversea: value ? OVERSEA.out : OVERSEA.inner,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="状态">
                        <Select
                            value={editingUser.status}
                            onChange={value => {
                                setEditingUser({
                                    ...editingUser,
                                    status: value,
                                });
                            }}
                        >
                            <Select.Option value={STATUS.normal}>正常</Select.Option>
                            <Select.Option value={STATUS.frozen}>冻结</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="最大并行任务数">
                        <Input
                            value={editingUser.maxRunLimit}
                            onChange={e =>
                                setEditingUser({
                                    ...editingUser,
                                    maxRunLimit: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
});

export default SubAccount;
