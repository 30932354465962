import React, { useState, useEffect } from 'react';
import { Button, message, Table } from 'antd';
import { RechargeListData, RechargeListParams } from '@/shared/model/type/finance/recharge-list.type';
import {
    ACCOUNT_FILLING,
    ACCOUNT_FILLING_NAME,
    BALANCE_TYPE_MSG,
    PAYMENT,
    PAYMENT_NAME,
    RECHARGE_STATUS,
    RECHARGE_STATUS_MSG,
} from '@/shared/model/enum/finance/recharge.enum';
import useListScrollHeight from '@/shared/utils/hooks/useListScrollHeight';
import { queryRechargeList } from '@/services/api/bank';
import { IncomeRecordModal } from '@shared/components/biz/finance/income-record-modal/index';
import { ChangeRechargeSettleModal } from '@shared/components/biz/finance/income-record-modal/change-settle';
import { fetchOrgTypes } from '@services/api/org';
import { BALANCE_TYPE } from '@user/model';
import RechargeListForm from './components/form';

const FinanceRecharge: React.FC = React.memo(function FinanceIncome() {
    const [dataSourceState, setDataSourceState] = useState<any[]>([]);
    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 30, total: 0 });
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [multiSelect, setMultiSelect] = useState<boolean>(true);
    const [modalDataState, setModalDataState] = useState<RechargeListData | undefined>(undefined);
    const [visible, setVisible] = useState<boolean>(false);
    const [currSearchingState, setCurrSearchingState] = useState<RechargeListParams>({ page: 1, pageSize: 30 });
    const [scrollHeightState] = useListScrollHeight(550, 230);
    const [org, setOrg] = useState<any>({});
    const [changeSettleVisible, setChangeSettleVisible] = useState<boolean>(false);
    const [settleChangeState, setSettleChangeState] = useState({ rechargeId: 0, toStatus: 0 });

    const obtainData = async (params: RechargeListParams) => {
        try {
            setLoadingState(true);
            const rep = await queryRechargeList(params);
            if (!rep || rep.code) {
                message.error(rep?.error?.msg ?? '服务器错误');
                return setLoadingState(false);
            }
            setPaginationState({
                current: rep.data.page,
                pageSize: params.pageSize || paginationState.pageSize,
                total: rep.data.total,
            });
            setCurrSearchingState(params);
            setDataSourceState(rep.data.items);
            setLoadingState(false);
        } catch (e) {
            setLoadingState(false);
        }
    };

    useEffect(() => {
        const { current, pageSize } = paginationState;
        obtainData({
            page: current,
            pageSize,
        });
        fetchOrgTypes().then(({ data }) => {
            setOrg(
                data.reduce((total: any, item) => {
                    total[item.id] = item.name;
                    return total;
                }, {})
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <RechargeListForm
                onSubmit={(values: any) => {
                    setCurrSearchingState({ ...values });
                    obtainData({ ...values, page: 1, pageSize: paginationState.pageSize });
                }}
            />
            <div style={{ height: 10 }} />
            <Table
                columns={[
                    {
                        title: '充值ID',
                        dataIndex: 'id',
                        width: 80,
                    },
                    {
                        title: '组织ID',
                        dataIndex: 'orgId',
                        width: 80,
                    },
                    {
                        title: '管理员邮箱',
                        dataIndex: 'orgOwner',
                        width: 120,
                    },
                    {
                        title: '组织全称',
                        dataIndex: 'orgFullname',
                        width: 120,
                    },
                    {
                        title: '组织类型',
                        dataIndex: 'orgType',
                        width: 120,
                        render: value => org[value] ?? '--',
                    },
                    {
                        title: '充值操作人',
                        width: 120,
                        dataIndex: 'operator',
                    },
                    {
                        title: '余额类型',
                        dataIndex: 'balanceType',
                        render: value => BALANCE_TYPE_MSG[value as BALANCE_TYPE],
                        width: 100,
                    },
                    {
                        title: '支付方式',
                        width: 100,
                        dataIndex: 'channel',
                        render: (text: string) => {
                            return PAYMENT_NAME[Number(text) as PAYMENT];
                        },
                    },
                    {
                        title: '充值时间',
                        width: 180,
                        dataIndex: 'rechargeTime',
                    },
                    {
                        title: '充值金额',
                        width: 100,
                        dataIndex: 'rechargeAmount',
                    },
                    {
                        title: '已到款金额',
                        width: 120,
                        dataIndex: 'settleAmount',
                    },
                    {
                        title: '未到款金额',
                        width: 120,
                        dataIndex: 'unsettledAmount',
                    },
                    {
                        title: '充值状态',
                        width: 120,
                        dataIndex: 'status',
                        render: value => {
                            return RECHARGE_STATUS_MSG[value as RECHARGE_STATUS];
                        },
                    },
                    {
                        title: '结算状态',
                        width: 100,
                        dataIndex: 'settleStatus',
                        render: (text: string) => {
                            return ACCOUNT_FILLING_NAME[Number(text) as ACCOUNT_FILLING];
                        },
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        fixed: 'right',
                        render: (text: string, record: any) => {
                            const isSettleStatusFinish = record.settleStatus === ACCOUNT_FILLING.FINISH;
                            return (
                                <>
                                    {!isSettleStatusFinish && (
                                        <Button
                                            disabled={record.settleStatus === ACCOUNT_FILLING.NOT}
                                            style={{ marginLeft: 12 }}
                                            onClick={() => {
                                                setVisible(true);
                                                setModalDataState(record);
                                                setMultiSelect(true);
                                            }}
                                            size="small"
                                            type="primary"
                                        >
                                            关联来款
                                        </Button>
                                    )}
                                    <Button
                                        disabled={
                                            record.balanceType !== BALANCE_TYPE.NORMAL_BALANCE ||
                                            record.settleAmount !== 0 ||
                                            record.settleStatus === ACCOUNT_FILLING.NOT
                                        }
                                        style={{ marginLeft: 12 }}
                                        onClick={() => {
                                            setChangeSettleVisible(true);
                                            setSettleChangeState({
                                                rechargeId: record.id,
                                                toStatus: ACCOUNT_FILLING.NOT,
                                            });
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        不可结算
                                    </Button>
                                    <Button
                                        disabled={
                                            record.balanceType !== BALANCE_TYPE.NORMAL_BALANCE ||
                                            record.settleAmount <= 0 ||
                                            record.settleStatus === ACCOUNT_FILLING.FINISH
                                        }
                                        style={{ marginLeft: 12 }}
                                        onClick={() => {
                                            setChangeSettleVisible(true);
                                            setSettleChangeState({
                                                rechargeId: record.id,
                                                toStatus: ACCOUNT_FILLING.FINISH,
                                            });
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        完成结算
                                    </Button>
                                </>
                            );
                        },
                    },
                ]}
                dataSource={dataSourceState}
                rowKey={item => item.id}
                scroll={{ x: true, y: scrollHeightState }}
                loading={loadingState}
                bordered
                pagination={{
                    ...paginationState,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: total => `共 ${total} 条结果`,
                    onChange: (current, pageSize) => {
                        obtainData({
                            ...currSearchingState,
                            page: current,
                            pageSize: pageSize ?? paginationState.pageSize,
                        });
                    },
                }}
            />
            <IncomeRecordModal
                title="请选择来款记录"
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                    setModalDataState(undefined);
                }}
                onSubmit={() => {
                    setVisible(false);
                    setModalDataState(undefined);
                    obtainData({
                        page: 1,
                        pageSize: paginationState.pageSize,
                    });
                }}
                multiSelect={multiSelect}
                currData={modalDataState}
            />
            <ChangeRechargeSettleModal
                data={settleChangeState}
                visible={changeSettleVisible}
                onCancel={() => {
                    setChangeSettleVisible(false);
                    setModalDataState(undefined);
                }}
                onSubmit={() => {
                    setChangeSettleVisible(false);
                    setModalDataState(undefined);
                    obtainData({
                        page: 1,
                        pageSize: paginationState.pageSize,
                    });
                }}
            />
        </div>
    );
});

export default FinanceRecharge;
